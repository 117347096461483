import React from 'react';
import { useTranslation } from 'react-i18next';

import GenderEthnicityChart from '../../../modules/Chart/GenderEthnicityChart';
import { GENDER_ETHNICITY_CHART_LEGEND_KEYS } from '../../../modules/Chart/GenderEthnicityChart/GenderEthnicityChart.constants';
import IntensityChart from '../../../modules/Chart/IntensityChart';
import YoYChart from '../../../modules/Chart/YoYChart';
import Card from '../../atoms/Card';
import PrintPage from '../../atoms/Page/PrintPage';
import ChartCard from '../../molecules/ChartCard';
import { GENDER_ETHNICITY_GAP_CHART_LEGEND_KEYS } from '../../molecules/GenderChart/GenderChart.constants';
import TotalAdjustmentCard from '../../molecules/TotalAdjustmentCard/TotalAdjustmentCard';
import GenderTable from '../../organisms/GenderTable/GenderTable';
import KeyTakeawaysCard from '../../organisms/KeyTakeawaysCard/KeyTakeawaysCard';

import useOpportunityPagePresenter from './OpportunityPage.presenter';
import { OpportunityPageProps } from './OpportunityPage.types';

const OpportunityPrintPage: React.FC<OpportunityPageProps> = () => {
  const { t } = useTranslation();
  const {
    view,
    appliedFilters,
    totalOpportunityAdjustment,
    genderEthnicityChartData,
    genderTable,
    keyTakeawaysCard,
    opportunityIntensityChart,
    opportunityYoYChart,
  } = useOpportunityPagePresenter();

  return (
    <>
      <PrintPage>
        <div style={{
          display: 'flex', flexDirection: 'column', gap: '10px', width: '132px', flexShrink: 0,
        }}>
          <Card printMode>
            <TotalAdjustmentCard page='opportunity' value={totalOpportunityAdjustment} printMode />
          </Card>
          <KeyTakeawaysCard
            page='opportunity'
            view={view}
            state={keyTakeawaysCard.state}
            keyTakeawaysValues={keyTakeawaysCard.values}
            printMode />
        </div>
        <ChartCard
          width='offset'
          printMode
        >
          <ChartCard.Header
            title={view === 'Region'
              ? t('opportunityPage.opportunityAdjustmentGraphTitle.intensity')
              : t('opportunityPage.opportunityAdjustmentGraphTitle.forEachSupplier')}
            subtitle={t('filtersApplied', { filters: appliedFilters })}
            isExpandable={false}
            printMode
          />
          {view === 'Region' ? <>
            <YoYChart
              height={545}
              width={484}
              stacked={true}
              datasetConfig={opportunityYoYChart.datasetConfig}
              data={opportunityYoYChart.data}
              isIntensity
              printMode
            />
          </> : <>
            <IntensityChart
              datasetConfig={opportunityIntensityChart.datasetConfig}
              alignment='right'
              data={opportunityIntensityChart.data}
              height={545}
              width={484}
              printMode
            />
            <ChartCard.Legend datasets={opportunityIntensityChart.datasetConfig} printMode />
          </>}
        </ChartCard>
      </PrintPage>
      <PrintPage>
        <ChartCard printMode>
          <ChartCard.Header
            title={t('opportunityPage.ethnicityGenderBarChart.title')}
            subtitle={t('filtersApplied', { filters: appliedFilters })}
            isExpandable={false}
            printMode
          />
          <GenderEthnicityChart
            height={545}
            width={631}
            data={genderEthnicityChartData}
            datasetConfig={'opportunityAdjustment'}
            printMode
          />
          <ChartCard.Legend datasets={GENDER_ETHNICITY_CHART_LEGEND_KEYS} shouldShowDefinition={false} printMode />
        </ChartCard>
      </PrintPage>
      {view === 'Region' ? <PrintPage>
        <ChartCard printMode>
          <ChartCard.Header
            title={t('opportunityPage.genderTable.title')}
            subtitle={t('filtersApplied', { filters: appliedFilters })}
            isExpandable={false}
            printMode
          />
          <GenderTable
            data={genderTable.data}
            year={genderTable.year}
            toggleItems={genderTable.toggleItems}
            workforceGraphType='Seniority'
            printMode />
          <ChartCard.Legend
            datasets={GENDER_ETHNICITY_GAP_CHART_LEGEND_KEYS}
            shouldShowDefinition={false}
            printMode />
        </ChartCard>
      </PrintPage> : null}

      {view === 'Region' && <PrintPage>
        <ChartCard>
          <ChartCard.Header
            title={t('opportunityPage.genderTable.title')}
            subtitle={t('filtersApplied', { filters: appliedFilters })}
            isExpandable={false}
            printMode
          />
          <GenderTable
            data={genderTable.data}
            year={genderTable.year}
            toggleItems={[
              {
                ...genderTable.toggleItems[0],
                isSelected: false,
              },
              {
                ...genderTable.toggleItems[1],
                isSelected: true,
              },
            ]}
            workforceGraphType={'Job Category'}
            printMode />
          <ChartCard.Legend
            datasets={GENDER_ETHNICITY_GAP_CHART_LEGEND_KEYS}
            shouldShowDefinition={false}
            printMode />
        </ChartCard>
      </PrintPage>
      }
    </>
  );
};

export default OpportunityPrintPage;

