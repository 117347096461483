import { useCallback, useEffect, useState } from 'react';
import useMouse from '../../../hooks/useMouse';
import { useTooltip } from '../../../shared_components/organisms/Tooltip/Tooltip';
import { calcTooltipPosition } from './ChartTooltip.utils';


const useChartTooltip = ( tooltip:React.RefObject<HTMLDivElement> ) => {
  
  const [ mouse, mouseRef, subscribe, unsubscribe ] = useMouse(false);    
  const {
    settings,
    openTooltip,
    closeTooltip,
    positionTooltip,
  } = useTooltip();
  const {
    isOpen,
  } = settings;

  //Get the chart to constrain the following of the mouse to its boundary
  const boundingElement:HTMLElement = mouseRef.current as HTMLElement;

  //wrapper method to handle both making tooltip visible and listening to mouse movements
  const openChartTooltip = useCallback(( ) => {
    if (!isOpen) {
      subscribe();
      openTooltip();
    }
  }, [isOpen]);

  //wrapper method to handle both hiding tooltip and removing mouse movement listener
  const closeChartTooltip = useCallback(() => {
    if (isOpen) {
      unsubscribe();
      closeTooltip();
    }
  }, [isOpen]);

  //Tooltip positioning logic, wrapped in useEffect to prevent infinite 
  //looping of rerendering. It will updated the position of the tooltip everytime
  //the mouse moves.
  useEffect(()=>{
    if (isOpen && tooltip && tooltip.current) {
      const position = calcTooltipPosition( mouse, tooltip.current, boundingElement);
      positionTooltip(position);
    }
  }, [mouse.elementX, mouse.elementY]);
    
  return {
    isTooltipOpen: isOpen,
    openTooltip: openChartTooltip,
    closeTooltip: closeChartTooltip,
    mouseRef,
  };
};

export default useChartTooltip;