import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Summary } from '../../../lib/api/resources/reports';
import { DatasetConfig } from '../../../modules/Chart/Chart.types';
import { PageContext } from '../../../modules/page/PageContext';
import { useFilterModal } from '../../organisms/FilterModal/FilterModal.context';
import { KeyTakeawaysCardStateEnum } from '../../organisms/KeyTakeawaysCard/KeyTakeawaysCard';

import { buildDataForPage } from './JobCreationPage.utils';

const useJobCreationPagePresenter = (
) => {
  const { t } = useTranslation();
  const { filterState: { year }, pageView, appliedFilters } = useFilterModal();
  const { chartsData: summaryTables, isLoading } = useContext(PageContext);
  const {
    rawSummary,
  } = summaryTables;

  const {
    keyTakeawayValues,
    totalJobCreationAdjustment,
  } = buildDataForPage(pageView, year, rawSummary);

  // Key Takeaways Card
  const keyTakeawaysState: KeyTakeawaysCardStateEnum = isLoading ? 'Loading' : 'Default';
  const keyTakeawaysCard = {
    state: keyTakeawaysState,
    values: keyTakeawayValues,
  };

  // Job Creation Adjustment Intensity Chart
  const jobCreationIntensityChartDatasetConfig: DatasetConfig<Summary>[] = [{
    datasetLabel: t('chartDatasetKeys.jobCreationAdjustment'),
    datasetProperty: 'jobCreationAdjustment',
    color: 'BLUE',
  }];
  const jobCreationIntensityChart = {
    data: rawSummary,
    datasetConfig: jobCreationIntensityChartDatasetConfig,
  };

  // Job Creation Adjustment YoY Chart
  const jobCreationYoYChartDatasetConfig: DatasetConfig<Summary>[] = [{
    datasetLabel: t('chartDatasetKeys.jobCreationAdjustment'),
    datasetProperty: 'jobCreationAdjustment',
    color: 'TEAL',
  }];
  const jobCreationYoYChart = {
    data: rawSummary,
    datasetConfig: jobCreationYoYChartDatasetConfig,
  };

  return {
    view: pageView,
    // Applied Filters for each Chart Card
    appliedFilters,
    // Total Adjutsment Amount Card
    totalJobCreationAdjustment,
    // Map Chart Props
    jobMapGraphCardData: rawSummary,
    // Key Takeaways Card Props
    keyTakeawaysCard,
    // Intensity Chart Props
    jobCreationIntensityChart,
    // YoY Chart Props
    jobCreationYoYChart,
  };
};

export default useJobCreationPagePresenter;

