import { buildOptions } from './GenderChart.utils';
import { GenderChartProps } from './GendersChart.types';

const useGenderChartPresenter = (props: GenderChartProps) => {
  const options = buildOptions(props);
  return {
    ...props,
    options,
  };
};

export default useGenderChartPresenter;