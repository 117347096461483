/**
 * Checks if the provided link is an external link.
 * 
 * @param {string} link - The input string to be validated as a URL.
 * @returns {boolean} - Returns true if the input is a valid URL with 'http' or 'https' protocol, otherwise false.
 */
export const isExternalUrl = (link) => {
  try {
    const url = new URL(link);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (e) {
    return false;
  }
};