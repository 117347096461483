import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import GenderEthnicityChart from '../../../modules/Chart/GenderEthnicityChart';
import { GENDER_ETHNICITY_CHART_LEGEND_KEYS } from '../../../modules/Chart/GenderEthnicityChart/GenderEthnicityChart.constants';
import IntensityChart from '../../../modules/Chart/IntensityChart';
import YoYChart from '../../../modules/Chart/YoYChart';
import Card from '../../atoms/Card';
import Page from '../../atoms/Page';
import ChartCard from '../../molecules/ChartCard';
import DimensionHeader from '../../molecules/DimensionHeader';
import EmptyStateContent from '../../molecules/EmptyStateContent';
import { GENDER_ETHNICITY_GAP_CHART_LEGEND_KEYS } from '../../molecules/GenderChart/GenderChart.constants';
import TotalAdjustmentCard from '../../molecules/TotalAdjustmentCard/TotalAdjustmentCard';
import GenderTable from '../../organisms/GenderTable/GenderTable';
import KeyTakeawaysCard from '../../organisms/KeyTakeawaysCard/KeyTakeawaysCard';
import GenderChartEmptyStateContent from '../../organisms/WorkforceGapGenderEthnicityCard/EmptyStateContent';

import styles from './OpportunityPage.module.scss';
import useOpportunityPagePresenter from './OpportunityPage.presenter';
import { OpportunityPageProps } from './OpportunityPage.types';

const OpportunityPage: React.FC<OpportunityPageProps> = () => {
  const { t } = useTranslation();
  const {
    isAdmin,
    view,
    appliedFilters,
    totalOpportunityAdjustment,
    genderEthnicityChartData,
    genderTable,
    keyTakeawaysCard,
    opportunityIntensityChart,
    opportunityYoYChart,
  } = useOpportunityPagePresenter();

  const { data: genderTableData, toggleItems, workforceGraphType, year } = genderTable;

  return (
    <Page className={cx(styles.opportunityPage)} >
      <DimensionHeader page={'opportunity'} />
      <div style={{
        display: 'flex', flexDirection: 'column', gap: '1.5rem', width: '312px', flexShrink: 0,
      }}>
        <Card>
          <TotalAdjustmentCard page="opportunity" value={totalOpportunityAdjustment} />
        </Card>
        <KeyTakeawaysCard
          page='opportunity'
          view={view}
          state={keyTakeawaysCard.state}
          keyTakeawaysValues={keyTakeawaysCard.values} />
      </div>
      <ChartCard
        width='offset'
      >
        <ChartCard.Header
          title={view === 'Region'
            ? t('opportunityPage.opportunityAdjustmentGraphTitle.intensity')
            : t('opportunityPage.opportunityAdjustmentGraphTitle.forEachSupplier')}
          subtitle={t('filtersApplied', { filters: appliedFilters })}
          isExpandable={view !== 'Region' ? true : false}
        />
        {view === 'Region' ? <>
          <YoYChart
            height={545}
            stacked={true}
            datasetConfig={opportunityYoYChart.datasetConfig}
            data={opportunityYoYChart.data}
            isIntensity
          />
        </> : <>
          <IntensityChart
            datasetConfig={opportunityIntensityChart.datasetConfig}
            alignment='right'
            data={opportunityIntensityChart.data}
            height={545}
          />
          <ChartCard.Legend datasets={opportunityIntensityChart.datasetConfig} />
        </>}
      </ChartCard>
      {/* Ethnicity & Gender Bar Chart */}
      <ChartCard>
        <ChartCard.Header
          title={t('opportunityPage.ethnicityGenderBarChart.title')}
          subtitle={t('filtersApplied', { filters: appliedFilters })}
          isExpandable={false}
        />
        <GenderEthnicityChart
          height={545}
          data={genderEthnicityChartData}
          datasetConfig={'opportunityAdjustment'}
        />
        <ChartCard.Legend datasets={GENDER_ETHNICITY_CHART_LEGEND_KEYS} shouldShowDefinition={false} />
      </ChartCard>

      {/* Ethnicity & Gender & Category Gender Table */}
      {isAdmin && <ChartCard>
        <ChartCard.Header
          title={t('opportunityPage.genderTable.title')}
          subtitle={t('filtersApplied', { filters: appliedFilters })}
          isExpandable={false}
        />
        {view === 'Region'
          ? (
            <>
              <GenderTable
                year={year}
                data={genderTableData}
                toggleItems={toggleItems}
                workforceGraphType={workforceGraphType} />
              <ChartCard.Legend
                datasets={GENDER_ETHNICITY_GAP_CHART_LEGEND_KEYS}
                shouldShowDefinition={false} />
            </>
          )
          : view === 'Empty'
            ? <EmptyStateContent />
            : <GenderChartEmptyStateContent />
        }
      </ChartCard>
      }
    </Page>
  );
};

export default OpportunityPage;

