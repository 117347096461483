import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext } from '../auth';
import { EmployeeSummary, EmpowermentSummary, RoleSummary, Summary, fetchAllData } from '../../lib/api/resources/reports';
import { SelectedFilterState } from '../../shared_components/organisms/FilterModal/FilterModal.types';
import { useFilterModal } from '../../shared_components/organisms/FilterModal/FilterModal.context';
import { useTranslation } from 'react-i18next';
import { translateDatasets } from './pageContext.utils';


export type ChartData = {
  empowermentSummary: EmpowermentSummary[];
  roleSummary: RoleSummary[];
  rawSummary: Summary[];
  rawEmployeeSummary: EmployeeSummary[];
};
export type PageContextValue = {
  chartsData: ChartData;
  isLoading: boolean;
};

const MAX_SUPPLIER_IN_PAYLOAD = 100;

export const initialPageContext: PageContextValue = {

  chartsData: {
    empowermentSummary: [],
    roleSummary: [],
    rawSummary: [],
    rawEmployeeSummary: [],
  },
  isLoading: false,
};
export const PageContext = createContext<PageContextValue>(initialPageContext);
export const PageProvider: React.FC<{}> = ({ children }) => {
  const [chartsData, setChartsData] = useState<ChartData>(initialPageContext.chartsData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isAdmin, account } = useContext(AuthContext);
  const { filterState } = useFilterModal();
  const { t } = useTranslation();

  const fetchChartData = async (selectedFilterState: SelectedFilterState) => {
    try {
      // update loading state to true
      setIsLoading(true);
      const pageData = await fetchAllData(selectedFilterState, isAdmin);
      const translatedPageData = translateDatasets(pageData, t);

      //SET PAGE DATA
      setChartsData(translatedPageData);      
    } catch (error) {
      // TODO: handle error
    } finally {
      // update loading state to false       
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchAndUpdateChartData = async () => {
      if (filterState.selectedSuppliers.length > MAX_SUPPLIER_IN_PAYLOAD) {
        console.error('more than 100 suppliers selected');
        return;
      }

      //Only fetch chart data if filters were set, but data isn't stored in context
      if (filterState.selectedSuppliers.length && account) {
        await fetchChartData(filterState);
      }
    };

    // Handle the promise returned by fetchAndUpdateChartData
    fetchAndUpdateChartData().catch(error => {
      console.error('Error fetching page data:', error);
    });
  }, [filterState.selectedSuppliers, filterState.year, account]);


  const contextValue = useMemo(() => ({
    chartsData,
  }), [chartsData]);

  return (
    <PageContext.Provider value={{
      ...contextValue,
      isLoading,
    }}>
      {children}
    </PageContext.Provider>
  );
};