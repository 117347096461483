import { useFilterModal } from '../../FilterModal/FilterModal.context';
import { EmptyStateContentProps } from './EmptyStateContent.types';

const useEmptyStateContentPresenter = (props: EmptyStateContentProps) => {
  const { openModal } = useFilterModal();

  return {
    selectSupplierButtonClickHandler: openModal,
  };
};

export default useEmptyStateContentPresenter;