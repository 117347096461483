import { Cell, CellContext, ColumnDef, ColumnHelper, Table, flexRender } from '@tanstack/react-table';
import { TFunction } from 'react-i18next';

import { Summary } from '../../../lib/api/resources/reports';
import { calcIntensity } from '../../../lib/utils';
import { DataFrame } from '../../../modules/data_frame/DataFrame';
import { View } from '../FilterModal/FilterModal.types';

import { ColumnConfig, ColumnMetaConfig, ColumnThresholds, EITableCellProps, EITableDataRow, EITableHeaderCellProps, EITableRowProps } from './EITable.types';

// This function formats the value of a table cell for display.
export const formatTableCellValue = (cell: CellContext<EITableDataRow, unknown>): string => {
  // Retrieve the rendered value of the cell
  const value = cell.getValue();

  // Check if the value is a number
  if (typeof value === 'number') {
    // If the number is negative, enclose it within parentheses and convert it to a localized string
    if (value < 0) {
      const absValue = Math.abs(value);
      return `(${absValue.toLocaleString()})`;
    } else {
      // Otherwise, convert the number to a localized string
      return value.toLocaleString();
    }
  }

  // If the value is not a number, return it as a string
  return String(value);
};

// This function determines the background cell color based on the provided threshold values and cell data.
export const getBackgroundCellColor = (cell: Cell<EITableDataRow, unknown>, thresholdValues?: ColumnThresholds): string => {
  // Retrieve the column name of the cell
  const columnName = cell.column.id;

  // Retrieve the value of the cell
  const columnValue = cell.getValue();

  // Check if threshold values are provided
  if (thresholdValues && thresholdValues[columnName] !== undefined) {
    // If threshold values are provided for the column and the cell value is below or equal to the threshold,
    // return a background color, otherwise return 'none' indicating no background color.
    return thresholdValues[columnName] >= Number(columnValue) ? '#BAE2FF' : 'none';
  }

  // If no threshold values are provided, return 'none' indicating no background color.
  return 'none';
};

// This function generates a list of columns configuration for a table, each column containing information about its accessor, header text, minimum size, and size.
const getColumnsList = (t: TFunction): ColumnConfig[] => [
  {
    accessor: 'supplierName',
    headerText: t('OverviewPage.employmentImpactTable.header.supplierName'),
    minSize: 192,
    size: 192,
    meta: {
      textAlignment: 'right',
    },
  },
  {
    accessor: 'totalWages',
    headerText: t('OverviewPage.employmentImpactTable.header.totalWages'),
    minSize: 131,
    size: 131,
  },
  {
    accessor: 'wageQualityAdjustment',
    headerText: t('OverviewPage.employmentImpactTable.header.wageQualityAdjustment'),
    minSize: 131,
    size: 131,
  },
  {
    accessor: 'diversityAdjustment',
    headerText: t('OverviewPage.employmentImpactTable.header.diversityAdjustment'),
    minSize: 131,
    size: 131,
  },
  {
    accessor: 'opportunityAdjustment',
    headerText: t('OverviewPage.employmentImpactTable.header.opportunityAdjustment'),
    minSize: 131,
    size: 131,
  },
  {
    accessor: 'jobCreationAdjustment',
    headerText: t('OverviewPage.employmentImpactTable.header.jobCreationAdjustment'),
    minSize: 131,
    size: 131,
  },
  {
    accessor: 'totalAdjustment',
    headerText: t('OverviewPage.employmentImpactTable.header.totalAdjustment'),
    minSize: 131,
    size: 131,
    meta: {
      textWeight: 'bold',
    },
  },
  {
    accessor: 'totalEmploymentImpact',
    headerText: t('OverviewPage.employmentImpactTable.header.totalEmploymentImpact'),
    minSize: 131,
    size: 131,
    meta: {
      textWeight: 'bold',
    },
  },
  {
    accessor: 'rank',
    headerText: t('OverviewPage.employmentImpactTable.header.rank'),
    minSize: 51,
    size: 51,
  },
];

/**
 * Function to generate column definitions for a table.
 * @param {TFunction} t - Function for language translation.
 * @param {ColumnHelper} columnHelper - Helper for column configuration.
 * @returns {Array} - Array of column definitions.
 */
export const generateColumnsDefinitions = (t: TFunction, columnHelper: ColumnHelper<EITableDataRow>): ColumnDef<EITableDataRow, string | number>[] => {
  // Retrieve the list of columns
  const columns = getColumnsList(t);

  // Map over each column to generate its definition
  return columns.map(column => {
    return columnHelper.accessor(column.accessor, {
      // Define header rendering function
      header: () => column.headerText,
      // Define cell rendering function
      cell: (cell: CellContext<EITableDataRow, unknown>) => formatTableCellValue(cell),
      // Set minimum size for the column
      minSize: column.minSize,
      // Set size for the column
      size: column.size,
      meta: column.meta,
    });
  });
};


/**
 * Function to assign ranks to data based on a specified field.
 * @param {T[]} data - Array of data to be ranked.
 * @param {keyof T} fieldToRankBy - Field in the data to rank by.
 * @param {(a: T[keyof T], b: T[keyof T]) => number} [comparator] - Optional comparator function for custom sorting.
 * @returns {Array} - Array of data with ranks assigned.
 */
const assignRank = <T extends Record<string, any>>(
  data: T[],
  fieldToRankBy: keyof T,
  comparator?: (a: T[keyof T], b: T[keyof T]) => number,
): (T & {
    rank: number;
  })[] => {
  // Sort the array based on the specified field
  const sortedData = data.sort((a, b) => (
    comparator
      ? comparator(a[fieldToRankBy], b[fieldToRankBy])
      : (b[fieldToRankBy] - a[fieldToRankBy])
  ));

  // Initialize an array to store ranked data
  const rankedData: (T & {
    rank: number;
  })[] = [];

  let rank = 1;
  let prevValue = sortedData[0][fieldToRankBy];

  // Assign ranks based on the sorted order
  for (let i = 0; i < sortedData.length; i++) {
    if (sortedData[i][fieldToRankBy] < prevValue) {
      rank = rank + 1;
      prevValue = sortedData[i][fieldToRankBy];
    }
    // Push each data element along with its rank into the rankedData array
    rankedData.push({
      ...sortedData[i],
      rank,
    });
  }

  return rankedData;
};


const calculateThresholdForTableColumn = (
  data: DataFrame<Record<string, string | number>>,
  fields: string[],
): ColumnThresholds => {
  const thresholds: ColumnThresholds = {};

  fields.forEach((field) => {
    // Extract values for the specified field from the data
    const values = data.col(field) as number[];
    if (!values.length) {
      return 0;
    }

    // Sort the values in ascending order
    const sortedValues: number[] = values.slice().sort((a, b) => a - b);

    // last array sort value to get max value
    const maxValue = sortedValues[sortedValues.length - 1];

    // first array sort value to get max value
    const [lowestValue] = sortedValues;

    // to get 20% of range in values
    const increment = (maxValue - lowestValue) / 5;

    // to set threshold for column last 20% of values 
    thresholds[field] = lowestValue + increment;
  });

  return thresholds;
};

/**
 * Function to build data for a table representing employment impact.
 * @param {number} year - Year for which data is being built.
 * @param {View} pageView - Type of view for the page.
 * @param {Summary[]} rawSummary - Raw summary data.
 * @returns {EITableProps} - Data for the employment impact table.
 */
export const buildDataForTable = (year: number, pageView: View, rawSummary: Summary[]) => {
  // Define keys for intensity calculations
  const intensityKeys = ['supplier', 'wageQualityAdjustment', 'diversityAdjustment', 'opportunityAdjustment', 'jobCreationAdjustment', 'totalAdjustment', 'totalEmploymentImpact'];

  // Define a list of selectors for data transformation
  const selectList = intensityKeys.map((dataset) => {
    // Handle special case for 'supplier' dataset
    if (dataset === 'supplier') {
      return {
        as: 'supplierName',
        calculator: (props) => {
          // Return appropriate value based on page view
          return pageView === 'Portfolio' ? props.row.supplier : `${props.row.location} | ${props.row.supplier}`;
        },
      };
    }
    // For other datasets, use the provided calculator function
    return {
      as: dataset,
      calculator: (props) => {
        return calcIntensity(props.row, dataset);
      },
    };
  });

  // initialize thresholds and data
  let thresholds: ColumnThresholds = {};
  let dataRows: EITableDataRow[] = [];

  // Query and transform raw data using DataFrame
  const tableDataWORank = new DataFrame(rawSummary).query(
    {
      where: [
        {
          comparator: (props) => {
            return (props.row.year === year);
          },
        },
      ],
      groupBy: pageView === 'Portfolio' ? ['supplier'] : ['supplier', 'location'],
      select: ['totalWages', ...selectList],
      roundValues: true,
      excludedAggregationKeys: ['year'],
    },
  );
  const tableDataRowsWORank = tableDataWORank.getDataset() as Omit<EITableDataRow, 'rank'>[];

  // If there is data available, proceed with further processing
  if (tableDataRowsWORank.length) {
    // Assign ranks to the data based on 'totalEmploymentImpact'
    dataRows = assignRank(tableDataRowsWORank, 'totalEmploymentImpact');

    // Calculate thresholds for each adjustment type
    thresholds = calculateThresholdForTableColumn(
      tableDataWORank,
      ['wageQualityAdjustment', 'diversityAdjustment', 'opportunityAdjustment', 'jobCreationAdjustment', 'totalAdjustment', 'totalEmploymentImpact'],
    );
  }

  // Return the built data for the employment impact table
  return {
    rows: dataRows,
    thresholds,
  };
};

export const buildHeaderCellPropsFromTable = (table: Table<EITableDataRow>): EITableHeaderCellProps[] => {
  // if there are no table groups return empty array
  const tableHeaderGroups = table.getHeaderGroups();
  if (!tableHeaderGroups.length) {
    return [];
  }
  const tableHeaderGroup = tableHeaderGroups[0];

  const tableHeaders = tableHeaderGroup.headers.map((header): EITableHeaderCellProps => {
    return {
      id: header.id,
      isSortable: header.column.getCanSort(),
      sortColumn: header.column.getToggleSortingHandler(),
      columnTitleView: flexRender(header.column.columnDef.header, header.getContext()),
      sortDirection: header.column.getIsSorted(),
      cellTextWeight: (header.column.columnDef.meta as ColumnMetaConfig)?.textWeight || 'regular',
    };
  });

  return tableHeaders;
};

export const buildTableRowPropsFromTable = (table: Table<EITableDataRow>, thresholds: ColumnThresholds): EITableRowProps[] => {
  const tableRows = table.getRowModel().rows;
  
  const rows = tableRows.map((row): EITableRowProps => {
    return {
      id: row.id,
      cells: row.getVisibleCells().map((cell): EITableCellProps => {
        return {
          id: cell.id,
          backgroundCellColor: getBackgroundCellColor(cell, thresholds),
          value: flexRender(cell.column.columnDef.cell, cell.getContext()),
          width: cell.column.columnDef.size,
          cellTextWeight: (cell.column.columnDef.meta as ColumnMetaConfig)?.textWeight || 'regular',
          cellTextAlignment: (cell.column.columnDef.meta as ColumnMetaConfig)?.textAlignment || 'right',
        };
      }),
    };
  });

  return rows;
};



