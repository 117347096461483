import React, { HTMLAttributes, forwardRef } from 'react';
import cx from 'classnames';

import styles from './ChartTooltip.module.scss';
import { ChartTooltipProps, DATA_COLOR } from './ChartTooltip.types';
import useChartTooltipPresenter from './ChartTooltip.presenter';
import Tooltip from '../../../shared_components/organisms/Tooltip';
import Text from '../../../shared_components/atoms/Text';

//Icons
import { ReactComponent as ArrowUp } from '../../../resources/icons/ArrowUpTooltip.svg';
import { ReactComponent as ArrowDown } from '../../../resources/icons/ArrowDownTooltip.svg';
import { TextColor } from '../../../shared_components/atoms/Text/Text.types';

export type TrendProps = {
  trendPercent?: number;
  alignment?: 'leftAlignment' | 'rightAlignment';
};
type TrendObj = {
  up: {
    arrow: React.JSX.Element;
    color: TextColor;
    plusSign: string;
  };
  down: {
    arrow: React.JSX.Element;
    color: TextColor;
    plusSign: string;
  };
};

const trend: TrendObj = {
  up: {
    arrow: <ArrowUp />,
    color: 'green',
    plusSign: '+',
  },
  down: {
    arrow: <ArrowDown />,
    color: 'purple',
    plusSign: '',
  },
};


const Trend: React.FC<TrendProps> = (props) => {
  const {
    trendPercent,
    alignment = 'leftAlignment',
  } = props;

  //do not render trend if no value is present
  if (!trendPercent) return null;

  const direction = (trendPercent > 0) ? 'up' : 'down';
  const trendDirection = trend[direction];

  return (
    <div className={cx(styles.Trend, styles[direction], styles[alignment])}>
      <div className={styles.arrow}>
        {trendDirection.arrow}
      </div>
      <Text
        type='p'
        size='xs'
        color={trendDirection.color}
      >
        {`${trendDirection.plusSign}${trendPercent}% from prior year`}
      </Text>
    </div>
  );
};

type IndicatorProps = {
  indicatorColor?: DATA_COLOR;
};

const Indicator: React.FC<IndicatorProps> = (props) => {
  const {
    indicatorColor,
  } = props;
  if (!indicatorColor) return null;

  return (
    <div
      className={styles.indicator}
      style={{
        backgroundColor: indicatorColor,
      }}
    />
  );
};

export type DataPointProps = {
  title: string;
  value: string;
  indicatorColor?: DATA_COLOR;
  trendPercent?: number;
};
export type StandardLayoutProps = DataPointProps;
export type StackedLayoutProps = {
  title: string;
  aggregatedDataPoint: DataPointProps;
  dataPointStack: DataPointProps[];

};

const StandardLayout: React.FC<StandardLayoutProps> = (props) => {
  const {
    title,
    value,
    indicatorColor,
    trendPercent,
  } = props;

  //Used to add space in the layout to align value with title
  //TODO: handle this with pure css
  const indicatorPadding = indicatorColor ? 'leftPadding' : '';

  return (
    <div className={cx(styles.content, styles.StandardLayout)}>
      <div className={styles.title} >
        <Indicator indicatorColor={indicatorColor} />
        <Text
          type='p'
          size='sm'
          color='grey-2'
          weight='semi-bold'
          noWrap={true}
        >
          {title}
        </Text>
      </div>

      <Text
        className={styles[indicatorPadding]}
        type='p'
        size='sm'
        color='grey-2'
        weight='regular'
        noWrap={true}
      >
        {value}
      </Text>
      <Trend trendPercent={trendPercent} />
    </div>
  );

};

const StackedDataPoint: React.FC<DataPointProps> = (props) => {
  const {
    title,
    value,
    indicatorColor,
    trendPercent,
  } = props;

  return (
    <div className={styles.dataPoint}>
      <div className={styles.dataPointContent}>
        <div className={styles.dataPointLabel}>
          <Indicator indicatorColor={indicatorColor} />
          <Text
            type='p'
          >{title}</Text>
        </div>
        <Text type='p'> {value} </Text>
      </div>
      <Trend trendPercent={trendPercent} alignment='rightAlignment' />
    </div>
  );
};


const StackedLayout: React.FC<StackedLayoutProps> = (props) => {
  const {
    title,
    aggregatedDataPoint,
    dataPointStack,

  } = props;
  return (
    <div className={cx(styles.content, styles.StackedBarContent)}>
      <Text
        type='p'
        size='md'
        weight='semi-bold'
        color='grey-2'
      >{title}</Text>
      <StackedDataPoint
        {...aggregatedDataPoint}
      />
      <div className={styles.seperator} />
      {
        dataPointStack &&
        dataPointStack.map(
          (item, index) => <StackedDataPoint key={index} {...item} />,
        )
      }
    </div>
  );
};


export type TooltipChartContentProps = StandardLayoutProps | StackedLayoutProps;

const layouts = {

  //Standard Layouts
  standardBar: StandardLayout,
  groupedBar: StandardLayout,
  proportionalBar: StandardLayout,
  standardLine: StandardLayout,
  multiLine: StandardLayout,


  //Stacked Layouts
  stackedLine: StackedLayout,
  stackedBar: StackedLayout,

};
const ChartTooltip = forwardRef<HTMLDivElement, ChartTooltipProps & HTMLAttributes<HTMLDivElement>>(
  (props, ref) => {
    const {
      children,
      layout,
    } = props;
    const tooltipProps: any = useChartTooltipPresenter(props);

    const ComponentView = layouts[layout];
    return (
      <Tooltip ref={ref} animation='fade'>
        <div className={cx(styles.ChartTooltip)}>
          <ComponentView  {...tooltipProps} />
          {children}
        </div>
      </Tooltip>
    );

  });

export default ChartTooltip;