import React from 'react';
import cx from 'classnames';
import Modal, { RenderModalBackdropProps } from 'react-overlays/Modal';

import styles from './ModalWrapper.module.scss';
import { ModalWrapperProps } from './types';

const ModalWrapper: React.FC<ModalWrapperProps> = (props) => {
  const {
    className,
    content,
    show,
    onHide,
    backDropClassName,
    autoFocus = true,
  } = props;

  const renderBackdrop = (backDropProps: RenderModalBackdropProps) => {
    return <div {...backDropProps} className={cx(styles.backDrop, backDropClassName)} />;
  };

  return (
    <Modal
      className={cx(styles.modalWrapper, className)}
      onHide={onHide}
      show={show}
      renderBackdrop={renderBackdrop}
      // Specifies the element to which the modal is appended. It should usually be the document
      // body for the sake of assistive technologies
      container={document.body}
      autoFocus={autoFocus}
    >
      {content}
    </Modal>
  );
};

export default ModalWrapper;