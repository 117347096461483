import axiosInstance from './../axios';
import { BFF_URL } from './../../config';
import { DataEntry } from './../types';
import { Location } from './locations';
import { filterDataOnLocation, generateReportsPayload } from '../../utils';
import { SelectedFilterState, View } from '../../../shared_components/organisms/FilterModal/FilterModal.types';
import { getPageView } from '../../../shared_components/organisms/FilterModal/FilterModal.utils';

export type GetOverviewDataPayload = {
  suppliers?: string;
  locations?: string;
  year: number;
  view: View;
};

export type YoyAdjustments = {
  opportunityAdjustment: number;
  jobCreationAdjustment: number;
  totalEmploymentImpact: number;
  wageQualityAdjustment: number;
  diversityQualityAdjustment: number;
};
export type SummaryOld = {
  supplierName: string;
  year: number;
  count: number;
  location?: DataEntry<Location>;
  totalWages: number;
  totalAdjustment: number;
  opportunityAdjustment: number;
  jobCreationAdjustment: number;
  totalEmploymentImpact: number;
  wageQualityAdjustment: number;
  diversityQualityAdjustment: number;
  rank?: number
};
export type ReportRequestPayload = {
  suppliers: string[];
  years: number[];
  locations?: string[];
  groupBy?: string[];
};

export type BaseSummary = {
  supplier: string;
  location: string;
  year: number;
  count: number;
  company?: string;
};

export type SummaryKeys = keyof Summary;

export type Summary = BaseSummary & {
  totalWages: number;
  wageQualityAdjustment: number;
  livingWage: number;
  incomeSatiation: number;
  wageEquity: number;
  diversityAdjustment: number;
  opportunityAdjustment: number;
  seniorityAdjustment: number;
  jobCategoryAdjustment: number;
  jobCreationAdjustment: number;
  totalAdjustment: number;
  totalEmploymentImpact: number;
  unemploymentRate: number;
  hypoUnemploymentRate: number;
  rank?: number;
};
export type EmployeeSummary = BaseSummary & {
  ethnicity: string;
  gender: string;
  wageQualityAdjustment: number;
  livingWage: number;
  incomeSatiation: number;
  wageEquity: number;
  diversityAdjustment: number;
  opportunityAdjustment: number;
  empowerment: string;
  empowermentPercent: number;
  expectedCount: number;
  currentCount: number;
  workforceGap: number;
};
export type EmployeeSummaryKeys = keyof EmployeeSummary;

export type GenderType = 'male' | 'female';
export type EthnicityType = 'asian' | 'nh_white' | 'nh_black' | 'hispanic' | 'native' | 'local_other';
export type EmpowermentType = 'Self Reliant' | 'Reliant' | 'Somewhat Reliant' | 'Empowered' | 'Mostly Reliant';

export type EmpowermentSummary = {
  ethnicity: EthnicityType;
  gender: GenderType;
  empowermentPercent: number;
  empowerment: EmpowermentType;
  year: number;
  count: number;
};


export type RoleSummary = BaseSummary & {
  ethnicity: string;
  gender: string;
  categoryType: string;
  categoryName: string;
  expected: number;
  current: number;
  gap: number;
  adjustment: number;
};
export type RoleSummaryKey = keyof RoleSummary;

export type ReportData = Summary | RoleSummary | EmpowermentSummary |  EmployeeSummary;
export type YoyAdjustmentsForYears = Record<number, YoyAdjustments>;


const convertPayload = (payload: ReportRequestPayload) => {
  const {
    years: numberYears,
  } = payload;
  const years = numberYears.map(year => year.toString());
  return { ...payload, years };
};
export const generateReportsSummary = async (payload: ReportRequestPayload): Promise<{ data: Summary[] }> => {
  return axiosInstance.post(`${BFF_URL}/reports/summary`, { ...convertPayload(payload) });
};
export const generateEmployeeReportsSummary = async (payload: ReportRequestPayload): Promise<{ data: EmployeeSummary[] }> => {
  return axiosInstance.post(`${BFF_URL}/reports/employee_summary`, { ...convertPayload(payload) });
};
export const generateEmpowermentReportsSummary = async (payload: ReportRequestPayload): Promise<{ data: EmpowermentSummary[] }> => {
  return axiosInstance.post(`${BFF_URL}/reports/empowerment_summary`, { ...convertPayload(payload) });
};
export const generateRoleReportsSummary = async (payload: ReportRequestPayload): Promise<{ data: RoleSummary[] }> => {
  return axiosInstance.post(`${BFF_URL}/reports/role_summary`, { ...convertPayload(payload) });
};

export const fetchAllData = async (selectedFilterState: SelectedFilterState, isAdmin?: boolean) => {
  const pageView = getPageView(isAdmin, selectedFilterState.selectedSuppliers);
  const [
    rawSummary,
    rawEmployeeSummary,
    rawEmpowermentSummary,
    rawRoleSummary,
  ] = await Promise.all([
    generateReportsSummary(generateReportsPayload(selectedFilterState, pageView, 'summary')),
    generateEmployeeReportsSummary(generateReportsPayload(selectedFilterState, pageView, 'employee_summary')),
    generateEmpowermentReportsSummary(generateReportsPayload(selectedFilterState, pageView, 'empowerment_summary')),
    pageView === 'Region' ? generateRoleReportsSummary(generateReportsPayload(selectedFilterState, pageView, 'role_summary')) : Promise.resolve(null), // Only call generateRoleReportsSummary if pageView is 'region'
  ]);

  return {
    empowermentSummary: rawEmpowermentSummary.data,
    roleSummary: rawRoleSummary?.data ? rawRoleSummary.data : [],
    rawSummary: filterDataOnLocation(rawSummary.data, selectedFilterState.selectedSuppliers),
    rawEmployeeSummary: rawEmployeeSummary.data,
  };
};