import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Summary } from '../../../lib/api/resources/reports';
import { DatasetConfig } from '../../../modules/Chart/Chart.types';
import { AuthContext } from '../../../modules/auth';
import { PageContext } from '../../../modules/page/PageContext';
import { useFilterModal } from '../../organisms/FilterModal/FilterModal.context';
import { KeyTakeawaysCardStateEnum } from '../../organisms/KeyTakeawaysCard/KeyTakeawaysCard';

import buildDataForPage from './DiversityPage.utils';

const useDiversityPagePresenter = () => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(AuthContext);
  const { filterState: { year }, appliedFilters, pageView } = useFilterModal();
  const { chartsData: summaryTables, isLoading } = useContext(PageContext);
  const {
    rawSummary,
    rawEmployeeSummary,
  } = summaryTables;

  const {
    keyTakeawayValues,
    totalDiversityAdjustment,
  } = buildDataForPage(pageView, year, rawSummary, rawEmployeeSummary);

  // Key Takeaways Card
  const keyTakeawaysState: KeyTakeawaysCardStateEnum = isLoading ? 'Loading' : 'Default';
  const keyTakeawaysCard = {
    state: keyTakeawaysState,
    values: keyTakeawayValues,
  };

  // Diversity Adjustment Intensiy Chart
  const diversityIntensityChartAdjustmentKeys: DatasetConfig<Summary>[] = [{
    datasetLabel: t('chartDatasetKeys.diversityAdjustment'),
    datasetProperty: 'diversityAdjustment',
    color: 'BLUE',
  }];
  const diversityIntensityChart = {
    data: rawSummary,
    adjustmentKeys: diversityIntensityChartAdjustmentKeys,
  };

  // Diversity Adjustment YoY Chart
  const diversityYoYChartAdjustmentKeys: DatasetConfig<Summary>[] = [{
    datasetLabel: t('chartDatasetKeys.diversityAdjustment'),
    datasetProperty: 'diversityAdjustment',
    color: 'GREEN',
  }];
  const diversityYoYChart = {
    data: rawSummary,
    adjustmentKeys: diversityYoYChartAdjustmentKeys,
  };

  return {
    isAdmin,
    view: pageView,
    year,
    // Applied Filters for each Chart Card
    appliedFilters,
    // Total Adjutsment Amount Card
    totalDiversityAdjustment,
    // Gender Ethnicty Bar Chart Props
    genderEthnicityChartData: rawEmployeeSummary,
    // Gender Ethnicty Workforce Gap Chart
    employeeWorkforceGapChartData: rawEmployeeSummary,
    // Key Takeaways Card Props
    keyTakeawaysCard,
    // Intensity Chart Props
    diversityIntensityChart,
    // YoY Chart Props
    diversityYoYChart,
  };
};

export default useDiversityPagePresenter;
