import { useEffect, useState } from 'react';
import { GlossaryHeaderProps } from './GlossaryHeader';
import { GlossaryHeaderType, glossaryHeaderDefinition } from './glossaryHeaderDef';
import { GlossaryHeaderData, generateOverViewPageGlossaryHeader } from './GlossaryHeader.utils';
import { useFilterModal } from '../FilterModal/FilterModal.context';

type UseGlossaryPresenterReturnProps = GlossaryHeaderData & {
  definitions: GlossaryHeaderType;
};

const useGlossaryPresenter = (props: GlossaryHeaderProps): UseGlossaryPresenterReturnProps => {
  const {
    page,
  } = props;

  const { pageView, rawSuppliersData, filterState: { selectedSuppliers } } = useFilterModal();
  const definitions = glossaryHeaderDefinition[page];
  const [glossaryHeader, setGlossaryHeaderProps] = useState<GlossaryHeaderData>();

  useEffect(() => {
    if (pageView !== 'Empty') {
      const result = generateOverViewPageGlossaryHeader(pageView, selectedSuppliers, rawSuppliersData, page);
      setGlossaryHeaderProps(result);
    }
  }, [pageView, rawSuppliersData, selectedSuppliers, page]);

  return {
    ...props,
    definitions,
    pageView: pageView,
    title: glossaryHeader?.title,
    subTitle: glossaryHeader?.subTitle,
    description: glossaryHeader?.description,
    page: page,
  };
};

export default useGlossaryPresenter;