import { RoleSummary, RoleSummaryKey } from '../../../lib/api/resources/reports';
import { CalculatedField, DataFrame } from '../../../modules/data_frame/DataFrame';
import { ETHNICITY_NAME_MAPPING } from './GenderTable.constants';
import { DataRow } from '../WorkforceGapGenderEthnicityCard/types';
import { EMPLOYEE_LEVELS_ROWS } from './GenderTable.constants';
import { getRowsForValue } from '../../../lib/utils';

export const buildDataForGenderTable = (data: RoleSummary[], year: number) => {
  // configured dataframe select fields to get DataRow in value groups
  const dataFields: (RoleSummaryKey | CalculatedField<RoleSummary>)[] = [
    'gender', 'categoryType',
    {
      as: 'ethnicity',
      calculator: (dataRow) => ETHNICITY_NAME_MAPPING[dataRow.row.ethnicity],
    },
    {
      as: 'expectedCount',
      calculator: (dataRow) => dataRow.row.expected,
    },
    {
      as: 'currentCount',
      calculator: (dataRow) => dataRow.row.current,
    },
    {
      as: 'femaleCurrentCount',
      calculator: (dataRow) => {
        return dataRow.row.gender === 'female' ? dataRow.row.current : 0;
      },
    },
    {
      as: 'femaleExpectedCount',
      calculator: (dataRow) => {
        return dataRow.row.gender === 'female' ? dataRow.row.expected : 0;
      },
    },
    {
      as: 'maleCurrentCount',
      calculator: (dataRow) => {
        return dataRow.row.gender === 'male' ? dataRow.row.current : 0;
      },
    },
    {
      as: 'maleExpectedCount',
      calculator: (dataRow) => {
        return dataRow.row.gender === 'male' ? dataRow.row.expected : 0;
      },
    },
  ];

  // to calculate male and female individual gender data for each category and ethnicity
  const firstGroupByKeys: RoleSummaryKey[] = ['gender', 'ethnicity', 'categoryName'];
  // to combine male and female data for each category and ethnicity
  const secondGroupByKeys: RoleSummaryKey[] = ['ethnicity', 'categoryName'];
  // to not sum data for columns in second group by
  const ignoreKeys = ['femaleCurrentCount', 'femaleExpectedCount', 'maleCurrentCount', 'maleExpectedCount'];

  const seniorityDataFrame = new DataFrame(data).query({
    where: [{
      comparator: (dataRow) => (
        dataRow.row.year === year && dataRow.row.categoryType === 'seniority'
      ),
    }],
    select: [
      ...dataFields,
      {
        as: 'categoryName',
        calculator: (dataRow) => EMPLOYEE_LEVELS_ROWS[dataRow.row.categoryName],
      },
    ],
    groupBy: firstGroupByKeys,
    orderBy: ['ethnicity'],
    roundValues: false,
  }).query({
    groupBy: secondGroupByKeys,
    excludedAggregationKeys: ignoreKeys,
    orderBy: ['categoryName'],
    roundValues: false,
  });

  const jobCategoryDataFrame = new DataFrame(data).query({
    where: [{
      comparator: (dataRow) => (
        dataRow.row.year === year && dataRow.row.categoryType === 'job_category'
      ),
    }],
    select: [
      ...dataFields,
      'categoryName',
    ],
    groupBy: firstGroupByKeys,
    orderBy: ['ethnicity'],
  }).query({
    groupBy: secondGroupByKeys,
    excludedAggregationKeys: ignoreKeys,
  });

  // creating a object containing data for each category name
  const seniorityValueGroups = getRowsForValue(seniorityDataFrame.getDataset() as DataRow[], 'categoryName');
  const jobCategoryValueGroups = getRowsForValue(jobCategoryDataFrame.getDataset() as DataRow[], 'categoryName');

  // to get footer labels for gender table
  const ethnicityLabels = [...new Set(seniorityDataFrame.col('ethnicity') as string[])];

  return {
    jobCategoryValueGroups,
    seniorityValueGroups,
    ethnicityLabels,
  };
};