import React from 'react';
import cx from 'classnames';

import styles from './SupplierInfo.module.scss';
import Text from '../../../atoms/Text';
import { SupplierInfoProps } from './SupplierInfo.types';

const SupplierInfo: React.FC<SupplierInfoProps> = (props) => {
  const {
    supplierIndex,
    supplierName,
    price,
    printMode,
  } = props;
  return (
    <div className = {cx(styles.SupplierInfo, (printMode ? styles.PrintSupplierInfo : undefined))}>
      <div className={styles.labelContent}>
        <Text
          type={'p'}
          size={'sm'}
          weight={'semi-bold'}
          color={'royal-blue'}
          printMode={printMode}
        > { supplierIndex } </Text>
        <Text
          type={'p'}
          size={'sm'}
          weight={'semi-bold'}
          color={'royal-blue'}
          printMode={printMode}
        > { supplierName } </Text>
      </div>
      <div className={styles.price}>
        <Text
          type={'p'}
          size={'sm'}
          weight={'semi-bold'}
          color={'grey-2'}
          printMode={printMode}
        > { price } </Text>
      </div>
    </div>
  );

};
 
export default SupplierInfo;