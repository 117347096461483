import React from 'react';
import cx from 'classnames';

import Text from '../Text';

import styles from './SupplierCount.module.scss';
import { SupplierCountProps } from './SupplierCount.types';

const SupplierCount: React.FC<SupplierCountProps> = (props) => {
  const {
    text,
  } = props;
  return (
    <div className = {cx(styles.SupplierCount)}>
      <Text
        size='sm'
        type='p'
        color='grey-8'
        weight='semi-bold'
      >
        {text}
      </Text>
    </div>
  );

};
 
export default SupplierCount;