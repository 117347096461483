import React from 'react';
import cx from 'classnames';

import styles from './LargeHeadingCard.module.scss';
import { LargeHeadingCardProps } from './LargeHeadingCard.types';
import Card from '../../atoms/Card';
import Text from '../../atoms/Text';

type HeaderProps = {
  title?: React.ReactNode;
  className?: string;
  description?: React.ReactNode;
};

export const Header: React.FC<HeaderProps> = (props) => {
  const { title, description, className } = props;

  const descriptionView = description && (
    <Text
      className={cx(styles.headerDescription, className)}
      size='sm'
      type='p'
      color='grey-4'
      weight='semi-bold'
    >
      {description}
    </Text>
  );

  return (
    <div className={styles.Header}>
      <Text
        className={cx(styles.headerTitle, className)}
        size='xl'
        type='p'
        color='grey-2'
        weight='bold'
      >
        {title}
      </Text>
      {descriptionView}
    </div>
  );
};

const LargeHeadingCard: React.FC<LargeHeadingCardProps>
& { Header: React.FC<HeaderProps> } = (props) => {
  const {
    children,
    className,
  } = props;
  return (
    <Card className={cx(styles.LargeHeadingCard, className)}>
      {children}
    </Card>
  );
};

LargeHeadingCard.Header = Header;

export default LargeHeadingCard;
