import React from 'react';
import cx from 'classnames';

import styles from './ErrorBanner.module.scss';
import { ErrorBannerProps } from './ErrorBanner.types';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

const ErrorBanner: React.FC<ErrorBannerProps> = (props) => {
  const {
    error,
  } = props;
  return (
    <div className = {cx(styles.ErrorBanner)}>
      <Icon className={styles.icon} asset='ErrorOctagon' colour='Error' state='Default' />
      <Text
        size='sm'
        weight='regular'
        type='p'
        color='error'
      >
        {error}
      </Text>
    </div>
  );

};
 
export default ErrorBanner;