import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import ErrorBanner from '../../molecules/ErrorBanner';
import TextField from '../../molecules/TextField';

import styles from './CreatePasswordPage.module.scss';
import useCreatePasswordPagePresenter from './CreatePasswordPage.presenter';
import { CreatePasswordPageProps } from './CreatePasswordPage.types';

const CreatePasswordPage: React.FC<CreatePasswordPageProps> = () => {
  const { t } = useTranslation();
  const {
    confirmPasswordTextField,
    createPasswordButton,
    isError,
    passwordTextField,
  } = useCreatePasswordPagePresenter();
  const errorView = isError && (
    <ErrorBanner error={t('GenericError.somethingWentWrong')} />
  );
  return (
    <div className={cx(styles.CreatePasswordPage)}>
      <div className={styles.instructionContent}>
        <Text
          className={styles.label}
          size='xl'
          type='p'
          color='grey-2'
          weight='bold'
        >
          {t('CreatePassword.title')}
        </Text>
        <Text
          className={styles.instruction}
          size='sm'
          type='p'
          color='grey-4'
          weight='regular'
        >
          {t('CreatePassword.description')}
        </Text>
      </div>
      {errorView}
      <div className={styles.formContent}>
        <TextField
          labelText={t('CreatePassword.labels.password')}
          state={passwordTextField.state}
          text={passwordTextField.text}
          inputField={passwordTextField.inputField}
        />
        <TextField
          labelText={t('CreatePassword.labels.confirmPassword')}
          state={confirmPasswordTextField.state}
          text={confirmPasswordTextField.text}
          inputField={confirmPasswordTextField.inputField}
        />
        <Button onClick={createPasswordButton.onClick} style='primary' disabled={createPasswordButton.disabled}>
          <Text
            size='sm'
            type='p'
            weight='semi-bold'
            color='grey-8'
          >
            {t('CreatePassword.buttons.createPassword')}
          </Text>
        </Button>
      </div>
    </div>
  );

};

export default CreatePasswordPage;