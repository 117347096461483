import { MouseState } from '../../../hooks/useMouse';
import { ChartDataset } from '../Chart.types';
import { DataPointProps, StackedLayoutProps, StandardLayoutProps, TooltipChartContentProps } from './ChartTooltip';
import { ChartTooltipProps } from './ChartTooltip.types';

export const formatValueTooltip = (value: number, type: 'percent' | 'currency' = 'currency'): string => {

  if (type === 'percent') return `${Math.round(value)}%`;

  const formattedValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Math.abs(value));

  if (value < 0) {
    return `(${formattedValue})`;
  } else {
    return formattedValue;
  }

};

/**
 * Calculates the percentage change (trend) in a value compared to the previous year.
 * The function is designed to work with an array of numerical data, where each element
 * represents a value for a consecutive year. The trend is calculated as the percentage
 * change from the previous year to the current year at the specified index.
 * 
 * If the specified index is 0 (indicating the first year in the dataset), the function
 * returns 0 as there is no previous year to compare to.
 *
 * @param {number[]} data - An array of numbers(reed:Dataset), each representing the value for a year.
 * @param {number} index - The index of the current year in the data array. This index 
 *                         is used to find the current year's value and the previous year's value.
 * @returns {number} The trend as a percentage, rounded to the nearest whole number. If the
 *                   index is 0, the function returns 0.
 */
export const calcTrendPercent = (data: number[], index: number) => {

  const prevYearValue = data[index - 1];
  const curYearValue = data[index];
  
  //Handles 2 edgecases:
  // 1. Not having a previous years to compare to
  // 2. The value of previous year being zero, causing a division by zero in the calcs
  if (!prevYearValue) {
    return 0;
  }

  //calcuate precentage change between years
  const trendValue = ((curYearValue - prevYearValue) / Math.abs(prevYearValue)) * 100;

  //return rounded off percent to whole number
  return Math.round(trendValue);
};
const buildStandardLayoutProps = (props: ChartTooltipProps): StandardLayoutProps => {
  const {
    datasets,
    dataIndex,
    datasetIndex,
    labels,
    layout,
    chartType,
    aggregateCategory,
  } = props;

  //Extract values for current dataset
  const {
    data,
    label,
    backgroundColor,
  } = datasets[datasetIndex];

  //Set inital properties
  let title = labels[dataIndex];
  let value = formatValueTooltip(data[dataIndex]);
  let trendPercent;
  let indicatorColor;

  //Modify properties based on layout
  if (layout === 'standardLine' || layout === 'multiLine') {
    title = label;
    trendPercent = calcTrendPercent(data, dataIndex);
  }
  if (layout === 'proportionalBar') {
    title = `${labels[dataIndex]} | ${aggregateCategory}`; 
    indicatorColor = backgroundColor;
    value = formatValueTooltip(data[dataIndex], 'percent');
  }
  if (layout === 'groupedBar') {
    title = `${labels[dataIndex]} | ${label}`;
  }

  return {
    title,
    value,
    trendPercent,
    indicatorColor,
  };
};

const computeDataPointStack = (datasets: ChartDataset[], dataIndex: number, layout: string): DataPointProps[] => {
  return datasets.map((set: ChartDataset) => {
    const {
      label: dataPointLabel,
      backgroundColor: dataPointBackgroundColor,
      data,
    } = set;
    //Set inital properties
    const dataPointTitle = dataPointLabel;
    const dataPointValue = formatValueTooltip(data[dataIndex]);
    let dataPointTrendPercent;
    let dataPointIndicatorColor;

    if (layout === 'stackedBar') {
      dataPointIndicatorColor = dataPointBackgroundColor;
    }

    if (layout === 'stackedLine') {
      dataPointTrendPercent = calcTrendPercent(data, dataIndex);
    }

    return {
      title: dataPointTitle,
      value: dataPointValue,
      trendPercent: dataPointTrendPercent,
      indicatorColor: dataPointIndicatorColor,
    };
  });
};

const buildStackedLayoutProps = (props: ChartTooltipProps): StackedLayoutProps => {
  const {
    datasets,
    dataIndex,
    datasetIndex,
    labels,
    layout,
    chartType,
  } = props;
  //Extract values for current dataset
  const {
    data,
    label,
    backgroundColor,
    gender,
  } = datasets[datasetIndex];

  //Aggregate Data
  let title = label;

  //Pull first item in dataset to render heading
  const [firstElement, ...restOfArray] = datasets;
  const aggregatedDataPoint = {
    title: firstElement.label,
    value: formatValueTooltip(firstElement.data[dataIndex]),
  };

  if (layout === 'stackedBar') {
    title = labels[dataIndex];
  }
  if (layout === 'stackedLine') {
    title = `${labels[dataIndex]} | ${label}`;
  }

  //Compute Stack Values
  const dataPointStack: DataPointProps[] = computeDataPointStack(restOfArray, dataIndex, layout);
  return {
    title,
    aggregatedDataPoint,
    dataPointStack,
  };
};

/**
 * Builds the props for all of the possible layouts
 */
export const buildChartTooltipProps = (props: ChartTooltipProps): TooltipChartContentProps => {
  const {
    layout,
    datasets,
    labels,
    dataIndex,
    datasetIndex,
  } = props;

  if (dataIndex < 0 || datasetIndex < 0) {
    return {
      title: '',
      value: '',
    };
  }

  switch (layout) {

    case 'standardBar':
    case 'proportionalBar':
    case 'groupedBar':
    case 'standardLine':
    case 'multiLine':
      return { ...buildStandardLayoutProps({ ...props }) };
      break;
    case 'stackedBar':
    case 'stackedLine':

      return { ...buildStackedLayoutProps({ ...props }) };
      break;
  }
  return {
    title: `DataIndex${dataIndex} | DatasetIndex ${datasetIndex}`,
    value: 'Labels' + labels.join(' | '),
  };
};


/*
*   This function calcuates the positioning of the tooltip relative
*   to it's bounding element and anchored to the mouse pointer.
*   It handles inverting the tooltip position to keep with in bounding box
*/
export const calcTooltipPosition = (mouse: MouseState, tooltip: HTMLDivElement, boundingElement) => {

  const {
    width: tooltipWidth,
    height: tooltipHeight,
  } = tooltip.getBoundingClientRect();

  //calculate positon to keep in view of bounding element
  //if outside of bounding element, flip position
  let x = mouse.elementX + 10; //offset of 10 to match designs
  let y = mouse.elementY + 15; //offset of 15 to match designs

  if (mouse.elementX + tooltipWidth + 10 > boundingElement.getBoundingClientRect().width) {
    x = mouse.elementX - 5 - tooltipWidth;//offset of 5 to match designs
  }
  if (mouse.elementY + tooltipHeight + 15 > boundingElement.getBoundingClientRect().height) {
    y = mouse.elementY - 5 - tooltipHeight;//offset of 5 to match designs
  }

  return {
    x,
    y,
  };
}; 