import { DASHBOARD_ROUTES } from '../../../lib/constants';

export const DIMENSIONS_NAV_ROUTES = {
  overview: {
    path: DASHBOARD_ROUTES.overview,
    labelTranslationKey: 'topNav.overview',
  },
  wageQuality: {
    path: DASHBOARD_ROUTES.wageQuality,
    labelTranslationKey: 'topNav.wageQuality',
  },
  diversity: {
    path: DASHBOARD_ROUTES.diversity,
    labelTranslationKey: 'topNav.diversity',
  },
  opportunity: {
    path: DASHBOARD_ROUTES.opportunity,
    labelTranslationKey: 'topNav.opportunity',
  },
  jobCreation: {
    path: DASHBOARD_ROUTES.jobCreation,
    labelTranslationKey: 'topNav.jobCreation',
  },
};