import axiosInstance from '../axios';
import { BFF_URL } from '../../config';
import { DataEntry } from '../types';


export type Region = {
  name: string;
};

export const getRegions = async (): Promise<DataEntry<Region>[]> => {
  const { data } = await axiosInstance.get<DataEntry<Region>[]>(
    `${BFF_URL}/regions`,
  );
  return data;
  
};
  
  