export const LegendDefs = {
  'Total Employment Impact': 'Total Wages Paid by a firm, plus or minus adjustments for diversity, opportunity, wage quality and local job creation.',
  'Total Adjustment': 'The sum of all adjustment values.',
  'Opportunity Adjustment': 'Determines the negative cost to the workforce based on whether the organization’s Job categories and seniority levels are representative of the firm’s demographics.',
  'Job Creation Adjustment': 'Determines the positive impact on the local labor community based on job creation.',
  'Wage Quality Adjustment': 'The sum of living wage, wage equity and income satiation adjustments.',
  'Diversity Adjustment': 'Determines the negative cost to the local labor community based on whether the organization’s workforce is representative of the demographics of the local population. The value may be zero or negative for this adjustment.',
  'Wage Equity': 'Assesses if wages are equitable within job types and seniority levels such that equal pay is earned for equal work.',
  'Income Satiation': 'Assesses whether wages paid are in excess of what is needed for happiness.',
  'Living Wage': 'Assesses whether employees are compensated at a level that meets living wage standards.',
  'Reliant': 'Individuals earning under 50% of living wage as defined by MIT; reliant on external sources to meet basic needs.',
  'Mostly Reliant': 'Individuals between 50-75% of a living wage as defined by MIT; may be reliant on external sources to meet basic needs.',
  'Somewhat Reliant': 'Individuals earning between 75-90% of living wage as defined by MIT.',
  'Self Reliant': 'Individuals earning between 90-100% of living wage as defined by MIT; able to meet basic needs from wages.',
  'Empowered': 'Individuals earning 100%+ of living wage where basic needs are met and a typical experience is financially empowering.',
  'Seniority Adjustment': 'Determines the negative cost to the workforce based on whether the organization’s seniority levels are representative of the firm’s demographics.',
  'Job Category Adjustment': 'Determines the negative cost to the workforce based on whether the organization’s Job categories are representative of the firm’s demographics.',
};

export const TooltipDefs = {
  'Total Wage Quality Adjustment': 'This figure represents the sum of all wage quality adjustments for the selected companies in the selected year',
  'Wage Quality Adjustment per Employee by Supplier | Intensity': 'living wage + wage equity + income satation= wage quality adj/ per employee',
  'Wage Quality Adjustment by Gender & Ethnicity | Aggregate': 'This graph represents the sum of the wage quality adjustments for each demographic group for the selected suppliers.',
  'Employee Empowerment Breakdown by Gender & Ethnicity | Aggregate': "Employee empowerment examines an employees' journey to financial freedom. The financial empowerment stages show where a worker stands in relation to a living wage percentage. This helps better illustrate areas where systemic inequalities, like food insecurity and limited access to affordable housing, may impact workers in specific demographics",
  'Wage Quality Adjustment YoY per Employee | Intensity': 'Analyzing YoY wage quality is crucial for illustrating whether a company is improving wage quality, which ultimately serves as a key driver of job quality.',
  'Total Diversity Adjustment': 'This figure represents the sum of all diversity adjustments for the selected companies in the selected year',
  'Diversity Adjustment per Employee by Supplier | Intensity': 'Diversity Adjustment Intensity measures, on a per employee basis, how closely a company’s gender and racial demographics reflect the demographics of the local population. This figure may be negative or zero.',
  'Diversity Adjustment by Gender & Ethnicity | Aggregate': 'This graph represents the sum of the diversity adjustments, for each demographic group, for the selected suppliers in the selected years.',
  'Diversity Adjustment YoY per Employee | Intensity': 'Analyzing YoY diversity adjustment per employee is crucial for understanding whether a company is improving diversity, which ultimately serves as a key driver of job quality.',
  'Workforce Gap by Gender & Ethnicity': 'The workforce gap in the opportunity adjustment calculates the difference between expected and actual representation for each demographic group in each job category and seniority level within a company. It\'s important to highlight that the "expected" values are not fixed, rather they are determined on a case-by-case basis by calculating the percentage makeup of each demographic group in the local community. ',
  'Workforce Gap by Gender & Ethnicity | Aggregate': 'The workforce gap calculates the difference between expected and actual representation for each demographic group within a company. It\'s important to highlight that the "expected" values are not fixed, rather they are determined on a case-by-case basis by calculating the percentage makeup of each demographic group in the local community.',
  'Total Opportunity Adjustment': 'This figure represents the sum of all opportunity adjustments for the selected companies in the selected year',
  'Opportunity Adjustment per Employee by Supplier | Intensity': 'Opportunity Adjustment = opportunity across job categories + opportunity across seniorities',
  'Opportunity Adjustment by Gender & Ethnicity | Aggregate': 'This graph represents the sum of the Opportunity Adjustments, for each demographic group, for the selected suppliers in the selected years.',
  'Opportunity Adjustment YoY per Employee | Intensity': 'Analyzing YoY Opportunity Adjustment per employee is crucial for understanding whether a company is opportunity is improving in more equitable opportunities for each demographic group in various job categories and senority levels.',
  'Workforce Gap (in Seniority and Job Category) by Gender & Ethnicity': "The workforce gap in the opportunity adjustment calculates the difference between expected and actual representation for each demographic group in each job category and seniority level within a company. It's important to highlight that the 'expected' values are not fixed, rather they are determined on a case-by-case basis by calculating the percentage makeup of each demographic group in the local community.",
  'Total Job Creation Adjustment': 'This figure represents the sum of all job creation adjustments for the selected companies in the selected year',
  'Job Creation Adjustment per Employee by Supplier | Intensity': "Job Creation Adjustment Intensity measures, on a per employee basis, a company's impact on the local labor community based on jobs created",
  'Job Creation Adjustment by State | Intensity': 'Examining job creation intensity by state allows us to highlight areas where creating new jobs can deliver higher impact to the local community over other geographies.',
  'Job Creation Adjustment YoY per Employee | Intensity': 'Analyzing YoY job creation adjustment per employee is important for understanding whether a company is improving job creation.',
  'Total Combined Employment Impact of Selected Suppliers | Aggregate': 'This figure represents the sum of total employment impact for the selected companies in the selected year',
  'Average Employment Impact per Employee of Selected Suppliers | Intensity': 'This figure represents the average of total employment impact, on a per employee basis, for the selected companies in the selected year',
  'Employment Impact Breakdown Per Employee | Intensity': 'Employment Impact Breakdown Per Employee chart shows the distribution of Adjustments on a per employee basis.',
  'Employment Impact per Employee by Supplier | Intensity': 'Employment impact Intensity= Total Employment impact/total # of employees which is important for comparison between companies',
  'Total Employment Impact Adjustment per Employee by Supplier | Intensity': 'Employment impact adjustment Intensity= Total Employment impact/total # of employees which is important for comparison between companies',
  'Employment Impact YoY | Aggregate': 'Sum YoY Employment impact for the selected suppliers in the selected year',
  'Adjustment By Dimension YoY | Aggregate': 'This graph represents the YoY trend for each adjustment for the selected suppliers in the selected year.',
  'Employment Impact YoY | Intensity': 'Sum YoY Employment impact on a per employee basis for the selected suppliers in the selected year ',
  'Adjustment By Dimension YoY | Intensity': 'This graph represents the YoY trend for each adjustment on a per employee basis for the selected suppliers in the selected year.  ',
  'Total Employment Impact': 'Total Wages Paid by a firm,  plus or minus adjustments for diversity, opportunity, wage quality and local job creation.',
  'Opportunity Adjustment': 'Determines the negative cost to the workforce based on whether the organization’s Job categories and seniority levels are representative of the firm’s demographics.',
  'Job Creation Adjustment': 'Determines the positive impact on the local labor community based on job creation.',
  'Wage Quality Adjustment': 'Wage Quality is a significant contributor to human flourishing and varies widely at local and national levels. Transforming communities through the lens of employment requires that we look at the disparity in wages among groups and across geographies as the most vulnerable workers who earn the lowest wages also experience the highest levels of inequality. Wage Quality varies for employees within the same company, and structural inequalities often magnify pay inequity',
  'Diversity Adjustment': 'Determines the negative cost to the local labor community based on whether the organization’s workforce is representative of the demographics of the local population. The value may be zero or negative for this adjustment',
  'Wage Equity': 'Assesses if wages are equitable within job types and seniority levels such that equal pay is earned for equal work. ',
  'Income Satiation': 'Assesses whether wages paid are in excess of what is needed for happiness',
  'Living Wage': 'Assesses whether employees are compensated at a level that meets living wage standards',
  'Self Reliant': 'Individuals earning between 90-100% of living wage as defined by MIT; able to meet basic needs from wages',
  'Reliant': 'Individuals earning under 50% of living wage as defined by MIT; reliant on external sources to meet basic needs',
  'Somewhat Reliant': 'Individuals earning between 75-90% of living wage as defined by MIT ',
  'Empowered': 'Individuals earning 100%+ of living wage where basic needs are met and a  typical experience is financially empowering',
  'Mostly Reliant': 'Individuals between 50-75% of a living wage as defined by MIT; may be reliant on external sources to meet basic needs',
  'Seniority Adjustment': 'Determines the negative cost to the workforce based on whether the organization’s seniority levels are representative of the firm’s demographics.',
  'JobCategory Adjustment': 'Determines the negative cost to the workforce based on whether the organization’s Job categories are representative of the firm’s demographics.',
};

