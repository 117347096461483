import React from 'react';
import cx from 'classnames';

import styles from './ScreenSizeCard.module.scss';
import { ScreenSizeCardProps } from './ScreenSizeCard.types';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import { useTranslation } from 'react-i18next';

const ScreenSizeCard: React.FC<ScreenSizeCardProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation();
  return (
    <div className={cx(styles.ScreenSizeCard, className)}>
      <Icon className={styles.icon} asset='ScreenSize' colour='Primary' state='Selected' />
      <div className={styles.descriptionContent}>
        <Text
          size='lg'
          weight='bold'
          type='p'
          color='grey-2'
        >
          {t('screenSizePage.title')}
        </Text>
        <Text
          size='sm'
          weight='regular'
          type='p'
          color='grey-2'
        >
          {t('screenSizePage.description')}
        </Text>
      </div>
    </div>
  );

};

export default ScreenSizeCard;