import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';

import { LocaleProvider } from './modules/locale';
import { AuthProvider } from './modules/auth';
import { UserProvider } from './modules/user/UserContext';
import { PageProvider } from './modules/page/PageContext';
import { FilterModalProvider } from './shared_components/organisms/FilterModal/FilterModal.context';

const queryClient = new QueryClient();

const AppProvider: React.FC<{}> = ({ children }) => (
  <LocaleProvider>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <UserProvider>
          <FilterModalProvider>
            <PageProvider>
              <Router>
                {children}
              </Router>
            </PageProvider>
          </FilterModalProvider>
        </UserProvider>
      </AuthProvider>
    </QueryClientProvider>
  </LocaleProvider>
);

export default AppProvider;
