import { useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import { forgotPassword } from '../../../lib/api';
import { AUTH_ROUTES, REGEX_EMAIL } from '../../../lib/constants';

const useForgotPasswordPagePresenter = () => {
  const history = useHistory();

  const [email, setEmail] = useState<string>();

  const resetLinkButtonDisabled = useMemo(() => {
    if (email) {
      return !REGEX_EMAIL.test(email);
    }

    return true;
  }, [email]);

  const handleSendResetEmailClick = async () => {
    if (!resetLinkButtonDisabled && email) {
      try {
        await forgotPassword(email);
        history.replace(AUTH_ROUTES.forgotPasswordConfirmed, {
          email,
        });
      } catch (error) {
        // no-op
      }
    }
  };

  return {
    emailInputField: {
      textValue: email,
      onTextChanged: setEmail,
    },
    resetLinkButton: {
      disabled: resetLinkButtonDisabled,
      onClick: handleSendResetEmailClick,
    },
  };
};

export default useForgotPasswordPagePresenter;