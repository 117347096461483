import React from 'react';
import cx from 'classnames';

import styles from './Card.module.scss';
import { CardProps } from './Card.types';

const Card: React.FC<CardProps> = (props) => {
  const {
    children,
    className,
    printMode,
  } = props;
  return (
    <div 
      className = {cx(
        styles.Card, 
        className,
        (printMode ? styles.PrintCard : undefined),
      )}
    >
      {children}
    </div>
  );
};
 
export default Card;
