import React from 'react';
import cx from 'classnames';

import styles from './Text.module.scss';
import { TextProps } from './Text.types';

const Text: React.FC<TextProps> = (props) => {
  const {
    children,
    className,
    size = 'md',
    weight = 'regular',
    color = 'grey-4',
    type = 'p',
    align = 'left',
    underline = false,
    noWrap = false,
    printMode = false,
  } = props;

  const wrap = noWrap ? 'no-wrap' : '';
  const Tag: keyof JSX.IntrinsicElements = type;

  const underlineClass = underline && styles.underline;
  return (
    <div className = {cx(className, styles.Text, (printMode ? styles.PrintText : undefined))}>
      <Tag className={cx(styles[size], styles[weight], styles[color], styles[wrap], styles[align], underlineClass)}>
        {children}
      </Tag>
    </div>
  );

};
export default Text;