import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Summary } from '../../../lib/api/resources/reports';
import { DatasetConfig } from '../../../modules/Chart/Chart.types';
import { AuthContext } from '../../../modules/auth';
import { PageContext } from '../../../modules/page/PageContext';
import { ToggleItems } from '../../molecules/Toggle/Toggle.types';
import { useFilterModal } from '../../organisms/FilterModal/FilterModal.context';
import { KeyTakeawaysCardStateEnum } from '../../organisms/KeyTakeawaysCard/KeyTakeawaysCard';

import { WorkforceGraphType } from './OpportunityPage.types';
import { buildDataForPage } from './OpportunityPage.utils';

const useOpportunityPagePresenter = (
) => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(AuthContext);
  const { chartsData, isLoading } = useContext(PageContext);
  const { pageView, filterState, appliedFilters } = useFilterModal();
  const {
    roleSummary,
    rawSummary,
    rawEmployeeSummary,
  } = chartsData;

  const {
    keyTakeawayValues,
    totalOpportunityAdjustment,
  } = buildDataForPage(rawSummary, pageView, filterState.year, roleSummary);

  // Gender Ethnicity Workforce Gap Table
  const [workforceGraphType, setWorkforceGraphType] = useState<WorkforceGraphType>('Seniority');
  const toggleItemProps: ToggleItems[] = [
    {
      value: 'Seniority',
      onClick: () => setWorkforceGraphType('Seniority'),
      isSelected: workforceGraphType === 'Seniority',
    },
    {
      value: 'Job Category',
      onClick: () => setWorkforceGraphType('Job Category'),
      isSelected: workforceGraphType === 'Job Category',
    },
  ];
  const genderEthnicityWorkforceGapTable = {
    data: roleSummary,
    year: filterState.year,
    toggleItems: toggleItemProps,
    workforceGraphType,
  };

  // Key Takeaways Card
  const keyTakeawaysState: KeyTakeawaysCardStateEnum = isLoading ? 'Loading' : 'Default';
  const keyTakeawaysCard = {
    state: keyTakeawaysState,
    values: keyTakeawayValues,
  };

  // Opportunity Adjustment Intensity Chart
  const opportunityIntensityChartDatasetConfig: DatasetConfig<Summary>[] = [
    {
      datasetLabel: t('chartDatasetKeys.opportunityAdjustment'),
      datasetProperty: 'opportunityAdjustment',
      color: 'PURPLE',
      hidden: true,
    },
    {
      datasetLabel: t('chartDatasetKeys.seniorityAdjustment'),
      datasetProperty: 'seniorityAdjustment',
      color: 'BLUE',
    },
    {
      datasetLabel: t('chartDatasetKeys.jobCategoryAdjustment'),
      datasetProperty: 'jobCategoryAdjustment',
      color: 'GREEN',
    },
  ];
  const opportunityIntensityChart = {
    data: rawSummary,
    datasetConfig: opportunityIntensityChartDatasetConfig,
  };

  // Opportunity Adjustment YoY Chart
  const opportunityYoYChartDatasetConfig: DatasetConfig<Summary>[] = [
    {
      datasetLabel: t('chartDatasetKeys.opportunityAdjustment'),
      datasetProperty: 'opportunityAdjustment',
      color: 'PURPLE',
    },
    {
      datasetLabel: t('chartDatasetKeys.seniorityAdjustment'),
      datasetProperty: 'seniorityAdjustment',
      color: 'BLUE',
      hidden: true,
    },
    {
      datasetLabel: t('chartDatasetKeys.jobCategoryAdjustment'),
      datasetProperty: 'jobCategoryAdjustment',
      color: 'GREEN',
      hidden: true,
    },
  ];
  const opportunityYoYChart = {
    data: rawSummary,
    datasetConfig: opportunityYoYChartDatasetConfig,
  };

  return {
    isAdmin,
    view: pageView,
    // Applied Filters for each Chart Card
    appliedFilters,
    // Total Adjutsment Amount Card
    totalOpportunityAdjustment,
    // Gender Ethnicty Workforce Gap Table Props
    genderTable: genderEthnicityWorkforceGapTable,
    // Gender Ethnicty Bar Chart Props
    genderEthnicityChartData: rawEmployeeSummary,
    // Key Takeaways Card Props
    keyTakeawaysCard,
    // Intensity Chart Props
    opportunityIntensityChart,
    // YoY Chart Props
    opportunityYoYChart,
  };
};

export default useOpportunityPagePresenter;

