import React from 'react';
import cx from 'classnames';

import styles from './ProfileLayout.module.scss';
import { ProfileLayoutProps } from './ProfileLayout.types';
import Header from '../../organisms/Header';
import ProfileRouter from './ProfileLayout.router';

const ProfileLayout: React.FC<ProfileLayoutProps> = (props) => {
  const { className } = props;
  return (
    <div className={cx(styles.ProfileLayout, className)}>
      <Header />
      <ProfileRouter />
    </div>
  );

};

export default ProfileLayout;