import React from 'react';
import cx from 'classnames';

import SkeletonRectangle from '../../../atoms/SkeletonRectangle';
import Text from '../../../atoms/Text';
import KeyTakeawayNumber from '../KeyTakeawayNumber';
import styles from './KeyTakeaway.module.scss';

export type KeyTakeawayProps = {
  state?: 'Default' | 'Loading';
  keyTakeawayIndex?: string | number;
  title?: React.ReactNode;
  description?: React.ReactNode;
  printMode?: boolean;
};
const KeyTakeaway: React.FC<KeyTakeawayProps> = (props) => {
  const {
    state,
    keyTakeawayIndex,
    title,
    description,
    printMode,
  } = props;
  const titleComponent = state === 'Loading' ? <SkeletonRectangle /> : (
    <Text
      color='grey-2'
      size='sm'
      type='p'
      weight='semi-bold'
      className={styles.title}
      printMode={printMode}
    >
      {title}
    </Text>
  );
  const descriptionComponent = state === 'Loading' ? <SkeletonRectangle /> : (
    <Text
      color='grey-4'
      size='xs'
      type='p'
      weight='regular'
      printMode={printMode}
    >
      {description}
    </Text>
  );
  return (
    <div className={cx(styles.KeyTakeaway, (printMode ? styles.PrintKeyTakeaway : undefined))}>
      <div className={styles.topContent}>
        <KeyTakeawayNumber keyTakeawayIndex={keyTakeawayIndex} printMode={printMode}/>
        {titleComponent}
      </div>
      {descriptionComponent}
    </div>
  );

};

export default KeyTakeaway;