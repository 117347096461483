import { buildWorkforceGapDataForEthnicities } from './WorkforceGapGenderEthnicityCard.utils';
import { WorkforceGapGenderEthnicityCardProps } from './types';

const useWorkforceGapGenderEthnicityCardPresenter = (props: WorkforceGapGenderEthnicityCardProps) => {
  const { data, year } = props;
  
  const workforceGapData = buildWorkforceGapDataForEthnicities(data, year);

  return {
    workforceGapData,
  };
};

export default useWorkforceGapGenderEthnicityCardPresenter;