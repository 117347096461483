import { Chart as ChartJS } from 'chart.js';
import { RefObject, useContext, useRef, useState } from 'react';
import ChartCardContext from '../../shared_components/molecules/ChartCard/ChartCard.context';
import useChartTooltip from './ChartTooltip/useChartTooltip';
import { ChartProps } from './Chart.types';
import { buildOptions, buildPlugins, calculateChartAverage } from './Chart.utils';

const useChartPresenter = (props: ChartProps, tooltipRef: RefObject<HTMLDivElement>) => {
  // Set to less than zero for null checking purposes
  const [dataIndex, setDataIndex] = useState(-1);
  const [datasetIndex, setDatasetIndex] = useState(-1);

  const chartRef = useRef<ChartJS<'bar' | 'line', number[], string> | null>(null);

  const {
    alignment,
    orientation = 'vertical',
    showAverageLine,
    isAnnotated = false,
    displayXAxis = true,
    displayXAxisGrid = true,
    displayYAxisGrid = false,
    stacked = true,
    minX,
    maxX,
    annotationFormatter,
    ref = null,
    datasets,
    type,
    labels,
    printMode,
  } = props;

  const { openTooltip, closeTooltip, isTooltipOpen, mouseRef } = useChartTooltip(tooltipRef);
  const { isExpanded } = useContext(ChartCardContext);


  const externalFunction = (context) => {
    const { tooltip: TooltipModel, chart } = context;
    if (TooltipModel.dataPoints && TooltipModel.dataPoints.length) {
      const { dataIndex: dIndex, datasetIndex:dsIndex } = TooltipModel.dataPoints[0];

      //If bar is hovered over, open tooltip and added mouse listner to follow movement
      if ( TooltipModel.opacity === 0 && isTooltipOpen && dIndex > -1 ) { 

        closeTooltip(); 
        
      } else if ( 
        TooltipModel.opacity !== 0 && //If chartjs tooltip is no already open
        (dataIndex != dIndex || //if dataindex or datasetindex has changed or 
        datasetIndex != dsIndex || 
        !isTooltipOpen) &&
        dIndex > -1 
      ) {
        setDataIndex( dIndex );
        setDatasetIndex( dsIndex );
        openTooltip();        
      }
    }

  };

  const avgLineEnterHandler = () => {
    console.log('Entered');
  };

  const avgLineExitHandler = () => {
    console.log('Exited');
  };

  const chartAvg = showAverageLine ? calculateChartAverage(datasets, stacked) : undefined;

  const options = buildOptions(
    alignment,
    orientation,
    displayXAxis,
    displayXAxisGrid,
    displayYAxisGrid,
    chartAvg,
    stacked,
    minX,
    maxX,
    annotationFormatter,
    externalFunction,
    avgLineEnterHandler,
    avgLineExitHandler,
    type,
    labels,
    printMode || false,
    isExpanded,
  );
  const plugins = buildPlugins(isAnnotated);
  return { options, plugins, mouseRef, chartRef, dataIndex, datasetIndex };
};

export default useChartPresenter;