import React from 'react';
import cx from 'classnames';
import Text from '../../atoms/Text';
import GenderChart from '../../molecules/GenderChart';
import Toggle from '../../molecules/Toggle';
import { EMPLOYEE_LEVELS } from './GenderTable.constants';
import styles from './GenderTable.module.scss';
import useGenderTablePresenter from './GenderTable.presenter';
import { GenderTableProps } from './GenderTable.types';

const GenderTable = (props: GenderTableProps) => {
  const { printMode, toggleItems } = props;
  const {
    categoryNameData, ethnicityLabels, workforceGraphType, categoryNames,
  } = useGenderTablePresenter(props);
  return (
    <div className={cx((printMode ? styles.printPersonBarGraphPairListListDefault : styles.personBarGraphPairListListDefault))}>
      <Toggle toggleItems={toggleItems} printMode={printMode} />
      <div className={cx(styles.tableContainer)}>
        {/* Render table cells with occupation names as rows */}
        {categoryNames.map((categoryName, rowIndex) => (
          <div key={rowIndex} className={cx(styles.rowContainer)}>
            {/* Render occupation names */}
            <div className={cx(styles.rowLabel)}>
              <Text
                color='grey-2'
                type='p'
                weight='regular'
                size='md'
                printMode={printMode}
                align='center'
              >
                {categoryName}
              </Text>
              {workforceGraphType === 'Seniority' && <div className={cx(styles.rowSubTitle)}>
                <Text
                  color='grey-2'
                  type='p'
                  weight='regular'
                  size='md'
                  printMode={printMode}
                  align='center'
                >
                  {EMPLOYEE_LEVELS[categoryName || '']}
                </Text>
              </div>}
            </div>
            {/* Render GenderSvg components for each ethnicity */}
            {categoryNameData[categoryName].map((dataRow, colIndex) => {
              return (
                <div key={colIndex} className={cx(styles.cell)}>
                  <GenderChart
                    {...dataRow}
                    printMode={printMode} />
                </div>
              );
            })}
          </div>
        ))}
        {/* Render occupation names as headers */}
        <div className={cx(styles.headerContainer)}>
          <div className={cx(styles.rowLabel)}></div> {/* Empty cell for the corner */}
          {ethnicityLabels.map((ethnicityLabel: string, index: number) => (
            <div key={index} className={cx(styles.cell, styles.headerCell)}>
              <Text
                color='grey-2'
                type='p'
                weight='regular'
                size='md'
                printMode={printMode}
              >
                {ethnicityLabel}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GenderTable;
