import { Summary } from '../../../lib/api/resources/reports';
import { formatAmount, getYearSummaryTotalAdjustmentValue } from '../../../lib/utils';
import { DataFrame } from '../../../modules/data_frame/DataFrame';
import { View } from '../../organisms/FilterModal/FilterModal.types';
import { getYoYDiffForProperty, getMinMaxForKeyTakeaway } from '../../../lib/utils';

const findMinNegativeField = (summary) => {
  if (!summary) return '';
  const negativeDimensions = {
    'jobCreationAdjustment': 'Job Creation',
    'wageQualityAdjustment': 'Wage Quality',
    'diversityAdjustment': 'Diversity',
    'opportunityAdjustment': 'Opportunity',
  };
  let minField: string | null = null;
  let minValue: number | null = null;

  // Iterate through the fields in the Summary object
  for (const [field, value] of Object.entries(summary)) {
    if (Object.keys(negativeDimensions).includes(field) && typeof value === 'number') {
      if (minValue === null || value < minValue) {
        minValue = value;
        minField = negativeDimensions[field];
      }
    }
  }

  return minField;
};

export const getDatasetAverage = (numeratorKey: string, denominatorKey: string, summaryDF: DataFrame<Record<string, string | number>>) => {
  // Calculate the sum of values for the numerator column
  const numeratorKeySum = summaryDF.columnSum(numeratorKey);
  // Calculate the sum of values for the denominator column
  const denominatorSum = summaryDF.columnSum(denominatorKey);
  return Math.round(numeratorKeySum / denominatorSum);
};

const buildDataForPortfolioView = (summaryDF: DataFrame<Record<string, string | number>>) => {
  const { maxRecord, minRecord } = getMinMaxForKeyTakeaway(summaryDF, ['totalEmploymentImpact'], ['supplier'], 'totalEmploymentImpact', 'totalEmploymentImpact');
  const avgTotalEIIntensity = getDatasetAverage('totalEmploymentImpact', 'count', summaryDF);
  const keyTakeawayValues = {
    'employmentImpactGrowthPotential': {
      supplier: minRecord?.supplier,
    },
    'employmentImpactPerformance': {
      supplier: maxRecord?.supplier,
    },
  };
  return {
    keyTakeawayValues,
    avgTotalEIIntensityAmount: formatAmount(avgTotalEIIntensity),
  };
};

const buildDataForCompanyView = (summaryDF: DataFrame<Record<string, string | number>>) => {
  const intensityKeys = [
    'totalEmploymentImpact',
    'jobCreationAdjustment',
    'wageQualityAdjustment',
    'diversityAdjustment',
    'opportunityAdjustment',
  ];
  const { maxRecord, minRecord } = getMinMaxForKeyTakeaway(summaryDF, intensityKeys, ['location', 'supplier'], 'totalEmploymentImpact', 'totalEmploymentImpact');
  const avgTotalEIIntensity = getDatasetAverage('totalEmploymentImpact', 'count', summaryDF);
  const minField = findMinNegativeField(minRecord) || '';

  const keyTakeawayValues = {
    'employmentImpactGrowthPotential': {
      supplier: minRecord?.supplier,
      location: minRecord?.location,
    },
    'employmentImpactPerformance': {
      supplier: maxRecord?.supplier,
      location: maxRecord?.location,
    },
    'employmentImpact': {
      supplier: minRecord?.supplier,
      location: minRecord?.location,
      dimensionalAdjustment: minField,
    },
  };

  return {
    keyTakeawayValues,
    avgTotalEIIntensityAmount: formatAmount(avgTotalEIIntensity),
  };
};

const buildDataForRegionView = (rawSummary: Summary[], year: number) => {
  const intensityKeys = ['totalEmploymentImpact', 'jobCreationAdjustment', 'wageQualityAdjustment', 'diversityAdjustment', 'opportunityAdjustment'];

  const rawSummaryDF = new DataFrame(rawSummary).query({});
  const { queriedDF } = getMinMaxForKeyTakeaway(rawSummaryDF, intensityKeys, ['location', 'supplier', 'year'], 'totalEmploymentImpact', 'totalEmploymentImpact');

  const currentYearDF = queriedDF.query({
    where: [{
      comparator: (props) => {
        return props.row.year === year;
      },
    }],
  });

  const { diffInPercentage, diffText, currentYearRecord } = getYoYDiffForProperty(queriedDF.getDataset(), 'totalEmploymentImpact', year);

  const avgTotalEIIntensity = currentYearDF.columnSum('totalEmploymentImpact');

  const minField = findMinNegativeField(currentYearRecord) || '';

  const keyTakeawayValues = {
    'employmentImpact': {
      supplier: currentYearRecord?.supplier,
      location: currentYearRecord?.location,
      dimensionalAdjustment: minField,
    },
    'employmentImpactYoY': {
      supplier: currentYearRecord?.supplier,
      location: currentYearRecord?.location,
      diffText: diffText,
      diffInPercentage: diffInPercentage,
      currentYear: year,
      previousYear: (year - 1),
    },
  };
  return {
    keyTakeawayValues,
    avgTotalEIIntensityAmount: formatAmount(avgTotalEIIntensity),
  };
};

export const buildDataForPage = (rawSummary: Summary[], year: number, pageView: View) => {
  const {
    summaryDF,
    totalAdjustmentValue,
  } = getYearSummaryTotalAdjustmentValue(rawSummary, 'totalEmploymentImpact', year);
  if (pageView === 'Portfolio') {
    return {
      ...buildDataForPortfolioView(summaryDF),
      totalEIAmount: formatAmount(totalAdjustmentValue),
    };
  }

  if (pageView === 'Company') {
    return {
      ...buildDataForCompanyView(summaryDF),
      totalEIAmount: formatAmount(totalAdjustmentValue),
    };
  }

  return {
    ...buildDataForRegionView(rawSummary, year),
    totalEIAmount: formatAmount(totalAdjustmentValue),
  };
};

