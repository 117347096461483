import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../auth';
import { AxiosError } from 'axios';
import { User, getUser } from '../../lib/api/resources/user';

export type UserContextValue = {
  user?: User;
  updateUser: (user?: User) => void;
  refetchUser: () => Promise<void>;
  isLoading?: boolean;
  error?: Error;
};

const initialUserContext: UserContextValue = {
  updateUser: () => {},
  refetchUser: () => Promise.resolve(),
};

export const UserContext = createContext<UserContextValue>(initialUserContext);

export const UserProvider: React.FC<{}> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [error, setError] = useState<Error>();
  const { account } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUser = async () => {
    setIsLoading(true);
    try {
      const userResponse = await getUser();
      setUser(userResponse);
      setError(undefined);
    } catch (e) {
      if (e instanceof AxiosError && e.response?.status === 404) {
        setError(e);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (account) {
      void fetchUser();
    }
  }, [account]);

  const refetchUser = async () => {
    await fetchUser();
  };

  const updateUser = (newUser?: User) => {
    if (newUser) {
      setUser(newUser);
    }
  };

  return (
    <UserContext.Provider value={{
      user,
      updateUser,
      refetchUser,
      error,
      isLoading,
    }}>
      {children}
    </UserContext.Provider>
  );
};