import { EmployeeSummary, EmployeeSummaryKeys, EmpowermentSummary, Summary, SummaryKeys } from '../../../lib/api/resources/reports';
import { formatAmount, getMinMaxForKeyTakeaway, getYearSummaryTotalAdjustmentValue, getYoYDiffForProperty } from '../../../lib/utils';
import { DataFrame } from '../../../modules/data_frame/DataFrame';
import { View } from '../../organisms/FilterModal/FilterModal.types';
import { ETHNICITY_NAME_MAPPING, GENDER_MAPPING } from '../../organisms/GenderTable/GenderTable.constants';

import { FormatedEmpowermentSummary } from './WageQualityPage.types';

const buildDataForPortfolioView = (
  summaryDF: DataFrame<Record<string, string | number>>,
  employeeSummaryDF: DataFrame<Record<string, string | number>>,
) => {
  const summaryIntensityKeys: SummaryKeys[] = ['wageQualityAdjustment', 'wageEquity', 'incomeSatiation', 'livingWage'];
  const {
    maxRecord: maxWageQualityAdjSupplier,
    minRecord: minWageQualityAdjSupplier,
    queriedDF,
  } = getMinMaxForKeyTakeaway(summaryDF, summaryIntensityKeys, ['supplier'], 'wageQualityAdjustment', 'wageQualityAdjustment');

  const employeeSummaryIntensityKeys: EmployeeSummaryKeys[] = ['wageEquity', 'incomeSatiation', 'ethnicity', 'gender'];
  const {
    maxRecord: maxIncomeSatiationAdjSuppliers,
    minRecord: minIncomeSatiationAdjSuppliers,
  } = getMinMaxForKeyTakeaway(employeeSummaryDF, employeeSummaryIntensityKeys, ['ethnicity', 'gender'], 'wageEquity', 'incomeSatiation', false);

  const keyTakeawayValues = {
    'wageQualityBreakdown': {
      supplier: minWageQualityAdjSupplier?.supplier,
    },
    'wageQualityPerformance': {
      supplier: maxWageQualityAdjSupplier?.supplier,
    },
    'wageEquityAdjustment': {
      ethnicity: ETHNICITY_NAME_MAPPING[minIncomeSatiationAdjSuppliers?.ethnicity || ''],
      gender: GENDER_MAPPING[minIncomeSatiationAdjSuppliers?.gender || ''],
    },
    'incomeSatiationAdjustment': {
      ethnicity: ETHNICITY_NAME_MAPPING[maxIncomeSatiationAdjSuppliers?.ethnicity || ''],
      gender: GENDER_MAPPING[maxIncomeSatiationAdjSuppliers?.gender || ''],
    },
  };

  return {
    summary: queriedDF,
    keyTakeawayValues,
  };
};

const buildDataForCompanyView = (
  summaryDF: DataFrame<Record<string, string | number>>,
  employeeSummaryDF: DataFrame<Record<string, string | number>>,
) => {
  const summaryIntensityKeys: SummaryKeys[] = ['wageQualityAdjustment', 'wageEquity', 'incomeSatiation', 'livingWage'];
  const {
    maxRecord: maxWageQualityAdjSupplier,
    minRecord: minWageQualityAdjSupplier,
    queriedDF,
  } = getMinMaxForKeyTakeaway(summaryDF, summaryIntensityKeys, ['supplier', 'location'], 'wageQualityAdjustment', 'wageQualityAdjustment');

  const employeeSummaryIntensityKeys: EmployeeSummaryKeys[] = ['wageEquity', 'incomeSatiation', 'ethnicity', 'gender'];
  const {
    maxRecord: maxIncomeSatiationAdjSuppliers,
    minRecord: minIncomeSatiationAdjSuppliers,
  } = getMinMaxForKeyTakeaway(employeeSummaryDF, employeeSummaryIntensityKeys, ['ethnicity', 'gender', 'supplier', 'location'], 'wageEquity', 'incomeSatiation', false);

  const keyTakeawayValues = {
    'wageQualityAdjustmentPerLocation': {
      supplier: minWageQualityAdjSupplier?.supplier,
      location: minWageQualityAdjSupplier?.location,
    },
    'wageQualityPerformance': {
      supplier: maxWageQualityAdjSupplier?.supplier,
      location: maxWageQualityAdjSupplier?.location,
    },
    'wageEquityAdjustment': {
      supplier: minIncomeSatiationAdjSuppliers?.supplier,
      ethnicity: ETHNICITY_NAME_MAPPING[minIncomeSatiationAdjSuppliers?.ethnicity || ''],
      gender: GENDER_MAPPING[minIncomeSatiationAdjSuppliers?.gender || ''],
    },
    'incomeSatiationAdjustment': {
      supplier: maxIncomeSatiationAdjSuppliers?.supplier,
      ethnicity: ETHNICITY_NAME_MAPPING[maxIncomeSatiationAdjSuppliers?.ethnicity || ''],
      gender: GENDER_MAPPING[maxIncomeSatiationAdjSuppliers?.gender || ''],
    },
  };

  return {
    summary: queriedDF,
    keyTakeawayValues,
  };
};


const buildDataForRegionView = (
  rawSummary: Summary[],
  employeeSummaryDF: DataFrame<Record<string, string | number>>,
  year: number,
) => {
  const summaryIntensityKeys: SummaryKeys[] = ['wageQualityAdjustment', 'wageEquity', 'incomeSatiation', 'livingWage'];
  const rawSummaryDF = new DataFrame(rawSummary).query({});
  const { queriedDF } = getMinMaxForKeyTakeaway(rawSummaryDF, summaryIntensityKeys, ['location', 'supplier', 'year'], '', '');

  const { diffInPercentage, diffText, currentYearRecord } = getYoYDiffForProperty(queriedDF.getDataset(), 'wageQualityAdjustment', year);
  const { minRecord: minWageEquityAdj } = getMinMaxForKeyTakeaway(
    employeeSummaryDF,
    ['supplier', 'location', 'wageEquity'],
    ['gender', 'ethnicity'],
    'wageEquity', 'wageEquity',
    false,
  );

  const keyTakeawayValues = {
    'wageEquity': {
      ethnicity: ETHNICITY_NAME_MAPPING[minWageEquityAdj?.ethnicity || ''],
      gender: GENDER_MAPPING[minWageEquityAdj?.gender || ''],
      supplier: minWageEquityAdj?.supplier,
      location: minWageEquityAdj?.location,
    },
    'wageQualityYoY': {
      supplier: currentYearRecord?.supplier,
      location: currentYearRecord?.location,
      diffText: diffText,
      diffInPercentage: diffInPercentage,
      currentYear: year,
      previousYear: (year - 1),
    },
  };
  return {
    summary: queriedDF.getDataset(),
    keyTakeawayValues,
  };
};

export const buildDataForPage = (view: View, year: number, rawSummary: Summary[], rawEmployeeSummary: EmployeeSummary[]) => {
  const {
    summaryDF,
    totalAdjustmentValue,
  } = getYearSummaryTotalAdjustmentValue(rawSummary, 'wageQualityAdjustment', year);

  const filteredEmployeeSummaryDF = new DataFrame(rawEmployeeSummary).query({
    where: [{
      comparator: (props) => {
        return props.row.year === year;
      },
    }],
  });

  if (view === 'Portfolio') {
    return {
      ...buildDataForPortfolioView(summaryDF, filteredEmployeeSummaryDF),
      totalWageQualityAdjustmentAmount: formatAmount(totalAdjustmentValue),
    };
  }

  if (view === 'Company') {
    return {
      ...buildDataForCompanyView(summaryDF, filteredEmployeeSummaryDF),
      totalWageQualityAdjustmentAmount: formatAmount(totalAdjustmentValue),
    };
  }

  return {
    ...buildDataForRegionView(rawSummary, filteredEmployeeSummaryDF, year),
    totalWageQualityAdjustmentAmount: formatAmount(totalAdjustmentValue),
  };
};

export const formatEmpowermentChartData = ( 
  gender:string, 
  dataset:EmpowermentSummary[], 
): FormatedEmpowermentSummary[] => {

  const df = new DataFrame(dataset);
  
  //Transpose the dataset so the empowerment values become properties with empowerment%
  const transposedDF = df.transposeCols('empowerment', 'empowermentPercent', 0) as unknown as DataFrame<FormatedEmpowermentSummary>;
  
  //Filter dataset to include specific gender, then sum
  const summedDF = transposedDF.query(
    {
      select:[
        'Empowered',
        'Mostly Reliant',
        'Reliant',
        'Self Reliant',
        'Somewhat Reliant',
        'ethnicity',
      ],
      where:[{ comparator:({ row })=>{return row.gender === gender;} }], //select correct gender
      groupBy:['ethnicity'],
      orderBy:['ethnicity'], //TODO: Validate White after Other is okay sorting, may need custome sort if not.
    });
  return summedDF.getDataset() as FormatedEmpowermentSummary[];
};