import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { createUser } from '../../../lib/api';
import { DASHBOARD_ROUTES } from '../../../lib/constants';
import { formatPhoneNumber, isEmptyString } from '../../../lib/utils';
import { UserContext } from '../../../modules/user/UserContext';
import { IconAssetEnum, IconColourEnum } from '../../atoms/Icon/Icon.types';
import { InputFieldProps } from '../../atoms/InputField/InputField.types';
import { CompleteProfilePageState } from './CompleteProfilePage.types';

const formInitialState: CompleteProfilePageState = {
  companyName: '',
  jobTitle: '',
  phoneNumber: '',
};

const useCompleteProfilePagePresenter = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { refetchUser } = useContext(UserContext);
  const [formState, setFormState] = useState<CompleteProfilePageState>(formInitialState);
  const [termsAndConditionsCheck, setTermsAndConditionsCheck] = useState(false);

  const handleTextChange = (field: keyof CompleteProfilePageState) => {
    return (value: string) => {
      setFormState({
        ...formState,
        [field]: value,
      });
    };
  };

  const isFormValid = useMemo(() => {
    const phoneNumber = formState.phoneNumber || '';
    const isCompanyNameEmpty = isEmptyString(formState.companyName);
    const isJobTitleEmpty = isEmptyString(formState.jobTitle);
    const isPhoneNumberEmpty = isEmptyString(phoneNumber);

    if (isCompanyNameEmpty || isJobTitleEmpty || isPhoneNumberEmpty) {
      return false;
    }

    if (phoneNumber.length < 12 || !termsAndConditionsCheck) {
      return false;
    }

    return true;
  }, [formState, termsAndConditionsCheck]);

  const onSaveButtonClicked = async () => {
    if (isFormValid) {
      try {
        await createUser({
          ...formState,
          termsAccepted: termsAndConditionsCheck,
        });
        await refetchUser();
        history.replace(DASHBOARD_ROUTES.overview);
      } catch {
        // no-op
      }
    }
  };

  const phoneNumberInputField: InputFieldProps = {
    type: 'phone',
    maxLength: 12,
    textPlaceholder: t('CompleteProfile.phoneNumberPlaceholder'),
    textValue: formState.phoneNumber,
    onTextChanged: handleTextChange('phoneNumber'),
    preProcessTextInput: formatPhoneNumber,
  };

  const handleCheckboxToggle = () => setTermsAndConditionsCheck(!termsAndConditionsCheck);

  const checkBoxItemIconAsset: IconAssetEnum = termsAndConditionsCheck ? 'CheckboxFilled' : 'CheckboxEmpty';
  const checkBoxItemIconColour: IconColourEnum = termsAndConditionsCheck ? 'Primary' : 'NeutralDisabled';

  return {
    companyNameInputField: {
      textValue: formState.companyName,
      onTextChanged: handleTextChange('companyName'),
    },
    jobTitleInputField: {
      textValue: formState.jobTitle,
      onTextChanged: handleTextChange('jobTitle'),
    },
    phoneNumberInputField,
    saveButton: {
      disabled: !isFormValid,
      onClick: onSaveButtonClicked,
    },
    handleCheckboxToggle,
    checkboxItemIcon: {
      asset: checkBoxItemIconAsset,
      colour: checkBoxItemIconColour,
    },
  };
};

export default useCompleteProfilePagePresenter;