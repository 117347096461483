import React, { useContext } from 'react';
import styles from './styles.module.scss';
import Text from '../../shared_components/atoms/Text';
import TitleBackgroundImage from './title-back.png';
import TitleReportText from './title-text.png';
import TitleLogo from './title-logo.png';
import TitleLine from './title-line.png';
import { UserContext } from '../user/UserContext';
import { isSafari } from '../../lib/utils';

export const TitlePage = () => {
  const { user } = useContext(UserContext);
  return (
    <div className={isSafari ? styles.SafariTitlePage : styles.TitlePage}>
      <img src={TitleBackgroundImage} style={{ width: '100%', height: '100%' }}/>
      <div style={{ 
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        top: 0,
        left: 0,
        padding: '120px', 
        gap: '120px',
        width: '100%',
      }}>
        <img src={TitleReportText} style={{ width: '100%' }}/>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4.75',
          alignSelf: 'flex-end',
        }}>
          <Text 
            type='h1'
            size='lg'
            align='left'
            weight='bold'
            color='grey-2'
            printMode
          >Prepared for:</Text>
          <Text 
            type='h1'
            size='xl'
            align='left'
            weight='bold'
            color='grey-2'
            printMode
          >{user?.content.companyName || 'Company Name'}</Text>
        </div>
      </div>
      <div style={{
        position: 'absolute',
        display: 'flex',
        bottom: 0,
        left: 0,
        paddingRight: '50px',
        paddingLeft: '70px',
        paddingBottom: '70px', 
        width: '100%',
      }}>
        <img style={{ width: '66.8px', height: '42.6px' }} src={TitleLogo} />
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignSelf: 'flex-end',
          alignItems: 'flex-start',
          paddingBottom: '10px',
          width: '100%',
        }}>
          <div style={{ width: '100%', height: '2px', backgroundColor: '#002664' }}/>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            <div style={{ width: '180px', height: '2px', backgroundColor: '#00B1AA' }}/>
            <Text 
              className={styles.bottomText}
              type='h2'
              size='sm'
              align='left'
              weight='bold'
              color='royal-blue'
              printMode
            >TRANSFORMING COMMUNITIES</Text>
          </div>
        </div>
      </div>
    </div>
  );
};