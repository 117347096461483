import cx from 'classnames';
import React from 'react';

import Header from '../../organisms/Header';
import ScreenSizePage from '../../pages/ScreenSizePage';

import styles from './ScreenSizeLayout.module.scss';
import { ScreenSizeLayoutProps } from './ScreenSizeLayout.types';

const ScreenSizeLayout: React.FC<ScreenSizeLayoutProps> = (props) => {
  return (
    <div className = {cx(styles.ScreenSizeLayout)}>
      <Header className={styles.header} showOnlyLogo={true} />
      <ScreenSizePage className={styles.screenSizePage} />
    </div>
  );

};

export default ScreenSizeLayout;
