import { ExpandableComponentProps } from './ExpandableComponent.types';
import { useState } from 'react';

const useAccordionDropdownPresenter = (props: ExpandableComponentProps) => {
  const { Header, content } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);    
  };

  return {
    handleToggle,
    isOpen,
    Header,
    content,
  };
};

export default useAccordionDropdownPresenter;