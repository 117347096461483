import { isEmpty, round } from '../../lib/utils';


//TODO: Implement Custom Aggregators
type CustomAggregatorFunctionProps<T> = {
  acc?
  count?: number;
  countDistinct?: number;
  row: T;

};
type CustomAggregatorFunction<T> = (props: CustomAggregatorFunctionProps<T>) => string | number;

type CallbackFieldProps<T> = {
  count?: number;
  countDistinct?: number;
  row: T;
};

type CalculatedFieldFunction<T> = (props: CallbackFieldProps<T>) => string | number;

export type CalculatedField<T> = {
  calculator: CalculatedFieldFunction<T>;
  as: string;
};

export type OrderDirection = 'asc' | 'desc';

export type QueryComparatorFunction<T> = (props: CallbackFieldProps<T>) => boolean;
export type WhereClause<T> = {
  comparator: QueryComparatorFunction<T>;
};
type QueryProps<T> = {
  select?: (keyof T | CalculatedField<T>)[],
  where?: WhereClause<T>[],
  orderBy?: (keyof T | CalculatedField<T>['as'])[],
  orderDirection?: OrderDirection,
  groupBy?: (keyof T)[],
  roundValues?: boolean,
  excludedAggregationKeys?: (keyof T)[],
};

// This type extracts the possible keys for U. Used in select function to 
// allow the return type be a combination of current properties, or as property from
// the list of calculated fields
type SelectableKeys<T> = keyof T | CalculatedField<T>['as'];

export type DataSetRecord = Record<string, string | number>;
export class DataFrame<T extends DataSetRecord> {

  // Private Properties
  private dataset: T[];

  private labelColumn: keyof T | undefined;

  //TODO:Implement mulit-pass grouping to keep track of counts
  // Used in first pass(Grouping Pass) to hold counts of rows in each grouping
  // private counts: number[]; 


  private groupByKeys: Array<keyof T> = [];

  private orderByKeys: Array<keyof T> = [];
  // private groupedDataSet: T[]; 



  // Constructor
  constructor(dataset: T[]) {
    this.dataset = dataset;

    //set the label column to the first key of the first row by default.
    if (dataset.length > 0) {
      this.labelColumn = Object.keys(dataset[0])[0];
    }


  }

  //Run aggregation
  private runAggregation() {
    //First pass, is a grouping pass. Also, handles counts
    // this.groupedDataSet = groupDataset;

    //Second pass, handles all of the remaining aggregators this includes
    //( AVG, MIN, MAX and Custom)
  }

  //Utility accessor methods. Provide access to the underlying data set

  public col(selectedColumn: keyof T): (string | number)[] {
    return this.dataset.map(record => record[selectedColumn]);
  }

  public cols(selectedColumns: Array<keyof T>): (string | number)[][] {
    return selectedColumns.map(property =>
      this.dataset.map(record => record[property]),
    );
  }

  public getDataset() {
    return this.dataset;
  }

  // public getDataset( omitLabels = false ) {
  //   if (omitLabels) {
  //     //get keys of object
  //     const datasetKeys = Object.keys(this.dataset[0]);

  //     //remove the label key
  //     const datasetKeysNoLabel = datasetKeys.filter(key => key !== this.labelColumn);

  //     //return all the cols with out the label column
  //     return this.cols(datasetKeysNoLabel);
  //   }
  //   return this.dataset;
  // }

  public setLabelCol(col: keyof T) {
    this.labelColumn = col;
  }

  public getLabels() {
    if (this.labelColumn)
      return this.col(this.labelColumn);
    return [];
  }

  //Aggregator and Querying Methods

  //Add properties to group by
  private groupBy(dataset: T[], keys: Array<keyof T>, excludedAggregationKeys?: Array<keyof T>): T[] {

    //null check to skip grouping if none have been specified
    if (isEmpty(keys)) return dataset;

    // Add unique keys for grouping
    const groupByKeys = [...new Set(keys)];

    // Aggregated results
    //using the loose typing of 'any' as a workaround typescript errors. Unfortunately the compiler
    //is not picking up on the typeof type checking that is already being done.

    const aggregated: Record<string, any> = {};

    dataset.forEach(row => {
      // Create a key based on the group by fields
      const groupKey = groupByKeys.map(key => row[key]).join('_');
      const ignoreKeys = excludedAggregationKeys?.map(key => row[key]).join('_');

      if (!aggregated[groupKey]) {
        aggregated[groupKey] = { ...row };
      } else {

        //Loop through each property to sum or concat its value, ignoring the props that are being grouped on.
        Object.keys(row).forEach((key) => {

          //Ignore keys that are being grouped on or Keys that are mentioned to exclude from aggregation
          if (groupByKeys.includes(key) || ignoreKeys?.includes(key)) return;

          //ensure type safety
          if (typeof row[key] === 'number' && typeof aggregated[groupKey][key] === 'number') {
            aggregated[groupKey][key] += row[key];
          } else if (typeof row[key] === 'string' && typeof aggregated[groupKey][key] === 'string') {
            aggregated[groupKey][key] = `${aggregated[groupKey][key]} || ${row[key]}`;
          }
        });
      }
    });

    // Convert the aggregated object back to the type T[]
    // required because of the loose typing above.
    return Object.values(aggregated).map(item => item as T);

  }

  //Order the dataset by the specified keys
  private orderBy<U extends Record<SelectableKeys<T>, string | number>>(dataset: U[], orderBy: Array<keyof T | CalculatedField<T>['as']>, orderDirection: OrderDirection): U[] {
    if (isEmpty(orderBy)) {
      return dataset;
    }
    //add key only if it is unique
    const keys = [...new Set(orderBy)];

    // Sort the dataset based on the specified keys
    return dataset.sort((item1, item2) => {
      for (const key of keys) {
        if (item1[key] < item2[key]) {
          return (orderDirection === 'desc') ? 1 : -1;
        } else if (item1[key] > item2[key]) {
          return (orderDirection === 'desc') ? -1 : 1;
        }
        // If equal, continue to the next key
      }
      return 0; // All keys are equal
    });

  }

  //removes all rows that do not match all comparators. Essentially ANDs them
  private filterDataset(dataset: T[], where: WhereClause<T>[]): T[] {

    if (isEmpty(where)) {
      return dataset;
    }
    return dataset.filter(row => {
      // Check each row against all where clauses
      return where.every(clause => clause.comparator({ row }));
    });
  }


  private select<U extends Record<SelectableKeys<T>, string | number>>(
    dataset: T[],
    selectList: (keyof T | CalculatedField<T>)[],
    roundValues: boolean,
  ): U[] {

    // If the selectList is empty, return the original dataset
    if (isEmpty(selectList)) {
      //convert types to new dataset shape
      return dataset as unknown as U[];
    }

    // Loop through the dataset to:
    // a: copy all properties that are specified as strings into the new row
    // b: add new properties as specified by the 'as' property of all the calculated fields
    const selectedDataset = dataset.map(record => {
      const selected: Record<string, string | number> = {};

      for (const item of selectList) {
        if (typeof item === 'string') {
          selected[item] = round(record[item], roundValues);
        } else if (typeof item === 'object') {
          //TODO: added counts
          const value = item.calculator({ row: record, count: 0, countDistinct: 0 });
          selected[item.as] = round(value, roundValues);
        }
      }
      return selected;
    });

    // Return a dataset that has the updated shape and correct typings
    return selectedDataset as unknown as U[];
  }

  public query<U extends Record<SelectableKeys<T>, string | number>>(props: QueryProps<T>): DataFrame<U> {
    const {
      select = [],
      where = [],
      orderBy = [],
      groupBy = [],
      roundValues = true,
      excludedAggregationKeys = [],
      orderDirection = 'asc',
    } = props;

    const newDataset: T[] = [...this.dataset];

    //Filter dataset    
    const filteredDataset: T[] = this.filterDataset(newDataset, where);

    //Group dataset
    const groupedDataset = this.groupBy(filteredDataset, groupBy, excludedAggregationKeys);
    // const groupedDataset:T[] = [...this.dataset];

    //Select rows from data set
    const selectedDataset: U[] = this.select(groupedDataset, select, roundValues);

    //Order dataset
    const orderedDataset = this.orderBy(selectedDataset, orderBy, orderDirection);

    //return new instance of dataFrame with updated dataset
    return new DataFrame(orderedDataset);
  }



  //Utility desctrucitve functions. They modify the dataset, hence the return a new dataframe


  /**
 * Transforms the DataFrame by transposing specified column values into new property 
 * names with their corresponding values, and populates the new columns with either 
 * existing values or a default value. Effectively performing a jagged array transpose
 * creating a sparse 2xN matrix by default. 
 * By default 0 is used to represent empty values, although 0 can be replaced with a specifed value.
 *
 * Eg. [ 
 *        {city: "Atlanta", population: 100000, size: "medium"},
 *        {city: "New York", population: 10000000, size: "large"}
 *     ] 
 *  When transposeCols( 'size', 'population') is applied, becomes:
 *     [
 *        {city: "Atlanta", medium: 100000, large: 0},
 *        {city: "New York", medium: 0, large: 10000000}
 *     ]
 * 
 * @template T The type of the objects in the DataFrame.
 * @template K The string literal type or union of string literal types representing the keys of the new properties.
 * @template V The type of the values for the new properties, constrained to string or number.
 * @param {keyof T} propertyName The name of the property in the objects of the DataFrame whose values are to be transposed into new property names.
 * @param {keyof T} valueProperty The name of the property in the objects of the DataFrame whose values will populate the new properties created from `propertyName`.
 * @param {V} defaultValue The default value to be assigned to the new properties if a given object does not have a corresponding value in `valueProperty`.
 * @returns {DataFrame<T & Record<K, V>>} A new DataFrame instance with the original dataset augmented by the addition of new properties based on the unique values found in `propertyName`. Each new property's value is either the corresponding value from `valueProperty` or the specified `defaultValue`.
 */

  //TODO: Refactor types to simplify, while still enforcing typings
  public transposeCols<K extends string, V extends string | number>(
    propertyName: keyof T,
    valueProperty: keyof T,
    defaultValue: V,
  ): DataFrame<T & Record<K, V>> {
    const propertyCol = this.col(propertyName);
    const newCols = [...new Set(propertyCol)];

    // Create a new dataset with added properties
    const augmentedDataset = this.dataset.map(row => {

      // Ensure new property name is a string
      const newPropName = String(row[propertyName]);

      // Initialize with current item properties
      const augmentedRow: any = { ...row };

      newCols.map((prop) => {
        // Dynamically add new property or assign default value
        augmentedRow[prop] = (row[propertyName] == prop) ? row[valueProperty] : defaultValue;
      });

      return augmentedRow as T & Record<K, V>;
    });
    // Return a new DataFrame instance with the augmented dataset
    return new DataFrame<T & Record<K, V>>(augmentedDataset);
  }

  /**
   * Generates value groups from the dataset based on the specified column name.
   * @param {keyof T} columnName - The column name to group the dataset by.
   * @returns {Record<string, T[]>} An object containing groups of items based on the column values.
   */
  public valueGroups(columnName: keyof T): Record<string, T[]> {
    // Reducing the dataset to generate value groups
    return this.dataset.reduce((groups, item) => {
      // Extracting the value of the specified column
      const key = item[columnName];
      // Ensure the key is a string or number
      const groupKey = String(key);
      // Initializing an array for the group if it doesn't exist
      groups[groupKey] = groups[groupKey] || [];
      // Adding the item to the corresponding group
      groups[groupKey].push(item);
      return groups;
    }, {});
  }

  /**
 * Calculate the sum of values in the specified column.
 * @param key The key of the column.
 * @returns The sum of values in the column.
 */
  public columnSum<K extends keyof T>(key: K): number {
    // Retrieve column data based on the provided key
    const colData = this.col(key) as number[];

    // Calculate the sum of values in the column using reduce
    const sum = colData.reduce((accumulatedValue, currentValue) => accumulatedValue + currentValue, 0);

    // Return the sum
    return sum;
  }

  //TODO: Implement sort(), a custom sort function
  //TODO: Implmennt filter(), a custom filter function
}

