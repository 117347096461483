import React from 'react';
import { useTranslation } from 'react-i18next';

import GenderEthnicityChart from '../../../modules/Chart/GenderEthnicityChart';
import { GENDER_ETHNICITY_CHART_LEGEND_KEYS } from '../../../modules/Chart/GenderEthnicityChart/GenderEthnicityChart.constants';
import IntensityChart from '../../../modules/Chart/IntensityChart';
import YoYChart from '../../../modules/Chart/YoYChart';
import Card from '../../atoms/Card';
import PrintPage from '../../atoms/Page/PrintPage';
import ChartCard from '../../molecules/ChartCard';
import TotalAdjustmentCard from '../../molecules/TotalAdjustmentCard';
import KeyTakeawaysCard from '../../organisms/KeyTakeawaysCard';
import WorkforceGapGenderEthnicityCard from '../../organisms/WorkforceGapGenderEthnicityCard';

import useDiversityPagePresenter from './DiversityPage.presenter';
import { DiversityPageProps } from './DiversityPage.types';

const DiversityPrintPage: React.FC<DiversityPageProps> = () => {
  const { t } = useTranslation();
  const {
    isAdmin,
    view,
    year,
    appliedFilters,
    totalDiversityAdjustment,
    genderEthnicityChartData,
    employeeWorkforceGapChartData,
    keyTakeawaysCard,
    diversityIntensityChart,
    diversityYoYChart,
  } = useDiversityPagePresenter();

  return (
    <>
      <PrintPage>
        <div style={{
          display: 'flex', flexDirection: 'column', gap: '10px', width: '132px', flexShrink: 0,
        }}>
          <Card printMode>
            <TotalAdjustmentCard page='diversity' value={totalDiversityAdjustment} printMode />
          </Card>
          <KeyTakeawaysCard
            page='diversity'
            view={view}
            state={keyTakeawaysCard.state}
            keyTakeawaysValues={keyTakeawaysCard.values}
            printMode />
        </div>

        <ChartCard
          width='offset'
          printMode
        >
          <ChartCard.Header
            title={view === 'Region'
              ? t('diversityPage.diversityAdjustmentGraphTitle.instensity')
              : t('diversityPage.diversityAdjustmentGraphTitle.forEachSupplier')}
            subtitle={t('filtersApplied', { filters: appliedFilters })}
            isExpandable={false}
            printMode
          />
          {view === 'Region' ? (
            <YoYChart
              height={545}
              width={484}
              stacked={true}
              datasetConfig={diversityYoYChart.adjustmentKeys}
              data={diversityYoYChart.data}
              isIntensity
              printMode
            />
          ) : (
            <IntensityChart
              datasetConfig={diversityIntensityChart.adjustmentKeys}
              alignment='right'
              data={diversityIntensityChart.data}
              height={545}
              width={484}
              printMode
            />
          )}
          <ChartCard.Legend
            datasets={view === 'Region'
              ? diversityYoYChart.adjustmentKeys
              : diversityIntensityChart.adjustmentKeys}
            printMode
          />
        </ChartCard>
      </PrintPage>
      <PrintPage>
        <ChartCard printMode>
          <ChartCard.Header
            title={t('diversityPage.genderEthnicityGraph.title')}
            subtitle={t('filtersApplied', { filters: appliedFilters })}
            isExpandable={false}
            printMode
          />
          <GenderEthnicityChart
            height={545}
            width={631}
            data={genderEthnicityChartData}
            datasetConfig={'diversityAdjustment'}
            printMode
          />
          <ChartCard.Legend datasets={GENDER_ETHNICITY_CHART_LEGEND_KEYS} shouldShowDefinition={false} printMode />
        </ChartCard>
      </PrintPage>

      {isAdmin && view === 'Region' && <PrintPage>
        <ChartCard printMode>
          <ChartCard.Header
            title={t('diversityPage.genderTable.title')}
            subtitle={t('filtersApplied', { filters: appliedFilters })}
            isExpandable={false}
            printMode
          />
          <WorkforceGapGenderEthnicityCard view={view} data={employeeWorkforceGapChartData} year={year} printMode />
        </ChartCard>
      </PrintPage>
      }
    </>
  );
};

export default DiversityPrintPage;

