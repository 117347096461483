import { useTranslation } from 'react-i18next';
import { TotalEICardProps } from './TotalEICard.types';

const useTotalEICardPresenter = (props: TotalEICardProps): TotalEICardProps => {
  const { t } = useTranslation();
  const { type, value, printMode } = props;
  const title = t(`OverviewPage.eiCards.${type}EI`);
  return {
    title,
    value,
    printMode,
  };
};

export default useTotalEICardPresenter;