import { MALE_SYMBOL, FEMALE_SYMBOL } from './GenderChart.constants';
import { GenderChartProps } from './GendersChart.types';

export const buildOptions = (props: GenderChartProps) => {
  const { maleCurrentCount, maleExpectedCount, femaleCurrentCount, femaleExpectedCount } = props;

  const malePercentage = Math.round((maleCurrentCount / (maleExpectedCount || 1)) * 100);
  const femalePercentage = Math.round((femaleCurrentCount / (femaleExpectedCount || 1)) * 100);
  return {
    legend: {
      show: false,
      data: ['current', 'expected'],
      selectedMode: false,
    },
    xAxis: {
      data: ['a', 'b', 'c', 'd', 'e'],
      axisTick: { show: false },
      axisLine: { show: false },
      axisLabel: { show: false },
    },
    yAxis: {
      max: 100,
      splitLine: { show: false },
      axisTick: { show: false },
      axisLine: { show: false },
      axisLabel: { show: false },
    },
    grid: {
      top: 'center',
      left: 'left',
      width: '250%',
      height: '75%',
    },
    series: [
      {
        name: 'Current',
        type: 'pictorialBar',
        symbolClip: true,
        symbolBoundingData: 100,
        symbolPosition: 'center',
        symbolSize: ['100%', '100%'],
        symbolMargin: 2,
        data: [
          {
            value: malePercentage,
            symbol: MALE_SYMBOL,
            itemStyle: {
              color: '#148BCC',
            },
            tooltip: {
              valueFormatter: () => maleCurrentCount,
            },
          },
          {
            value: femalePercentage,
            symbol: FEMALE_SYMBOL,
            itemStyle: {
              color: '#3ACEAD',
            },
            tooltip: {
              valueFormatter: () => femaleCurrentCount,
            },
          },
        ],
        z: 10,
      },
      {
        name: 'Expected',
        type: 'pictorialBar',
        symbolBoundingData: 100,
        animationDuration: 0,
        itemStyle: {
          color: '#ccc',
        },
        data: [
          {
            value: 100,
            symbol: MALE_SYMBOL,
            tooltip: {
              valueFormatter: () => maleExpectedCount,
            },
          },
          {
            value: 100,
            symbol: FEMALE_SYMBOL,
            tooltip: {
              valueFormatter: () => femaleExpectedCount,
            },
          },
        ],
      },
    ],
  };
};