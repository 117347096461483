import React from 'react';
import cx from 'classnames';

import Text from '../../../atoms/Text';
import { LegendGroup } from '../../../molecules/ChartCard/ChartCard';
import GenderChart from '../../../molecules/GenderChart';
import { GENDER_ETHNICITY_GAP_CHART_LEGEND_KEYS } from '../../../molecules/GenderChart/GenderChart.constants';

import styles from './EthnicityGenderPeopleChart.module.scss';
import { EthnicityGenderPeopleChartProps } from './EthnicityGenderPeopleChart.types';

const EthnicityGenderPeopleChart: React.FC<EthnicityGenderPeopleChartProps> = (props) => {
  const { dataRows, printMode } = props;
  return (
    <div className = {cx(styles.EthnicityGenderPeopleChart)}>
      <div className={styles.chartContainer}>
        {dataRows?.map((dataRow, index) => (
          <div className={(printMode ? styles.PrintGenderChart : styles.GenderChart)} key={index}>
            <GenderChart
              {...dataRow}
              className={styles.genderSVG}
              printMode={printMode}
            />
            <Text
              color={'grey-2'}
              size={'sm'}
              type={'p'}
              weight={'regular'}
              align={'center'}
              className={styles.text}
              printMode={printMode}
            >
              {dataRow.ethnicity}
            </Text>
          </div>
        ))}
      </div>

      <LegendGroup className={styles.legendGroup} datasets={GENDER_ETHNICITY_GAP_CHART_LEGEND_KEYS} shouldShowDefinition={false} printMode={printMode} />
    </div>
  );

};
 
export default EthnicityGenderPeopleChart;