import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import SelectSupplierImage from '../../../../resources/images/Employee_ Empowerment.png';
import Button from '../../../atoms/Button';
import Icon from '../../../atoms/Icon';
import Text from '../../../atoms/Text';

import styles from './EmptyStateContent.module.scss';
import useEmptyStateContentPresenter from './EmptyStateContent.presenter';
import { EmptyStateContentProps } from './EmptyStateContent.types';

const EmptyStateContent: React.FC<EmptyStateContentProps> = (props) => {
  const {
    selectSupplierButtonClickHandler,
  } = useEmptyStateContentPresenter(props);
  const { t } = useTranslation();
  return (
    <div className={cx(styles.EmptyStateContent)}>
      <img src={SelectSupplierImage} alt='Empty State' className={styles.image} />
      <div className={cx(styles.descriptionContent)}>
        <Text
          color='grey-2'
          size='md'
          type='p'
          weight='bold'
        >
          {t('genderGraphEmptyStateContent.title')}
        </Text>
        <Text
          color='grey-2'
          size='sm'
          type='p'
          weight='regular'
          align='center'
          className={styles.description}
        >
          {t('genderGraphEmptyStateContent.description')}
        </Text>
      </div>
      <Button
        onClick={selectSupplierButtonClickHandler}
      >
        <Text
          color='grey-4'
          size='md'
          weight='semi-bold'
          type='p'
        >
          {t('genderGraphEmptyStateContent.buttonText')}
        </Text>
        <Icon
          asset='Filter'
          colour='NeutralSubdued'
          state='Default'
        />
      </Button>
    </div>
  );

};
 
export default EmptyStateContent;