import { Summary, SummaryKeys } from '../../../lib/api/resources/reports';
import { calcIntensity } from '../../../lib/utils';
import { View } from '../../../shared_components/organisms/FilterModal/FilterModal.types';
import { CalculatedField, DataFrame } from '../../data_frame/DataFrame';
import { buildChartDatasets } from '../Chart.utils';
import { DatasetConfig } from '../Chart.types';

export const getIntensityDatasetAndLabels = (
  data: Summary[],
  datasetConfig: DatasetConfig<Summary>[],
  view: View,
  year: number,
) => {
  const labelKeys: SummaryKeys[] = view === 'Portfolio' ? ['supplier'] : ['location', 'supplier'];

  //Construct the list to select all intesities for the specified page
  const selectList = datasetConfig.map((dataset) => {
    return {
      as: dataset.datasetProperty,
      calculator: (props) => {
        return calcIntensity(props.row, dataset.datasetProperty);
      },
    };
  });

  // Configure supplierName column field for dataframe
  const labelFieldName = 'supplierName';
  const labelColumn: CalculatedField<Summary> = {
    as: labelFieldName,
    calculator: (props) => {
      return labelKeys.map((summaryKey) => props.row[summaryKey]).join(' | ');
    },
  };

  //Query the dataset
  const intensityDF = new DataFrame(data);
  const queriedDF = intensityDF.query({
    // filter data with year
    where: [{
      comparator: (props) => {
        return props.row.year === year;
      },
    }],
    select: [labelColumn, ...selectList],
    groupBy: labelKeys, // grouping data by supplierName fields
    orderBy: [labelFieldName],
  });

  const datasets = buildChartDatasets(datasetConfig, queriedDF.getDataset() as Summary[], 'bar');

  return {
    labels: queriedDF.col(labelFieldName) as string[],
    chartDatasets: datasets,
  };
};