import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory } from 'react-router';

import { AUTH_ROUTES } from '../../../lib/constants';
import ProtectedRoute from '../../../modules/auth/ProtectedRoute';
import ActionPage from '../../pages/ActionPage';
import CompleteProfilePage from '../../pages/CompleteProfilePage';
import CreateAccountPage from '../../pages/CreateAccountPage';
import CreatePasswordPage from '../../pages/CreatePasswordPage';
import ForgotPasswordPage from '../../pages/ForgotPasswordPage';
import ResendPasswordLinkPage from '../../pages/ResendPasswordLinkPage';
import SignInPage from '../../pages/SignInPage';

const AuthenticationRouter: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Switch>
      <Route path={AUTH_ROUTES.signUp} exact>
        <CreateAccountPage className={className} />
      </Route>
      <Route path={AUTH_ROUTES.signIn} exact>
        <SignInPage className={className} />
      </Route>
      <ProtectedRoute path={AUTH_ROUTES.completeProfile} exact>
        <CompleteProfilePage className={className} />
      </ProtectedRoute>
      <Route path={AUTH_ROUTES.forgotPasswordConfirmed} exact>
        <ResendPasswordLinkPage className={className} />
      </Route>
      <Route path={AUTH_ROUTES.forgotPassword} exact>
        <ForgotPasswordPage className={className} />
      </Route>
      <Route path={AUTH_ROUTES.resetPassword} exact>
        <CreatePasswordPage className={className} />
      </Route>
      <Route path={AUTH_ROUTES.resetPasswordExpired} exact>
        <ActionPage
          className={className}
          labelText={t('ResetPasswordLinkExpired.title')}
          instructions={t('ResetPasswordLinkExpired.description')}
          buttonText={t('ResetPasswordSuccess.buttons.signInButton')}
          link={AUTH_ROUTES.forgotPassword} />
      </Route>
      <Route path={AUTH_ROUTES.updatedPasswordConfirmed} exact>
        <ActionPage
          className={className}
          labelText={t('ResetPasswordSuccess.title')}
          instructions={t('ResetPasswordSuccess.description')}
          buttonText={t('ResetPasswordSuccess.buttons.signInButton')}
          buttonClick={() => {
            // when user clicks back button they won't be redirected to previous page
            history.replace(AUTH_ROUTES.signIn);
          }} />
      </Route>
      {/* If route does not match with above user will be redirected to signin page */}
      <Redirect to={AUTH_ROUTES.signIn} />
    </Switch>
  );
};

export default AuthenticationRouter;
