import { BFF_URL } from '../../config';
import axiosInstance from '../axios';
import { DataEntry } from '../types';
import { Supplier } from './suppliers';

export interface OrganizationInterface {
  name: string;
  suppliers: DataEntry<Supplier>;
}
  type OrganizationType = DataEntry<OrganizationInterface>;
  
export interface UserInterface {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  jobTitle: string;
  phoneNumber: string;
  termsAccepted: boolean;
  role?: number;
  createdAt: Date;
  updatedAt: Date;
  organization:OrganizationType;
}
  
export type User = DataEntry<UserInterface>;
  
export type CreateUserPayload = Pick<UserInterface, 'companyName' | 'jobTitle' | 'phoneNumber' | 'termsAccepted'>;
  
export type UpdateUserPayload = Pick<UserInterface, 'companyName' | 'jobTitle' | 'phoneNumber' | 'firstName' | 'lastName'>;

export const getUser = async (): Promise<User> => {
  const { data } = await axiosInstance.get<User>(`${BFF_URL}/users/me`);
  return data;
};

export const createUser = async (payload: CreateUserPayload): Promise<User> => {
  const { data } = await axiosInstance.post<User>(`${BFF_URL}/users`, {
    ...payload,
  });
  return data;
};

export const updateUser = async (payload: UpdateUserPayload, userId: string): Promise<User> => {
  const { data } = await axiosInstance.put<User>(`${BFF_URL}/users/${userId}`, {
    userData: payload,
  });
  return data;
};