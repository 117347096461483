import React from 'react';
import { useTranslation } from 'react-i18next';

import GenderEthnicityChart from '../../../modules/Chart/GenderEthnicityChart';
import { GENDER_ETHNICITY_CHART_LEGEND_KEYS } from '../../../modules/Chart/GenderEthnicityChart/GenderEthnicityChart.constants';
import IntensityChart from '../../../modules/Chart/IntensityChart';
import YoYChart from '../../../modules/Chart/YoYChart';
import Card from '../../atoms/Card';
import Page from '../../atoms/Page';
import ChartCard from '../../molecules/ChartCard';
import DimensionHeader from '../../molecules/DimensionHeader';
import TotalAdjustmentCard from '../../molecules/TotalAdjustmentCard';
import KeyTakeawaysCard from '../../organisms/KeyTakeawaysCard';
import WorkforceGapGenderEthnicityCard from '../../organisms/WorkforceGapGenderEthnicityCard';

import useDiversityPagePresenter from './DiversityPage.presenter';
import { DiversityPageProps } from './DiversityPage.types';

const DiversityPage: React.FC<DiversityPageProps> = () => {
  const { t } = useTranslation();
  const {
    isAdmin,
    view,
    year,
    appliedFilters,
    totalDiversityAdjustment,
    genderEthnicityChartData,
    employeeWorkforceGapChartData,
    keyTakeawaysCard,
    diversityIntensityChart,
    diversityYoYChart,
  } = useDiversityPagePresenter();

  return (
    <Page>
      <DimensionHeader page={'diversity'} />
      <div style={{
        display: 'flex', flexDirection: 'column', gap: '1.5rem', width: '312px', flexShrink: 0,
      }}>
        <Card>
          <TotalAdjustmentCard page='diversity' value={totalDiversityAdjustment} />
        </Card>
        <KeyTakeawaysCard
          page='diversity'
          view={view}
          state={keyTakeawaysCard.state}
          keyTakeawaysValues={keyTakeawaysCard.values} />
      </div>

      <ChartCard
        width='offset'
      >
        <ChartCard.Header
          title={view === 'Region'
            ? t('diversityPage.diversityAdjustmentGraphTitle.intensity')
            : t('diversityPage.diversityAdjustmentGraphTitle.forEachSupplier')}
          subtitle={t('filtersApplied', { filters: appliedFilters })}
          isExpandable={view !== 'Region' ? true : false}
        />
        {view === 'Region' ? (
          <YoYChart
            height={545}
            stacked={true}
            datasetConfig={diversityYoYChart.adjustmentKeys}
            data={diversityYoYChart.data}
            isIntensity
          />
        ) : (
          <IntensityChart
            datasetConfig={diversityIntensityChart.adjustmentKeys}
            alignment='right'
            data={diversityIntensityChart.data}
            height={545}
          />
        )}
        <ChartCard.Legend
          datasets={view === 'Region'
            ? diversityYoYChart.adjustmentKeys
            : diversityIntensityChart.adjustmentKeys}
        />
      </ChartCard>

      <ChartCard>
        <ChartCard.Header
          title={t('diversityPage.genderEthnicityGraph.title')}
          subtitle={t('filtersApplied', { filters: appliedFilters })}
          isExpandable={false}
        />
        <GenderEthnicityChart
          height={545}
          data={genderEthnicityChartData}
          datasetConfig={'diversityAdjustment'}
        />
        <ChartCard.Legend datasets={GENDER_ETHNICITY_CHART_LEGEND_KEYS} shouldShowDefinition={false} />
      </ChartCard>

      {/* showing Workforce gap for ethinicity and gender only for admin */}
      {isAdmin && <ChartCard>
        <ChartCard.Header
          title={t('diversityPage.genderTable.title')}
          subtitle={t('filtersApplied', { filters: appliedFilters })}
          isExpandable={false}
        />
        <WorkforceGapGenderEthnicityCard view={view} data={employeeWorkforceGapChartData} year={year} />
      </ChartCard>
      }
    </Page>
  );
};

export default DiversityPage;

