import { DataFrame, DataSetRecord } from '../../data_frame/DataFrame';
import { buildChartDatasets, getDatasetPropertiesFromConfig } from '../Chart.utils';
import { DatasetConfig } from '../Chart.types';


/**
 * Transforms a dataset so that each specified field's value becomes a percentage of the sum
 * of all specified fields' values in each row, excluding any label column or fields not explicitly specified.
 * This allows for a proportional comparison of the specified fields across all entries in the dataset.
 *
 * @template T - A generic type extending DataSetRecord, which dictates the structure of the dataset's records.
 * @param {T[]} data - The dataset to be transformed, where each item is a record of type T.
 * @param {keyof T} labelKey - The key in the dataset's records to be used as a label, which is excluded from the calculation.
 * @param {(keyof T)[]} fields - An array of keys from the dataset's records that should be included in the calculation. 
 *                               Values of these fields are summed and then used to calculate each field's percentage of the total.
 * @returns The modified dataset with each specified field's value transformed into its proportional percentage of the sum
 *          of all specified fields' values for that row, alongside the original label column. The function ensures
 *          the dataset retains these calculated proportions, enabling proportional analysis or visualization.
 *
 * @example
 * // Assuming a dataset of products with fields for different sales regions
 * const dataset = [
 *   { product: 'Widget', salesNorth: 100, salesSouth: 200, salesEast: 300 },
 *   { product: 'Gadget', salesNorth: 150, salesSouth: 150, salesEast: 200 },
 * ];
 * const labelKey = 'product';
 * const fields = ['salesNorth', 'salesSouth', 'salesEast'];
 * 
 * const proportionalDataset = createProportionalDataset(dataset, labelKey, fields);
 * // The `proportionalDataset` now contains each sales region's percentage of total sales for each product,
 * // alongside the original `product` label for each record.
 */
export const createProportionalDataset = <T extends DataSetRecord>(
  data: T[],
  labelKey: keyof T,
  fields: (keyof T)[],
):T[] => {

  //Build list of calucated values
  const calcFields = fields.map((field)=>{

    return {

      //replace field in dataset with a value that represents the precentage out of 100. 
      as: String(field),
      calculator: ({ row }) => { 
        //Find the total for all the fields that are being compared
        const sumRow = fields.reduce( (acc, val)=> acc + Number(row[val]), 0);

        //Calculate the precentage for the field
        return (row[field] / (0.0 + sumRow)) * 100.0;
      },
    };
  });

  const df = new DataFrame(data);

  const convertedDF = df.query({
    select:[
      labelKey, //make sure new object contains the field that will be used for labels
      ...calcFields, //append precentages for each specifed value
      'gender',
    ],
    roundValues: false,
  });

  return convertedDF.getDataset() as T[];
};

export const buildProportionalChartDatasets = <T extends DataSetRecord>( 
  data: T[], 
  yLabel: keyof T, 
  datasetConfig: DatasetConfig<T>[], 
) => {
  //Build datasets
  const proportionalizedDataset = createProportionalDataset(
    data, 
    yLabel, 
    getDatasetPropertiesFromConfig(datasetConfig),
  );
  return buildChartDatasets(datasetConfig, proportionalizedDataset);
};

export const buildProportionalChartLabels = <T extends DataSetRecord>(
  data: T[], 
  yLabel: keyof T, 
) => {
  //Build labels
  const df = new DataFrame(data);
  df.setLabelCol(yLabel);
  return df.getLabels() as string[];
};