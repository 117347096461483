import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from './Button.module.scss';
import { isExternalUrl } from './Button.utils';
import { ButtonProps } from './Button.types';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    onClick,
    className,
    style = 'secondary',
    disabled,
    target,
    href,
  } = props;

  const classes = cx(styles.Button, className, styles[style]);
  
  if (href) {
    //Renders a tag if href has been set, and it is an external url
    if (isExternalUrl(href)) {
      return (
        <a
          target={target}
          rel='noreferrer'
          href={href}
          onClick={onClick}
          className={classes}>
          {children}
        </a>
      );
    }

    //Renders a Link if internal link( relative path)
    return (
      <Link
        to={href}
        onClick={onClick}
        target={target}
        className={classes}>
        {children}
      </Link>
    );
  }
  return (
  
    <button 
      ref={ref}
      className = {cx(styles.Button, className, styles[style])} 
      disabled={disabled}
      onClick={onClick}>
      {children}
    </button>

  );

});
 
export default Button;