import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { DASHBOARD_ROUTES } from '../../../lib/constants';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Page from '../../atoms/Page';
import Text from '../../atoms/Text';
import LargeHeadingCard from '../../molecules/LargeHeadingCard';
import TextField from '../../molecules/TextField';

import { UsefulLinks } from './ProfilePage.constants';
import styles from './ProfilePage.module.scss';
import useProfilePagePresenter from './ProfilePage.presenter';
import { ProfilePageProps } from './ProfilePage.types';

type LinkButtonProps = {
  buttonText?: string;
  link?: string;
};

const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { buttonText, link } = props;
  return (
    <Button className={styles.linkButton} href={link} style='profile-link'>
      <Text
        size='sm'
        weight='regular'
        type='p'
        color='grey-2'
      >
        {buttonText}
      </Text>
      <Icon
        asset='ExternalLink'
        colour='Primary'
        state='Default'
      />
    </Button>
  );
};

const UsefulLinkButtons: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <div className = {cx(styles.usefulLinkButtons)}>
      {UsefulLinks.map((linkButton, index) => (
        <LinkButton
          buttonText={t(linkButton.buttonTextTranslationKey)}
          link={linkButton.link}
          key={index}
        />
      ))}
    </div>
  );

};

const ProfilePage: React.FC<ProfilePageProps> = (props) => {
  const { t } = useTranslation();
  const {
    className,
  } = props;

  const {
    companyNameInputField,
    emailInputField,
    firstNameInputField,
    jobTitleInputField,
    lastNameInputField,
    phoneNumberInputField,
    submitButton,
  } = useProfilePagePresenter();
  return (
    <Page className = {cx(styles.ProfilePage, className)}>
      <div className={styles.buttonContent}>
        <Button
          className={styles.backToDashboardButton}
          href={DASHBOARD_ROUTES.overview}
          style='subdued'
        >
          <Icon
            asset='ArrowLeft'
            colour='NeutralDefault'
            state='Default'
          />
          <Text
            size='sm'
            color='grey-2'
            type='p'
            weight='regular'
          >
            {t('MyProfilePage.backToDashboard')}
          </Text>
        </Button>
      </div>
      <div className={styles.formContent}>
        <LargeHeadingCard className={styles.profileCard}>
          <LargeHeadingCard.Header
            title={t('MyProfilePage.title')}
            description={t('MyProfilePage.description')}
          />
          <div className={styles.form}>
            <div className={styles.textFieldGroup}>
              <TextField
                className={styles.textField}
                labelText={t('CreateAccountPage.labels.firstName')}
                inputField={firstNameInputField}
              />
              <TextField
                className={styles.textField}
                labelText={t('CreateAccountPage.labels.lastName')}
                inputField={lastNameInputField}
              />
            </div>
            <div className={styles.textFieldGroup}>
              <TextField
                className={styles.textField}
                state='Caption'
                text={t('MyProfilePage.emailHint')}
                labelText={t('CreateAccountPage.labels.email')}
                inputField={emailInputField}
              />
              <TextField
                className={styles.textField}
                labelText={t('CompleteProfile.labels.phoneNumber')}
                inputField={phoneNumberInputField}
              />
            </div>
            <div className={styles.textFieldGroup}>
              <TextField
                className={styles.textField}
                labelText={t('CompleteProfile.labels.companyName')}
                inputField={companyNameInputField}
              />
              <TextField
                className={styles.textField}
                labelText={t('CompleteProfile.labels.jobTitle')}
                inputField={jobTitleInputField}
              />
            </div>
            <Button className={styles.submitButton} onClick={submitButton.onClick} disabled={submitButton.disabled} style='primary'>
              <Text
                size='sm'
                type='p'
                weight='semi-bold'
                color='grey-8'
              >
                {t('MyProfilePage.save')}
              </Text>
            </Button>
          </div>
        </LargeHeadingCard>

        <div className={styles.rightContent}>
          <LargeHeadingCard className={styles.usefulLinksCard}>
            <LargeHeadingCard.Header
              title={t('MyProfilePage.usefulLinks')}
            />
            <UsefulLinkButtons />
          </LargeHeadingCard>

          <LargeHeadingCard className={styles.needHelpCard}>
            <LargeHeadingCard.Header
              title={t('MyProfilePage.needHelp')}
            />
            <Text
              size='sm'
              color='grey-4'
              weight='regular'
              type='p'
            >
              {t('MyProfilePage.needHelpDescriptions')}
            </Text>
          </LargeHeadingCard>
        </div>
      </div>
    </Page>
  );

};
 
export default ProfilePage;