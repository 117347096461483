import { TooltipChartContentProps } from './ChartTooltip';
import { buildChartTooltipProps } from './ChartTooltip.utils';
import { ChartTooltipProps } from './ChartTooltip.types';

const useChartTooltipPresenter = ( 
  props: ChartTooltipProps, 
): TooltipChartContentProps => {

  const {
    dataIndex,
    datasetIndex,
  } = props;

  //return empty values to avoid errors on page load and in between renders
  if ( dataIndex < 0 || datasetIndex < 0) {
    return {
      title:'',
      value:'',
    };
  }
  
  const tooltipProps = buildChartTooltipProps( { ...props } );
 
  return {
    ...tooltipProps,
  };
};

export default useChartTooltipPresenter;