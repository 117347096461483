import React from 'react';
import PrintPage from '../../shared_components/atoms/Page/PrintPage';
import Card from '../../shared_components/atoms/Card';
import Text from '../../shared_components/atoms/Text';
import styles from './styles.module.scss';

export const NotesPage = () => {
  return (
    <PrintPage>
      <Card className={styles.fullScreenCard}>
        <Text 
          type='h1'
          size='xl'
          align='left'
          weight='bold'
          color='grey-2'
          printMode
        >Additional Notes</Text>
        <div className={styles.notesAreaBox} />
      </Card>
    </PrintPage>
  );
};