import { useLocation } from 'react-router';
import { DIMENSIONS_NAV_ROUTES } from './NavBar.constants';
import { useTranslation } from 'react-i18next';
import { NavItemProps } from './NavItem/NavItem.types';

const useNavBarPresenter = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return {
    navBarItems: Object.values(DIMENSIONS_NAV_ROUTES).map((navItem) => {
      const isActive = navItem.path === pathname;

      return {
        to: navItem.path,
        label: t(navItem.labelTranslationKey),
        isActive,
      } as NavItemProps;
    }),
  };
};

export default useNavBarPresenter;