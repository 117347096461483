import React from 'react';
import { useTranslation } from 'react-i18next';

import IntensityChart from '../../../modules/Chart/IntensityChart';
import YoYChart from '../../../modules/Chart/YoYChart';
import Card from '../../atoms/Card';
import PrintPage from '../../atoms/Page/PrintPage';
import ChartCard from '../../molecules/ChartCard';
import JobMapGraphCard from '../../molecules/JobMapGraphCard';
import TotalAdjustmentCard from '../../molecules/TotalAdjustmentCard';
import KeyTakeawaysCard from '../../organisms/KeyTakeawaysCard';

import useJobCreationPagePresenter from './JobCreationPage.presenter';
import { JobCreationPageProps } from './JobCreationPage.types';
import { Summary } from '../../../lib/api/resources/reports';

const JobCreationPrintPage: React.FC<JobCreationPageProps> = () => {
  const { t } = useTranslation();
  const {
    view,
    appliedFilters,
    totalJobCreationAdjustment,
    jobMapGraphCardData,
    keyTakeawaysCard,
    jobCreationIntensityChart,
    jobCreationYoYChart,
  } = useJobCreationPagePresenter();

  const stateSummaryMap: Record<string, Summary[]> = {};
  jobMapGraphCardData.forEach((entry) => {
    const { location } = entry;
    const locationEntries = stateSummaryMap[location] || [];
    locationEntries.push(entry);
    stateSummaryMap[location] = locationEntries;
  });

  const mapPages = Object.keys(stateSummaryMap).map((key) => {
    return (
      <PrintPage key={key}>
        <ChartCard printMode>
          <ChartCard.Header
            title={t('jobCreationPage.stateGraph.title')}
            subtitle={t('filtersApplied', { filters: appliedFilters })}
            isExpandable={false}
            printMode
          />
          <JobMapGraphCard
            data={jobMapGraphCardData}
            selectedState={key}
            printMode />
        </ChartCard>
      </PrintPage>
    );
  });

  return (
    <>
      <PrintPage>
        <div style={{
          display: 'flex', flexDirection: 'column', gap: '10px', width: '132px', flexShrink: 0,
        }}>
          <Card printMode>
            <TotalAdjustmentCard page='jobCreation' value={totalJobCreationAdjustment} printMode />
          </Card>
          <KeyTakeawaysCard
            page='jobCreation'
            view={view}
            state={keyTakeawaysCard.state}
            keyTakeawaysValues={keyTakeawaysCard.values}
            printMode />
        </div>

        <ChartCard
          width='offset'
          printMode
        >
          <ChartCard.Header
            title={view === 'Region'
              ? t('jobCreationPage.jobCreationAdjustmentGraphTitle.intensity')
              : t('jobCreationPage.jobCreationAdjustmentGraphTitle.forEachSupplier')}
            subtitle={t('filtersApplied', { filters: appliedFilters })}
            isExpandable={false}
            printMode
          />
          {view === 'Region' ? (
            <YoYChart
              height={545}
              width={484}
              stacked={true}
              datasetConfig={jobCreationYoYChart.datasetConfig}
              data={jobCreationYoYChart.data}
              isIntensity
              printMode
            />
          ) : (
            <IntensityChart
              datasetConfig={jobCreationIntensityChart.datasetConfig}
              alignment='left'
              data={jobCreationIntensityChart.data}
              height={545}
              width={484}
              printMode
            />
          )}
          <ChartCard.Legend
            datasets={view === 'Region'
              ? jobCreationYoYChart.datasetConfig
              : jobCreationIntensityChart.datasetConfig}
            printMode
          />
        </ChartCard>
      </PrintPage>
      {mapPages}
    </>
  );
};

export default JobCreationPrintPage;

