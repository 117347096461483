import { Summary, SummaryKeys } from '../../../lib/api/resources/reports';
import { calcIntensity, formatAmount, getMinMaxForKeyTakeaway, getMinMaxOnCalculatedField, getYearSummaryTotalAdjustmentValue, getYoYDiffForProperty } from '../../../lib/utils';
import { DataFrame } from '../../../modules/data_frame/DataFrame';
import { View } from '../../organisms/FilterModal/FilterModal.types';

const buildDataForPortfolioView = (summaryDF: DataFrame<Record<string, string | number>>, intensityKeys: SummaryKeys[]) => {
  const { maxRecord, minRecord } = getMinMaxForKeyTakeaway(
    summaryDF,
    intensityKeys,
    ['supplier'],
    'jobCreationAdjustment',
    'jobCreationAdjustment',
    true,
    [{
      comparator: (props) => {
        return Number(props.row.jobCreationAdjustment) > 0;
      },
    }],
  );

  const keyTakeawayValues = {
    'jobCreationAdjustment': {
      supplier: maxRecord?.supplier,
    },
    'jobCreationPerformance': {
      supplier: minRecord?.supplier,
    },
  };

  return {
    keyTakeawayValues,
  };
};

const buildDataForCompanyView = (summaryDF: DataFrame<Record<string, string | number>>, intensityKeys: SummaryKeys[]) => {
  const { minRecord: largestJobCreationGrowthPotentialSupplier, queriedDF } = getMinMaxForKeyTakeaway(
    summaryDF,
    intensityKeys,
    ['supplier', 'location'],
    'jobCreationAdjustment',
    'jobCreationAdjustment',
  );

  const positiveAggregatedSummary = queriedDF.query({
    where: [{
      comparator: (props) => {
        return Number(props.row.jobCreationAdjustment) > 0;
      },
    }],
  });

  const largestJobCreationAdjustmentSupplier = getMinMaxOnCalculatedField(positiveAggregatedSummary, 'jobCreationAdjustment', 'max');
  const smallestJobCreationAdjustmentSupplier = getMinMaxOnCalculatedField(positiveAggregatedSummary, 'jobCreationAdjustment', 'min');

  const keyTakeawayValues = {
    'jobCreationAdjustment': {
      supplier: smallestJobCreationAdjustmentSupplier?.supplier,
      location: smallestJobCreationAdjustmentSupplier?.location,
    },
    'jobCreationPerformance': {
      supplier: largestJobCreationAdjustmentSupplier?.supplier,
      location: largestJobCreationAdjustmentSupplier?.location,
    },
    'jobCreationGrowthPotential': {
      supplier: largestJobCreationGrowthPotentialSupplier?.supplier,
      location: largestJobCreationGrowthPotentialSupplier?.location,
    },
  };

  return {
    keyTakeawayValues,
  };
};

const buildDataForRegionView = (summary: Summary[], intensityKeys: SummaryKeys[], year: number) => {
  //Construct the list to select all intensities for the specified page
  const selectList = intensityKeys.map((dataset) => {
    return {
      as: dataset,
      calculator: (props) => {
        return calcIntensity(props.row, dataset);
      },
    };
  });
  const queriedDF = new DataFrame(summary).query({
    select: [
      ...selectList,
      'supplier', 'location', 'year',
    ],
    orderBy: ['year'],
  });

  const { diffInPercentage, diffText, currentYearRecord } = getYoYDiffForProperty(queriedDF.getDataset(), 'jobCreationAdjustment', year);

  const keyTakeawayValues = {
    'jobCreationAdjustmentYoY': {
      supplier: currentYearRecord?.supplier,
      location: currentYearRecord?.location,
      diffText: diffText,
      diffInPercentage: diffInPercentage,
      currentYear: year,
      previousYear: (year - 1),
    },
  };

  return {
    keyTakeawayValues,
  };
};

export const buildDataForPage = (view: View, year: number, rawSummary: Summary[]) => {
  const {
    summaryDF,
    totalAdjustmentValue,
  } = getYearSummaryTotalAdjustmentValue(rawSummary, 'jobCreationAdjustment', year);
  const intensityKeys: SummaryKeys[] = ['jobCreationAdjustment'];

  if (view === 'Portfolio') {
    return {
      ...buildDataForPortfolioView(summaryDF, intensityKeys),
      totalJobCreationAdjustment: formatAmount(totalAdjustmentValue),
    };
  }

  if (view === 'Company') {
    return {
      ...buildDataForCompanyView(summaryDF, intensityKeys),
      totalJobCreationAdjustment: formatAmount(totalAdjustmentValue),
    };
  }

  return {
    ...buildDataForRegionView(rawSummary, intensityKeys, year),
    totalJobCreationAdjustment: formatAmount(totalAdjustmentValue),
  };
};
