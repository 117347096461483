import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AUTH_ROUTES } from '../../../lib/constants';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import TextField from '../../molecules/TextField';

import styles from './ForgotPasswordPage.module.scss';
import useForgotPasswordPagePresenter from './ForgotPasswordPage.presenter';
import { ForgotPasswordPageProps } from './ForgotPasswordPage.types';

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = () => {
  const { t } = useTranslation();
  const {
    emailInputField,
    resetLinkButton,
  } = useForgotPasswordPagePresenter();
  return (
    <div className = {cx(styles.ForgotPasswordPage)}>
      <Button
        style='subdued'
        href={AUTH_ROUTES.signIn}
      >
        <Icon asset='ArrowLeft' colour='NeutralDefault' state='Default' />
        <Text
          size='sm'
          weight='regular'
          type='p'
          color='grey-2'
        >
          {t('ForgotPassword.buttons.backToSignIn')}
        </Text>
      </Button>
      <div className={styles.instructionContent}>
        <Text
          size='xl'
          type='p'
          color='grey-2'
          weight='bold'
        >
          {t('ForgotPassword.title')}
        </Text>
        <Text
          size='sm'
          type='p'
          color='grey-4'
          weight='regular'
        >
          {t('ForgotPassword.description')}
        </Text>
      </div>
      <div className={styles.formContent}>
        <TextField
          labelText={t('ForgotPassword.labels.email')}
          inputField={emailInputField}
        />
        <Button onClick={resetLinkButton.onClick} style='primary' disabled={resetLinkButton.disabled}>
          <Text
            size='sm'
            type='p'
            weight='semi-bold'
            color='grey-8'
          >
            {t('ForgotPassword.buttons.sendResetLink')}
          </Text>
        </Button>
      </div>
    </div>
  );

};
 
export default ForgotPasswordPage;