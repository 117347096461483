import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Text from '../../../atoms/Text';

import styles from './JobMapGraphCardRateContent.module.scss';
import { JobMapGraphCardRateContentProps } from './JobMapGraphCardRateContent.types';
import { useFilterModal } from '../../../organisms/FilterModal/FilterModal.context';

const JobMapGraphCardRateContent: React.FC<JobMapGraphCardRateContentProps> = (props) => {
  const {
    printMode,
    showEmptyState,
    selectedStateUnemploymentRate,
    selectedStateHypoUnemploymentRate,
  } = props;

  const { t } = useTranslation();
  const { filterState: { year } } = useFilterModal();

  return (
    <div className={cx(styles.JobMapGraphCardRateContent, (printMode ? styles.PrintJobMapGraphCardRateContent : undefined))}>
      <div className={styles.header}>
        <Text
          color='grey-2'
          size='sm'
          type='p'
          weight='semi-bold'
          printMode={printMode}
        >
          {!showEmptyState && t('jobCreationPage.jobMapGraphCard.rateContent.header', { year })}
        </Text>
      </div>
      <div className={styles.title}>
        <div className={styles.label}>
          <Text
            type='p'
            size='sm'
            weight='regular'
            color={'grey-4'}
            printMode={printMode}
          >
            {!showEmptyState && t('jobCreationPage.jobMapGraphCard.rateContent.unemploymentRateLabel')}
          </Text>
        </div>
        <div className={styles.value}>
          <Text
            type='p'
            size='sm'
            weight='regular'
            color='grey-4'
            printMode={printMode}
          >
            {!showEmptyState && t(
              'jobCreationPage.jobMapGraphCard.rateContent.unemploymentRateValue',
              { value: selectedStateUnemploymentRate },
            )}
          </Text>
        </div>
      </div>
      <div className={styles.title}>
        <div className={styles.label}>
          <Text
            type='p'
            size='sm'
            weight='regular'
            color='grey-4'
            printMode={printMode}
          >
            {!showEmptyState && t('jobCreationPage.jobMapGraphCard.rateContent.unemploymentRateInSupplierAbsenceLabel')}
          </Text>
        </div>
        <div className={styles.value}>
          <Text
            type='p'
            size='sm'
            weight='regular'
            color='grey-4'
            printMode={printMode}
          > {!showEmptyState && t(
            'jobCreationPage.jobMapGraphCard.rateContent.unemploymentRateInSupplierAbsenceValue',
            { value: selectedStateHypoUnemploymentRate },
          )} </Text>
        </div>
      </div>
    </div>
  );

};
 
export default JobMapGraphCardRateContent;