import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { signUp } from '../../../lib/api';
import { AUTH_ROUTES, MIN_PASSWORD_LENGTH } from '../../../lib/constants';
import { decodeInviteToken } from '../../../lib/token';
import { isEmptyString } from '../../../lib/utils';
import { AuthContext } from '../../../modules/auth';
import { TextFieldProps } from '../../molecules/TextField/TextField.types';
import { SignUpState } from './CreateAccountPage.types';
import { isExpiryDatePassed } from './CreateAccountPage.utils';

const formInitialState: SignUpState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

const useCreateAccountPagePresenter = () => {
  const { t } = useTranslation();
  const { refetchAccount } = useContext(AuthContext);

  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const inviteToken = query.get('token');

  if (inviteToken) {
    const payload = decodeInviteToken(inviteToken);
    if (payload) {
      if (isExpiryDatePassed(payload.exp)) {
        history.replace(AUTH_ROUTES.expiredInvitation);
      } else {
        formInitialState.email = payload.email;
      }
    } else {
      // TODO: handle invalid payload
    }
  }

  const [formState, setFormState] = useState<SignUpState>(formInitialState);
  const [formErrorState, setFormErrorState] = useState<SignUpState>(formInitialState);
  const [createAccountButtonDisabled, setCreateAccountButtonDisabled] = useState(true);
  const [isSignUpError, setIsSignUpError] = useState(false);

  const isFormValid = useCallback(() => {
    const invalidFormErrors = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    };

    const isFirstNameEmpty = isEmptyString(formState.firstName);
    const isLastNameEmpty = isEmptyString(formState.lastName);
    const isPasswordEmpty = isEmptyString(formState.password);
    const isConfirmPasswordEmpty = isEmptyString(formState.confirmPassword);

    if (isFirstNameEmpty || isLastNameEmpty || isPasswordEmpty || isConfirmPasswordEmpty) {
      setCreateAccountButtonDisabled(true);
      return;
    }

    if (formState.password.length < MIN_PASSWORD_LENGTH) {
      invalidFormErrors.password = t('CreateAccountPage.errors.passwordShort');
      setFormErrorState(invalidFormErrors);
      setCreateAccountButtonDisabled(true);
      return;
    }

    if (formState.password !== formState.confirmPassword) {
      invalidFormErrors.confirmPassword = t('CreateAccountPage.errors.passwordMismatch');
      setFormErrorState(invalidFormErrors);
      setCreateAccountButtonDisabled(true);
      return;
    }

    setCreateAccountButtonDisabled(false);
    setFormErrorState(formInitialState);
  }, [formState]);

  const handleTextChange = (field: keyof SignUpState) => {
    return (value: string) => {
      setFormState({
        ...formState,
        [field]: value,
      });

      setFormErrorState({
        ...formErrorState,
        [field]: '',
      });
    };
  };

  useEffect(() => {
    isFormValid();
  }, [isFormValid, formState]);

  const handleSignUp = async () => {
    if (!createAccountButtonDisabled && inviteToken) {
      setIsSignUpError(false);
      try {
        // only when all fields are validated and there is an invite token
        await signUp({
          ...formState,
          inviteToken,
        });
        refetchAccount();
        history.replace(AUTH_ROUTES.completeProfile);
      } catch (e) {
        setIsSignUpError(true);
      }
    } 
  };

  const passwordTextField: TextFieldProps = {
    state: formErrorState.password ? 'Error' : 'Caption',
    text: formErrorState.password || t('CreateAccountPage.fieldDescriptions.password'),
    inputField: {
      type: 'password',
      state: formErrorState.password ? 'error' : 'none',
      textValue: formState.password,
      onTextChanged: handleTextChange('password'),
    },
  };

  const confirmPasswordTextFeild: TextFieldProps = {
    inputField: {
      type: 'password',
      state: formErrorState.confirmPassword ? 'error' : 'none',
      textValue: formState.confirmPassword,
      onTextChanged: handleTextChange('confirmPassword'),
    },
    state: formErrorState.confirmPassword ? 'Error' : 'Default',
    text: formErrorState.confirmPassword,
  };

  return {
    isError: isSignUpError,
    firstNameInputField: {
      textValue: formState.firstName,
      onTextChanged: handleTextChange('firstName'),
    },
    lastNameInputField: {
      textValue: formState.lastName,
      onTextChanged: handleTextChange('lastName'),
    },
    emailInputField: {
      disabled: true,
      textValue: formState.email,
    },
    passwordTextField,
    confirmPasswordTextFeild,
    createAccountButton: {
      disabled: createAccountButtonDisabled,
      onClick: handleSignUp,
    },
  };
};

export default useCreateAccountPagePresenter;