import React from 'react';
import cx from 'classnames';

import styles from './Glossary.module.scss';

import useGlossaryPresenter from './Glossary.presenter';

//Components
import Text from '../../atoms/Text';
import Button from '../../atoms/Button';

//Icons
import { ReactComponent as ChevronDownIcon } from '../../../resources/icons/ChevronDown.svg';
import { ReactComponent as ChevronUpIcon } from '../../../resources/icons/ChevronUp.svg';

//Types
import { GlossaryTerm } from './Glossary.types';

type GlossaryTermListProps = {
  terms: GlossaryTerm[];
};
const GlossaryTermList: React.FC<GlossaryTermListProps> = (props) => {
  const {
    terms,
  } = props;
  return (

    <div className = {cx(styles.GlossaryTermList)} >
      {terms.map((term, index) => (
        <div className={styles.termItem} key={index}  >
          <Text
            type="p"
            size='lg'
            weight='bold'
            color="grey-2">{term.title}</Text>
          <Text
            type='p'
            size='sm'
            weight='regular'
            color='grey-4'>{term.desc}</Text>
        </div>
      ))}
         
    </div>
  );
};


export type GlossaryProps = {
  page: string;
  
};

const Glossary: React.FC<GlossaryProps> = (props) => {

  const { 
    isGlossaryVisible,
    handleExpandToggle,
    definitions,
  } = useGlossaryPresenter( props );

  //TODO: handle accessiblity
  return (
    <div className = {cx(styles.Glossary)} >
      <div className= {styles.header}>
        <Text type="h3" size="md" weight='bold' color='grey-2'>Glossary</Text>
        <Button onClick={handleExpandToggle}>
          {isGlossaryVisible ? 'Collapse' : 'View Glossary'}
          {isGlossaryVisible ? <ChevronUpIcon/> : <ChevronDownIcon/>}
          
        </Button>
      </div>
      <div className={cx(styles.content, styles[`${isGlossaryVisible ? 'expanded' : ''}`])}>
        <div className={styles.contentWrapper}>
          <GlossaryTermList terms={definitions}/>
        </div>
      </div> 
    </div>
      
  );

};
 
export default Glossary;