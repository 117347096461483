import { DatasetConfig } from '../../../modules/Chart/Chart.types';
import { DataSetRecord } from '../../../modules/data_frame/DataFrame';

export const GENDER_ETHNICITY_GAP_CHART_LEGEND_KEYS: DatasetConfig<DataSetRecord>[] = [
  {
    color: 'BLUE',
    datasetLabel: 'Male',
    datasetProperty: 'Male',
  },
  {
    color: 'GREEN',
    datasetLabel: 'Female',
    datasetProperty: 'Female',
  },
];

export const MALE_SYMBOL = 'path://M8.65701 78.8953V51.2994H0.772461V27.6458C0.772461 25.4775 1.54449 23.6214 3.08855 22.0773C4.6326 20.5333 6.48876 19.7612 8.65701 19.7612H24.4261C26.5943 19.7612 28.4505 20.5333 29.9946 22.0773C31.5386 23.6214 32.3106 25.4775 32.3106 27.6458V51.2994H24.4261V78.8953H8.65701ZM16.5415 15.819C14.3733 15.819 12.5171 15.0469 10.9731 13.5029C9.42903 11.9588 8.65701 10.1027 8.65701 7.93443C8.65701 5.76618 9.42903 3.91002 10.9731 2.36597C12.5171 0.821911 14.3733 0.0498829 16.5415 0.0498829C18.7098 0.0498829 20.566 0.821911 22.11 2.36597C23.6541 3.91002 24.4261 5.76618 24.4261 7.93443C24.4261 10.1027 23.6541 11.9588 22.11 13.5029C20.566 15.0469 18.7098 15.819 16.5415 15.819Z';
export const FEMALE_SYMBOL = 'path://M12.8114 78.8953V55.2417H0.984619L13.0085 24.8862C13.6656 23.3093 14.684 22.0609 16.0638 21.141C17.4436 20.2212 18.9877 19.7612 20.696 19.7612C22.4043 19.7612 23.9484 20.2212 25.3281 21.141C26.7079 22.0609 27.7264 23.3093 28.3834 24.8862L40.4073 55.2417H28.5805V78.8953H12.8114ZM20.696 15.819C18.5277 15.819 16.6716 15.0469 15.1275 13.5029C13.5835 11.9588 12.8114 10.1027 12.8114 7.93443C12.8114 5.76618 13.5835 3.91002 15.1275 2.36597C16.6716 0.821911 18.5277 0.0498829 20.696 0.0498829C22.8642 0.0498829 24.7204 0.821911 26.2644 2.36597C27.8085 3.91002 28.5805 5.76618 28.5805 7.93443C28.5805 10.1027 27.8085 11.9588 26.2644 13.5029C24.7204 15.0469 22.8642 15.819 20.696 15.819Z';