import { useState } from 'react';
import { InputFieldProps } from './InputField.types';
import { IconAssetEnum } from '../Icon/Icon.types';

const usePresenter = (props: InputFieldProps) => {
  const { type, inputType } = props;

  const [showPassword, setShowPassword] = useState(false);

  const isPasswordField = type === 'password';

  const passwordIconAsset: IconAssetEnum = showPassword ? 'EyeOpen' : 'EyeClosed';

  return {
    ...props,
    inputType: isPasswordField
      ? (showPassword ? 'text' : 'password')
      : inputType,
    passwordIconAsset,
    buttonOnClick: () => {
      if (isPasswordField) setShowPassword(!showPassword);
    },
  };
};

export default usePresenter;