import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LunumLogoImage from '../../../resources/images/LunumLogo.png';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import InitialContainer from '../../atoms/InitialContainer';
import Text from '../../atoms/Text';
import DropdownButton from '../../molecules/DropdownButton';
import NavBar from '../../molecules/NavBar';

import styles from './Header.module.scss';
import { DropdownIconTextItem, HeaderProps } from './Header.types';
import useHeaderPresenter from './Header.presenter';
import PrintPDFButton from '../../../modules/pdf/PrintPDFButton';
import SupplierCount from '../../atoms/SupplierCount';

// render function for account menu items
const accountMenuItemRenderer = (props: DropdownIconTextItem) => {
  const { asset, colour = 'NeutralSubdued', state = 'Default', text, onClick } = props;
  return (
    <Button onClick={onClick} className={styles.accountMenuItem} style='dropdown'>
      <Icon className={styles.icon} asset={asset} colour={colour} state={state} />
      <Text
        size='sm'
        weight='regular'
        type='p'
        color='grey-4'
      >
        {text}
      </Text>
    </Button>
  );
};

// render function for year filter items
const yearFilterItemRenderer = (props) => {
  const { text, onClick } = props;
  return (
    <Button onClick={onClick} className={styles.yearFitlerItem} style='dropdown'>
      <Text
        size='sm'
        weight='regular'
        type='p'
        color='grey-4'
      >
        {text}
      </Text>
    </Button>
  );
};

const Header: React.FC<HeaderProps> = (props) => {
  const { className, showOnlyLogo } = props;
  const { t } = useTranslation();

  const { userInitials, userName, accountMeuItems, yearFilterItems, selectedYear, filterButton, isUserAuthenticated } = useHeaderPresenter();

  // For ScreenSizeLayout we have to show only logo even if user is authenticated
  // that's why use flag showOnlyLogo
  const showFilterNavAccountMenu = !showOnlyLogo && isUserAuthenticated;

  // Button content for user account menu
  const accountMenuButtonView = showFilterNavAccountMenu && (
    <>
      <InitialContainer initials={userInitials} />
      <Text
        size='sm'
        align='left'
        color='grey-2'
        type='p'
        weight='regular'
      >
        {userName}
      </Text>
      <Icon asset='ChevronDown' colour='NeutralDefault' state={'Default'} />
    </>
  );

  // Button content for year filter
  const yearFilterButtonView = (
    <>
      <Text
        size='md'
        align='left'
        color='grey-2'
        type='p'
        weight='semi-bold'
      >
        {selectedYear}
      </Text>
      <Icon asset='ChevronDown' colour='NeutralDefault' state={'Default'} />
    </>
  );

  const printReportAndAccountMenuButtonView = showFilterNavAccountMenu && (
    <div className={styles.PrintAndProfileContent}>
      <PrintPDFButton className={styles.printButton} />
      <DropdownButton
        classes={{
          button: styles.accountMenuButton,
        }}
        buttonType='dropdown'
        buttonContent={accountMenuButtonView}
        list={{
          items: accountMeuItems,
          renderItem: accountMenuItemRenderer,
        }} />
    </div>
  );

  const filterAndNavView = showFilterNavAccountMenu && (
    <div className={styles.filterNavContent}>

      {/* Filter Button and Year Filter Dropdown */}
      <div className={styles.filterContent}>
        <Button className={styles.filterButton} onClick={filterButton.onClick} style='outline'>
          <Text
            size='md'
            weight='semi-bold'
            color='grey-2'
            type='p'
          >
            {t('topNav.selectSuppliers', { count: filterButton.noOfSuppliers })}
          </Text>
          <SupplierCount text={filterButton.noOfSuppliers} />
          <Icon asset='Filter' colour='NeutralDefault' state='Default' />
        </Button>
        <DropdownButton
          buttonType='outline'
          classes={{
            button: styles.yearFilterButton,
          }}
          buttonContent={yearFilterButtonView}
          list={{
            items: yearFilterItems,
            renderItem: yearFilterItemRenderer,
          }} />
      </div>

      {/* NavBar */}
      <div className={styles.navContent}>
        <NavBar />
      </div>
    </div>
  );

  return (
    <div className = {cx(styles.Header, className)}>
      <div className={styles.logoProfileContent}>

        {/* LOGO */}
        <div className={styles.Logo} style={{ backgroundImage: `url(${LunumLogoImage})` }}></div>

        {/* Print Report Button and Account Menu Dropdown */}
        {printReportAndAccountMenuButtonView}

      </div>

      {filterAndNavView}

    </div>
  );

};
 
export default Header;