import React from 'react';
import cx from 'classnames';
import Text from '../../atoms/Text';

import styles from './Toggle.module.scss';
import { ToggleCombinedProps } from './Toggle.types';

const Toggle: React.FC<ToggleCombinedProps> = (props) => {
  const { printMode } = props;
  const {
    className,
    toggleItems,
  } = props.usePresenter?.(props) || props;
  return (
    <div className={cx(styles.toggle, className)}>
      {toggleItems.map((item, index) => (
        <button
          key={index}
          disabled={item.disabled}
          type={item.buttonType}
          form={item.buttonForm}
          onClick={() => item.onClick(item.value)}
          className={cx(styles.toggleItems, item.isSelected && styles.toggleItemSelected)}
        >
          <Text
            color={item.isSelected ? 'grey-8' : 'royal-blue'}
            size='sm'
            type='p'
            weight='semi-bold'
            printMode={printMode}
          > {item.value} </Text>
        </button>
      ))}
    </div>
  );
};

export default Toggle;
