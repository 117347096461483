import { ETHNICITY_NAME_MAPPING } from '../../shared_components/organisms/GenderTable/GenderTable.constants';
import { MAX_BAR_WIDTH_IN_PIXELS } from '../Chart/Chart.constants';
import { extractDataset } from '../Chart/Chart.utils';

type SortDirection = 'asc' | 'desc';

// TODO: Make this function more generic
const sortDataTable = <T>(dataTable: T[], labelKey?: string, sortDirection: SortDirection = 'asc'): T[] => {
  if (labelKey) {
    return dataTable.sort((a, b) => {
      const valueA = a[labelKey];
      const valueB = b[labelKey];

      if (typeof valueA === 'string' || typeof valueB === 'string') {
        // If values are strings, perform string comparison
        return sortDirection === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      } else if (typeof valueA === 'number' && typeof valueB === 'number') {
        // If values are numbers, perform numeric comparison
        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      } else {
        // Unsupported type, no sorting applied
        return 0;
      }
    });
  }
  return dataTable;
};

export type DatasetKey<T> = {
  datasetLabel: string;
  datasetProperty: keyof T;
  color: string;
  transformer?: (record: T, property: keyof T) => number;
  hidden?: boolean;
  avg?: boolean;
};

export type DatasetConfig<T> = {
  labelKeys: (keyof T)[];
  datasetKeys: DatasetKey<T>[];
};

export const buildDataset = <T>(dataTable: T[], config: DatasetConfig<T>, type?: string) => {
  const {
    labelKeys,
    datasetKeys,
  } = config;
  const sortedDataTable = sortDataTable(dataTable, String(labelKeys[0]));

  const labels = sortedDataTable.map(record => {
    const label = labelKeys.map((labelKey) => {
      return record[labelKey] || undefined;
    }).join(' | ');
    return ETHNICITY_NAME_MAPPING[label] || label;
  });

  const datasets: any[] = [];

  datasetKeys?.forEach((datasetKey) => {
    const {
      datasetProperty,
      transformer,
      color,
      datasetLabel,
      hidden,
    } = datasetKey;

    const intensity = extractDataset(sortedDataTable, datasetProperty, transformer);

    if (type && type === 'line') {
      datasets.push({
        label: datasetLabel,
        data: intensity,
        line: 1,
        borderColor: color,
        backgroundColor: color,
        hidden,
      });
    } else {
      datasets.push({
        label: datasetLabel,
        data: intensity,
        barPercentage: 0.97,
        categoryPercentage: 1,
        maxBarThickness: MAX_BAR_WIDTH_IN_PIXELS,
        // stack:'Group',
        backgroundColor: color,
        hoverBackgroundColor: color,
        hidden,
      });
    }

  });

  return {
    labels,
    datasets,
  };

};
