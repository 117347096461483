import React from 'react';
import cx from 'classnames';

import styles from './CheckboxItemList.module.scss';
import Text from '../../atoms/Text';
import useCheckboxItemListPresenter from './CheckboxItem.presenter';
import Icon from '../../atoms/Icon';
import Button from '../../atoms/Button';
import List from '../List';
import { formatCheckboxDisplayString } from '../../organisms/FilterModal/FilterModal.utils';
import { CheckboxItemProps, ChildCheckboxItemsProps, ChildItem } from './CheckboxItem.types';


const ChildCheckboxItems: React.FC<ChildCheckboxItemsProps> = (props) => {
  const { item, parentItem, selectedItems, setSelectedItems, handleChildCheckboxToggle } = props;
  const currentItem = selectedItems.find(selectedParentItem => selectedParentItem.name === parentItem.name);
  const isSelected = currentItem?.childItems?.includes(item.value);

  return (
    <div className={cx(styles.checkboxItem)}>
      <Button className={cx(styles.checkboxItemButton)} onClick={() => handleChildCheckboxToggle && handleChildCheckboxToggle(item.value, parentItem, selectedItems, setSelectedItems)}>
        <div className={cx(styles.checkbox)}>
          <Icon className={styles.checkboxIcon} asset={isSelected ? 'CheckboxFilled' : 'CheckboxEmpty'} colour={isSelected ? 'Primary' : 'NeutralDisabled'} state={'Selected'} />
        </div>
        <Text className={cx(styles.checkboxText)} type="p" size="sm" weight="regular" color="grey-2">{item.name}</Text>
      </Button>
    </div>
  );
};

const CheckboxItem: React.FC<CheckboxItemProps> = (props) => {
  const {
    item: checkboxItem,
    type,
    handleChildCheckboxToggle,
    handleCheckboxToggle,
    selectedItems,
    setSelectedItems,
    isSelectAll = false,
  } = props;

  const {
    isOpen,
    handleToggle,
  } = useCheckboxItemListPresenter();

  const isSelected = (isSelectAll || selectedItems.some(selectedItem => selectedItem.name === checkboxItem.name));
  return (
    <div className={cx(styles.checkboxItemExpandable)} >
      <div className={styles.checkboxItemContainer}>
        <div className={styles.checkboxItem}>
          <Button className={cx(styles.checkboxItemButton)} onClick={() => handleCheckboxToggle(checkboxItem, isSelected, selectedItems, setSelectedItems)}>
            <div
              className={cx(styles.checkbox)}>
              <Icon className={styles.checkboxIcon} asset={isSelected ? 'CheckboxFilled' : 'CheckboxEmpty'} colour={isSelected ? 'Primary' : 'NeutralDisabled'} state={'Selected'} />
            </div>
            <Text
              className={cx(styles.checkboxText)}
              type="p"
              size='sm'
              weight='regular'
              color="grey-2">{formatCheckboxDisplayString(checkboxItem)}</Text>
          </Button>
          {type === 'Expandable' && <Button
            className={cx(styles.checkboxIcon)}
            onClick={handleToggle}>
            <Icon asset={isOpen ? 'ChevronUp' : 'ChevronDown'} colour={'NeutralDefault'} state={'Default'} />
          </Button>}
        </div>
        {type === 'Expandable' && <div className={cx(styles.content, styles[`${isOpen ? 'expanded' : ''}`])}>
          <div className={cx(styles.contentWrapper)}>
            {isOpen && checkboxItem.childItems ? <List className={cx(styles.list)} items={checkboxItem.childItems} renderItem={
              (childItem: ChildItem) =>
                <div className={cx(styles.subCheckboxLits)}>
                  <div className={cx(styles.verticalDivider)} ></div>
                  <ChildCheckboxItems
                    item={childItem}
                    parentItem={checkboxItem}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    handleChildCheckboxToggle={handleChildCheckboxToggle}
                  />
                </div>
            } /> : null}
          </div>
        </div>}
      </div>

    </div>
  );
};

export default CheckboxItem;