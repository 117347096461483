import React from 'react';
import cx from 'classnames';

import styles from './TotalEICard.module.scss';
import Text from '../../atoms/Text';
import useTotalEICardPresenter from './TotalEICard.presenter';
import SkeletonRectangle from '../../atoms/SkeletonRectangle';
import TooltipIcon from '../TooltipIcon';
import { TooltipDefs } from '../../organisms/Tooltip/Tooltip.constant';
import { TotalEICardProps } from './TotalEICard.types';


const TotalEICard: React.FC<TotalEICardProps> = (props) => {
  const { state = 'Default', className } = props;
  const {
    title = '',
    value,
    printMode,
  } = useTotalEICardPresenter(props);

  const TooltipButton = !printMode ? ( 
    <TooltipIcon
    style={{ marginLeft: '6px' }}
    title={title}
    desc={TooltipDefs[title]} />
  ) : null;

  const valueContent = state === 'Loading' ? (
    <SkeletonRectangle />
  ) : (
    <Text
      type='h2'
      size={'xl'}
      weight={'semi-bold'}
      color={'grey-2'}
      printMode={printMode}
      className={!printMode ? styles.gradientText : undefined}
    >{value}</Text>
  );

  return (
    <div className = {cx(
      styles.TotalEICard, 
      className, 
      (printMode ? styles.PrintTotalEICard : undefined),
    )}>
      <div className={styles.labelContent}>
        <Text
          type='p'
          size={'md'}
          weight={'regular'}
          color={'grey-4'}
        >{title}
        {TooltipButton}
        </Text>
        
      </div>
      {valueContent}
    </div>
  );

};
 
export default TotalEICard;