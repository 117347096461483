import React from 'react';
import cx from 'classnames';

import styles from './GlossaryHeader.module.scss';

import useGlossaryPresenter from './GlossaryHeader.presenter';

//Components
import Text from '../../atoms/Text';
import Card from '../../atoms/Card';


export type GlossaryHeaderProps = {
  page: string;
};

const GlossaryHeader: React.FC<GlossaryHeaderProps> = (props) => {

  const {
    definitions,
    pageView,
    title,
    subTitle,
    description,
    page,
  } = useGlossaryPresenter(props);

  let component;

  if (page === 'overview') {
    component = (
      <Card className={cx(styles.GlossaryHeader)}>
        <div className={cx(styles.OverviewPageHeader)} >
          <Text type="h1" size="xl" weight='bold' color='grey-2'>{title}</Text>
          <Text type="p" size="lg" weight='regular' color='grey-4'>{subTitle}</Text>
          {description && <Text type="p" size="lg" weight='regular' color='grey-4'>{description}</Text>}
        </div>
      </Card>
    );
  }

  if (page !== 'overview') {
    if (pageView === 'Region' || pageView === 'Company') {
      component = (
        <div className={cx(styles.PageHeaderContainer)} >
          <div className={cx(styles.PageHeaderRowContainer)} >
            <div className={cx(styles.PageHeaderLeftContainer)}>
              <Text type="h1" size="xl" weight='bold' color='grey-2'>{definitions.title}</Text>
              <Text type="p" size="lg" weight='regular' color='grey-4'>{definitions.desc}</Text>
            </div>
            <div className={cx(styles.PageHeaderRightContainer)}>
              <Text type="h1" size="xl" weight='bold' color='grey-2'>{title}</Text>
              <Text type="p" size="lg" weight='regular' color='grey-4'>{subTitle}</Text>
              {description && <Text type="p" size="lg" weight='regular' color='grey-4'>{description}</Text>}
            </div>
          </div>
          <div className={cx(styles.PageHeaderDivider)}></div>
        </div>
      );
    } else {
      component = (
        <div className={cx(styles.PageHeaderContainer)} >
          <div className={cx(styles.PageHeaderRowContainer)} >
            <div className={cx(styles.PageHeaderLeftContainer)}>
              <Text type="h1" size="xl" weight='bold' color='grey-2'>{definitions.title}</Text>
              <Text type="p" size="lg" weight='regular' color='grey-4'>{definitions.desc}</Text>
            </div>
            <div className={cx(styles.PageHeaderRightImageContainer)}>
              <img src={definitions.imageSrc} style={{ width: '380px', height: '190px' }} alt="" />
            </div>
          </div>
          <div className={cx(styles.PageHeaderDivider)}></div>
        </div>
      );
    }
  }

  return component;
};

export default GlossaryHeader;