import React from 'react';
import cx from 'classnames';

import styles from './List.module.scss';
import { ListProps } from './List.types';

const List: React.FC<ListProps<any>> = (props) => {
  const {
    className,
    items,
    renderItem,
  } = props;
  return (
    <div className = {cx(styles.List, className)}>
      {items?.map((item, index) => (
        // Each child in a list should have a unique "key" prop
        <React.Fragment key={index}>
          {renderItem(item)}
        </React.Fragment>
      ))}
    </div>
  );

};
 
export default List;