import React, { useContext } from 'react';
import cx from 'classnames';

import styles from './DashboardLayout.module.scss';
import { DashboardLayoutProps } from './DashboardLayout.types';
import Header from '../../organisms/Header';
import LoaderModal from '../../organisms/LoaderModal/LoaderModal';
import { PageContext } from '../../../modules/page/PageContext';
import { useFilterModal } from '../../organisms/FilterModal/FilterModal.context';
import FilterModal from '../../organisms/FilterModal';
import DashboardRouter from './DashboardLayout.router';

const DashboardLayout: React.FC<DashboardLayoutProps> = (props) => {
  const { className } = props;
  const { isLoading: isPageDataLoading } = useContext(PageContext);
  const { isFilterDataLoading } = useFilterModal();
  return (
    <>
      <div className = {cx(styles.DashboardLayout, className)}>
        <Header />
        <DashboardRouter />
      </div>
      <LoaderModal show={isPageDataLoading || isFilterDataLoading} />
      <FilterModal />
    </>
  );

};
 
export default DashboardLayout;