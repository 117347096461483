import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EITableDataRow, EITableProps } from './EITable.types';
import { buildHeaderCellPropsFromTable, buildTableRowPropsFromTable, generateColumnsDefinitions } from './EITable.utils';

const columnHelper = createColumnHelper<EITableDataRow>();

const useEITablePresenter = (props: EITableProps) => {
  const { t } = useTranslation();
  const { rows, thresholds } = props;
  const [sorting, setSorting] = useState<SortingState>([]);
  const columnsDefs = generateColumnsDefinitions(t, columnHelper);

  const table = useReactTable({
    data: rows,
    columns: columnsDefs,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true,
    state: {
      sorting,
    },
  });

  const tableHeaders = buildHeaderCellPropsFromTable(table);

  const tableContentRows = buildTableRowPropsFromTable(table, thresholds);

  return {
    rows: tableContentRows,
    headers: tableHeaders,
  };
};

export default useEITablePresenter;
