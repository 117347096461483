import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import IntensityChart from '../../../modules/Chart/IntensityChart';
import YoYChart from '../../../modules/Chart/YoYChart';
import Card from '../../atoms/Card';
import Page from '../../atoms/Page';
import ChartCard from '../../molecules/ChartCard';
import DimensionHeader from '../../molecules/DimensionHeader';
import JobMapGraphCard from '../../molecules/JobMapGraphCard';
import TotalAdjustmentCard from '../../molecules/TotalAdjustmentCard';
import KeyTakeawaysCard from '../../organisms/KeyTakeawaysCard';

import styles from './JobCreationPage.module.scss';
import useJobCreationPagePresenter from './JobCreationPage.presenter';
import { JobCreationPageProps } from './JobCreationPage.types';

const JobCreationPage: React.FC<JobCreationPageProps> = () => {
  const { t } = useTranslation();
  const {
    view,
    appliedFilters,
    totalJobCreationAdjustment,
    jobMapGraphCardData,
    keyTakeawaysCard,
    jobCreationIntensityChart,
    jobCreationYoYChart,
  } = useJobCreationPagePresenter();

  return (
    <Page className={cx(styles.WageQualityPage)} >
      <DimensionHeader page={'jobCreation'} />

      <div style={{
        display: 'flex', flexDirection: 'column', gap: '1.5rem', width: '312px', flexShrink: 0,
      }}>
        <Card>
          <TotalAdjustmentCard page='jobCreation' value={totalJobCreationAdjustment} />
        </Card>
        <KeyTakeawaysCard
          page='jobCreation'
          view={view}
          state={keyTakeawaysCard.state}
          keyTakeawaysValues={keyTakeawaysCard.values} />
      </div>

      <ChartCard
        width='offset'
      >
        <ChartCard.Header
          title={view === 'Region'
            ? t('jobCreationPage.jobCreationAdjustmentGraphTitle.intensity')
            : t('jobCreationPage.jobCreationAdjustmentGraphTitle.forEachSupplier')}
          subtitle={t('filtersApplied', { filters: appliedFilters })}
          isExpandable={view !== 'Region' ? true : false}
        />
        {view === 'Region' ? (
          <YoYChart
            height={545}
            stacked={true}
            datasetConfig={jobCreationYoYChart.datasetConfig}
            data={jobCreationYoYChart.data}
            isIntensity
          />
        ) : (
          <IntensityChart
            datasetConfig={jobCreationIntensityChart.datasetConfig}
            alignment='left'
            data={jobCreationIntensityChart.data}
            height={545}
          />
        )}
        <ChartCard.Legend
          datasets={view === 'Region'
            ? jobCreationYoYChart.datasetConfig
            : jobCreationIntensityChart.datasetConfig}
        />
      </ChartCard>

      <ChartCard>
        <ChartCard.Header
          title={t('jobCreationPage.stateGraph.title')}
          subtitle={t('filtersApplied', { filters: appliedFilters })}
          isExpandable={false}
        />
        <JobMapGraphCard data={jobMapGraphCardData} />
      </ChartCard>
    </Page>
  );
};

export default JobCreationPage;

