import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import { updatePassword } from '../../../lib/api';
import { AUTH_ROUTES, MIN_PASSWORD_LENGTH } from '../../../lib/constants';
import { extractJwtPayload } from '../../../lib/token';
import { isEmptyString } from '../../../lib/utils';
import { TextFieldProps } from '../../molecules/TextField/TextField.types';

import { isExpiryDatePassed } from '../CreateAccountPage/CreateAccountPage.utils';
import { CreteNewPasswordState } from './CreatePasswordPage.types';

const formInitialState: CreteNewPasswordState = {
  password: '',
  confirmPassword: '',
};

const useCreatePasswordPagePresenter = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token');

  let accountId;
  if (token) {
    const { id, exp } = extractJwtPayload(token);
    if (isExpiryDatePassed(exp)) {
      history.replace(AUTH_ROUTES.resetPasswordExpired);
    }
    accountId = id;
  }

  const [formState, setFormState] = useState<CreteNewPasswordState>(formInitialState);
  const [formErrorState, setFormErrorState] = useState<CreteNewPasswordState>(formInitialState);
  const [isError, setIsError] = useState(false);

  const isFormValid = useMemo(() => {
    const invalidFormErrors = {
      password: '',
      confirmPassword: '',
    };

    const isPasswordEmpty = isEmptyString(formState.password);
    const isConfirmPasswordEmpty = isEmptyString(formState.confirmPassword);

    if (isPasswordEmpty || isConfirmPasswordEmpty) {
      return false;
    }

    if (formState.password.length < MIN_PASSWORD_LENGTH) {
      invalidFormErrors.password = t('CreatePassword.errors.passwordShort');
      setFormErrorState(invalidFormErrors);
      return false;
    }

    if (formState.password !== formState.confirmPassword) {
      invalidFormErrors.confirmPassword = t('CreatePassword.errors.passwordMismatch');
      setFormErrorState(invalidFormErrors);
      return false;
    }

    setFormErrorState(formInitialState);
    return true;
  }, [formState]);

  const handleTextChange = (field: keyof CreteNewPasswordState) => {
    return (value: string) => {
      setFormState({
        ...formState,
        [field]: value,
      });

      setFormErrorState({
        ...formErrorState,
        [field]: '',
      });
    };
  };

  const handleCreatePasswordButtonClick = async () => {
    if (isFormValid && accountId && token) {
      setIsError(false);
      try {
        await updatePassword({
          id: accountId,
          password: formState.password,
          token,
        });
        history.replace(AUTH_ROUTES.updatedPasswordConfirmed);
      } catch (error) {
        setIsError(true);
      }
    }
  };

  const passwordTextField: TextFieldProps = {
    state: formErrorState.password ? 'Error' : 'Caption',
    text: formErrorState.password || t('CreatePassword.fieldDescriptions.password'),
    inputField: {
      type: 'password',
      state: formErrorState.password ? 'error' : 'none',
      textValue: formState.password,
      onTextChanged: handleTextChange('password'),
    },
  };

  const confirmPasswordTextField: TextFieldProps = {
    state: formErrorState.confirmPassword ? 'Error' : 'Default',
    text: formErrorState.confirmPassword,
    inputField: {
      type: 'password',
      state: formErrorState.confirmPassword ? 'error' : 'none',
      textValue: formState.confirmPassword,
      onTextChanged: handleTextChange('confirmPassword'),
    },
  };

  return {
    isError,
    passwordTextField,
    confirmPasswordTextField,
    createPasswordButton: {
      disabled: !isFormValid,
      onClick: handleCreatePasswordButtonClick,
    },
  };
};

export default useCreatePasswordPagePresenter;