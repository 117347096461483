import { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { signIn } from '../../../lib/api';
import { DASHBOARD_ROUTES, REGEX_EMAIL } from '../../../lib/constants';
import { isEmptyString } from '../../../lib/utils';
import { AuthContext } from '../../../modules/auth';
import { InputFieldProps } from '../../atoms/InputField/InputField.types';

import { SignInState } from './SignInPage.types';

const formInitialState: SignInState = {
  email: '',
  password: '',
};

const useSignInPagePresenter = () => {
  const history = useHistory();
  const { refetchAccount } = useContext(AuthContext);

  const [formState, setFormState] = useState<SignInState>(formInitialState);
  const [isError, setIsError] = useState(false);

  const handleTextChange = (field: keyof SignInState) => {
    return (value: string) => {
      setFormState({
        ...formState,
        [field]: value,
      });
    };
  };

  const signInButtonDisabled = useMemo(() => {
    const isEmailEmpty = isEmptyString(formState.email);
    const isPasswordEmpty = isEmptyString(formState.password);

    if (isEmailEmpty || isPasswordEmpty) {
      return true;
    }

    const isValidEmail = REGEX_EMAIL.test(formState.email.trim());
    if (isValidEmail) {
      return false;
    }

    return true;
  }, [formState]);

  const handleSignIn = async () => {
    if (!signInButtonDisabled) {
      try {
        const accountResponse = await signIn({
          email: formState.email,
          password: formState.password,
        });
        if (accountResponse) {
          refetchAccount();
          history.replace(DASHBOARD_ROUTES.overview);
        }
      } catch {
        setIsError(true);
      }
    }
  };

  return {
    isError,
    emailInputField: {
      textValue: formState.email,
      onTextChanged: handleTextChange('email'),
    } as InputFieldProps,
    passwordInputField: {
      type: 'password',
      textValue: formState.password,
      onTextChanged: handleTextChange('password'),
    } as InputFieldProps,
    signInButton: {
      disabled: signInButtonDisabled,
      onClick: handleSignIn,
    },
  };
};

export default useSignInPagePresenter;