import { DataSetRecord } from '../../data_frame/DataFrame';
import percentageFormatter from '../AnnotationFormatter/precentageFormatter';
import { ProportionalChartProps } from './ProportionalChart.types';
import { buildProportionalChartDatasets, buildProportionalChartLabels } from './ProportionalChart.utils';

const useProportionalChartPresenter = <T extends DataSetRecord>( props: ProportionalChartProps<T> ) => {
  const {
    data,
    yLabel,
    datasetConfig,
  } = props;
 
  const labels = buildProportionalChartLabels(data, yLabel);
  const datasets = buildProportionalChartDatasets(data, yLabel, datasetConfig);
  const annotationFormatter = percentageFormatter(1);

  return {
    labels,
    datasets,
    annotationFormatter,
  };
};

export default useProportionalChartPresenter;