import React from 'react';

import { GenderEthnicityChartProps } from './GenderEthnicityChart.types';
import useGenderEthnicityChartPresenter from './GenderEthnicityChart.presenter';
import Chart from '../Chart';

const GenderEthnicityChart: React.FC<GenderEthnicityChartProps> = (props) => {
  const {
    className,
    height,
    width,
    printMode,
  } = props;

  const {
    labels,
    datasets,
  } = useGenderEthnicityChartPresenter(props);

  return (
    <Chart
      className={className}
      height={height}
      width={width}
      labels={labels}
      datasets={datasets}
      alignment={'right'}
      orientation={'horizontal'}
      stacked={false}
      type={'bar'}
      tooltipLayout={'groupedBar'}
      showAverageLine
      printMode={printMode}
    />

  );

};

export default GenderEthnicityChart;
