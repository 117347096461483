import React from 'react';

import Chart from '../Chart';
import { IntensityChartProps } from './IntensityChart.types';
import useIntensityChartPresenter from './IntensityChart.presenter';

const IntensityChart: React.FC<IntensityChartProps> = (props) => {
  const {
    className,
    alignment,
    height,
    width,
    printMode,
  } = props;

  const {
    labels,
    datasets,
    tooltipLayout,
    showAverageLine,
  } = useIntensityChartPresenter(props);

  return (
    <Chart
      className={className}
      height={height}
      width={width}
      labels={labels}
      datasets={datasets}
      alignment={alignment}
      orientation={'horizontal'}
      type={'bar'}
      printMode={printMode}
      tooltipLayout={tooltipLayout}
      showAverageLine={showAverageLine}
    />
  );

};

export default IntensityChart;
