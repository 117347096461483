import { View } from '../shared_components/organisms/FilterModal/FilterModal.types';
import { SummaryKeys } from './api/resources/reports';

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 128;
export const MAX_PHONE_NUMBER_LENGTH = 40;
export const MIN_SUPPORTED_BREAKPOINT = 1024;
export const TERMS_OF_USE_LINK = 'https://lunum.io/saas-terms-of-service';
export const KNOWLEDGE_BASE_LINK = 'https://lunum.io/m/login?r=%2Fknowledge-base';
export const PRIVACY_POLICY_LINK = 'https://lunum.io/privacy-policy';
export const ABOUT_LUNUM_LINK = 'https://lunum.io/';

export const TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  hour12: true,
  timeStyle: 'short',
};

export const COURSE_DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  month: 'short',
  day: '2-digit',
  year: 'numeric',
};

export const COURSE_TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  hour12: true,
  minute: '2-digit',
  hour: 'numeric',
};

export const US_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const CANADA_PROVINCES = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon',
];

export const REGEX_FIRST_AND_LAST_NAME = /[^a-zA-Z -]/gi;
export const REGEX_ACCEPT_PHONE_NUMBER = /[^+0-9]/gi;
export const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const DATA_COLORS = {
  BLUE: '#148BCC',
  GREEN: '#14CCA3',
  PURPLE: '#7D5CCC',
  TEAL: '#14C1CC',
  RED: '#5C7BCC',
  GREY: '#C8CACE',
};

export const DATA_COLOR_CODES = Object.values(DATA_COLORS);

export const YEAR_FILTER_ITEMS = [2023, 2022, 2021, 2020, 2019, 2018];

export const INTENSITY_GRAPH_LABEL_KEYS_VIEW_MAP: Record<View, SummaryKeys[]> = {
  'Portfolio': ['supplier'],
  'Company': ['location', 'supplier'],
  'Region': ['year'],
  'Empty': [],
};

export const STATE_CODES: Record<string, string> = {
  'alabama': 'AL',
  'alaska': 'AK',
  'arizona': 'AZ',
  'arkansas': 'AR',
  'california': 'CA',
  'colorado': 'CO',
  'connecticut': 'CT',
  'delaware': 'DE',
  'florida': 'FL',
  'georgia': 'GA',
  'hawaii': 'HI',
  'idaho': 'ID',
  'illinois': 'IL',
  'indiana': 'IN',
  'iowa': 'IA',
  'kansas': 'KS',
  'kentucky': 'KY',
  'louisiana': 'LA',
  'maine': 'ME',
  'maryland': 'MD',
  'massachusetts': 'MA',
  'michigan': 'MI',
  'minnesota': 'MN',
  'mississippi': 'MS',
  'missouri': 'MO',
  'montana': 'MT',
  'nebraska': 'NE',
  'nevada': 'NV',
  'new hampshire': 'NH',
  'new jersey': 'NJ',
  'new mexico': 'NM',
  'new york': 'NY',
  'north carolina': 'NC',
  'north dakota': 'ND',
  'ohio': 'OH',
  'oklahoma': 'OK',
  'oregon': 'OR',
  'pennsylvania': 'PA',
  'puerto rico': 'PR',
  'rhode island': 'RI',
  'south carolina': 'SC',
  'south dakota': 'SD',
  'tennessee': 'TN',
  'texas': 'TX',
  'utah': 'UT',
  'vermont': 'VT',
  'virginia': 'VA',
  'washington': 'WA',
  'west virginia': 'WV',
  'wisconsin': 'WI',
  'wyoming': 'WY',
};

export const STATE_NAMES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const AUTH_ROUTES = {
  signUp: '/auth/signUp',
  signIn: '/auth/signIn',
  expiredInvitation: '/auth/expiredInvitation',
  forgotPassword: '/auth/forgotPassword',
  forgotPasswordConfirmed: '/auth/forgotPassword/confirmed',
  resetPassword: '/auth/resetPassword',
  resetPasswordExpired: '/auth/resetPasswordExpired',
  invalidInvite: '/auth/invalidInvite',
  completeProfile: '/auth/completeProfile',
  updatedPasswordConfirmed: '/auth/updatePassword/confirmed',
} as const;

export const DASHBOARD_ROUTES = {
  overview: '/dashboard/overview',
  wageQuality: '/dashboard/wageQuality',
  diversity: '/dashboard/diversity',
  opportunity: '/dashboard/opportunity',
  jobCreation: '/dashboard/jobCreation',
} as const;

export type DashboardRoutes = keyof typeof DASHBOARD_ROUTES;

export const PUBLIC_ROUTES: string[] = [
  AUTH_ROUTES.signIn,
];
