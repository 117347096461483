import React, { useMemo } from 'react';

import * as echarts from 'echarts/core';
import {
  GeoComponent,
} from 'echarts/components';
import ReactECharts from 'echarts-for-react';
import { USA_JSON } from './MapChart.constant';

import useMapChartPresenter from './MapChart.presenter';
import { MapChartProps } from './MapChart.types';

echarts.use([GeoComponent]);

echarts.registerMap('USA', USA_JSON as any, {
  Alaska: {
    left: -131,
    top: 25,
    width: 15,
  },
  Hawaii: {
    left: -110,
    top: 28,
    width: 5,
  },
  'Puerto Rico': {
    left: -76,
    top: 26,
    width: 2,
  },
});

const MapChart: React.FC<MapChartProps> = (props) => {
  const { printMode } = props;
  const { options, handleRegionClick } = useMapChartPresenter(props);
  const onEvents = useMemo(() => ({
    click: handleRegionClick,
  }), [handleRegionClick]);
  return (
    <ReactECharts
      option={options}
      onEvents={onEvents}
      style={{
        height: '100%',
        width: printMode ? '400px' : '100%',
        borderTopRightRadius: printMode ? '6.74px' : '1rem',
        borderBottomRightRadius: printMode ? '6.74px' : '1rem',
      }} />
  );
};

export default MapChart;