import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AUTH_ROUTES } from '../../lib/constants';
import { UserContext } from '../user/UserContext';

import { AuthContext } from './AuthContext';

const CheckUserAccess: React.FC<RouteProps> = (props) => {
  const { account } = useContext(AuthContext);
  const { user, error } = useContext(UserContext);

  if (!account) {
    return <Redirect to={{
      pathname: AUTH_ROUTES.signIn,
      state: {
        sessionEnd: true,
      },
    }}/>;
  }

  // if there is an account created and there is no user and an error
  // user needs to complete the profile first
  // if (account && error && !user && props.path !== AUTH_ROUTES.completeProfile) {
  //   return <Redirect to={{
  //     pathname: AUTH_ROUTES.completeProfile,
  //     state: {
  //       sessionEnd: true,
  //     },
  //   }}/>;
  // }
  
  // if no errors then proceed to the route
  return <Route {...props} />;
};

const ProtectedRoute: React.FC<RouteProps> = (props) => {
  return <CheckUserAccess {...props} />;
};

export default ProtectedRoute;
