import React from 'react';

import 'chartjs-plugin-annotation';

import AppRouter from './App.router';

const App: React.FC<{}> = () => {
  return (
    <>
      <AppRouter />
    </>
  );
};

export default App;
