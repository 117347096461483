import { useEffect, useRef, useState } from 'react';

export const useTooltip = (
  xOffset: number,
  yOffset: number,
  anchorPosition: 'top' | 'bottom' = 'bottom',
  boundingElement?: React.RefObject<HTMLDivElement>, //Option element that contains tooltip
) => {
  const [isVisible, setVisibility] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);

  const [coordinates, setCoordinates] = useState<{ x: number, y: number }>({ x: 0, y: 0 });

  useEffect(() => {
    if (anchorRef.current && tooltipRef.current) {
      const {
        height: anchorHeight,
        width: anchorWidth,
        right: anchorRight,
      } = anchorRef.current.getBoundingClientRect();
      const xPos = (anchorWidth / 2);
      let yPos = (anchorHeight);

      if (anchorPosition === 'top') {
        yPos = -tooltipRef.current.clientHeight - yOffset;
      }

      const newCoordinates = {
        //place left edge of tooltip relative to center of anchor + specified offset
        x: xPos + xOffset,
        //place top of top tip and the bottom of the anchor + specifed offset
        y: yPos + yOffset,
      };

      let boundingRect = {
        top: 0,
        left: 0,
        right: window.innerWidth,
        bottom: window.innerHeight,
        height: window.innerHeight,
        width: window.innerWidth,
      };

      //Set bounding box to match a bounding elment if supplied
      if (boundingElement && boundingElement.current) {
        boundingRect = boundingElement.current.getBoundingClientRect();
      }

      const animation = tooltipRef.current.getAttribute('data-animation');

      //Inverse/flip x and y positioning If outside of the boudning area
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      if (anchorRight + xPos + tooltipRect.width > boundingRect.right) {

        //calculate current scale based on animation. The height and width of
        //an element changes as the animation progresses. Applying 0.8 gives the
        //final value of an element after the animation completes
        const scale = (animation === 'pop') ? 0.8 : 1;
        newCoordinates.x = xPos - tooltipRect.width / scale - xOffset;
      }

      if (tooltipRect.bottom > boundingRect.bottom) {
        coordinates.y = yPos - tooltipRect.height - yOffset - boundingRect.height;
      }
      setCoordinates(newCoordinates);
    }
  }, [tooltipRef, anchorRef, isVisible]);

  const handleMouseEnter = () => {
    if (anchorRef.current && !isVisible) {
      setVisibility(true);
    }
  };
  const handleMouseExit = () => {
    if (anchorRef.current && isVisible) {
      setVisibility(false);
    }
  };

  return {
    anchorRef,
    tooltipRef,
    isVisible,
    handleMouseEnter,
    handleMouseExit,
    coordinates,
  };

};