import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import MapChart from '../../organisms/MapChart/MapChart';
import EmptyStateContent from '../EmptyStateContent';

import styles from './JobMapGraphCard.module.scss';
import JobMapGraphCardHeader from './JobMapGraphCardHeader';
import JobMapGraphCardLegends from './JobMapGraphCardLegends';
import JobMapGraphCardRateContent from './JobMapGraphCardRateContent';
import JobMapGraphCardSupplierList from './JobMapGraphCardSupplierList';
import { JobMapGraphCardProps } from './JobMapGraphCard.types';
import useJobMapGraphCardPresenter from './JobMapGraphCard.presenter';

const JobMapGraphCard: React.FC<JobMapGraphCardProps> = (props) => {
  const { t } = useTranslation();

  const { printMode } = props;
  const {
    supplierList,
    mapChart,
    cardHeader,
    rateContent,
    pageView,
  } = useJobMapGraphCardPresenter(props);

  if (pageView === 'Empty') {
    return <EmptyStateContent />;
  }

  return (
    <div className = {cx(styles.JobMapGraphCard, (printMode ? styles.PrintJobMapGraphCard : undefined))}>

      <div className={styles.leftContent}>

        <JobMapGraphCardHeader
          intensity={cardHeader.intensity}
          selectedState={cardHeader.selectedState}
          totalSuppliers={cardHeader.totalSuppliers}
          printMode={printMode}
        />

        <div className={styles.bottomLeftContent}>

          <JobMapGraphCardRateContent
            selectedStateHypoUnemploymentRate={rateContent.selectedStateHypoUnemploymentRate}
            selectedStateUnemploymentRate={rateContent.selectedStateUnemploymentRate}
            showEmptyState={rateContent.showEmptyState}
            printMode={printMode}
          />

          <JobMapGraphCardSupplierList
            supplierInfoItems={supplierList.supplierInfoItems}
            showEmptyState={supplierList.showEmptyState}
            printMode={printMode}/>
        </div>
      </div>

      <div className={styles.rightContent}>
        <MapChart
          data={mapChart.data}
          selectedState={mapChart.selectedState}
          updateState={mapChart.updateState}
          printMode={printMode}
        />

        <JobMapGraphCardLegends
          lowestImpact={t('jobCreationPage.jobMapGraphCard.legends.lowestImpact')}
          highestImpact={t('jobCreationPage.jobMapGraphCard.legends.highestImpact')}
          printMode={printMode}
        />
      </div>
    </div>
  );

};
 
export default JobMapGraphCard;