import React from 'react';
import cx from 'classnames';

import styles from './TextInput.module.scss';
import { TextInputProps } from './TextInput.types';

const TextInput: React.FC<TextInputProps> = (props) => {
  const { beforeComponent, onChange, className, textPlaceholder, inputValue, maxLength, afterComponent, inputType, inputStyle } = props;

  const handleInputChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={cx(styles.textInput, className)}>
      {beforeComponent}
      <input
        className={cx(styles.input, inputStyle)}
        placeholder={textPlaceholder}
        value={inputValue}        
        onChange={(e)=>handleInputChange(e)}
        type={inputType}
        maxLength={maxLength} />
      {afterComponent}
    </div>
  );
};

export default TextInput;
