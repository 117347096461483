import React from 'react';
import cx from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { AUTH_ROUTES } from '../../../lib/constants';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './ResendPasswordLinkPage.module.scss';
import useResendPasswordLinkPagePresenter from './ResendPasswordLinkPage.presenter';
import { ResendPasswordLinkPageProps } from './ResendPasswordLinkPage.types';

const ResendPasswordLinkPage: React.FC<ResendPasswordLinkPageProps> = (props) => {
  const { t } = useTranslation();
  const { className } = props;
  const {
    handleSendResetEmailClick,
  } = useResendPasswordLinkPagePresenter();
  return (
    <div className = {cx(styles.ResendPasswordLinkPage, className)}>
      <Button
        style='subdued'
        href={AUTH_ROUTES.signIn}
      >
        <Icon asset='ArrowLeft' colour='NeutralDefault' state='Default' />
        <Text
          size='sm'
          weight='regular'
          type='p'
          color='grey-2'
        >
          {t('ResetLinkSent.buttons.back')}
        </Text>
      </Button>
      <div className={styles.instructionContent}>
        <Text
          size='xl'
          type='p'
          color='grey-2'
          weight='bold'
        >
          {t('ResetLinkSent.title')}
        </Text>
        <Text
          size='sm'
          type='p'
          color='grey-4'
          weight='regular'
        >
          {t('ResetLinkSent.description')}
        </Text>
      </div>
      <div className={styles.linkContent}>
        <Text
          size='lg'
          type='p'
          color='grey-2'
          weight='bold'
        >
          {t('ResetLinkSent.question')}
        </Text>
        <Text
          size='sm'
          type='p'
          color='grey-4'
          weight='regular'
        >
          {/* using Trans to include button directly inside text */}
          <Trans
            i18nKey="ResetLinkSent.resendLinkText"
            components={{
              resendPasswordButton: (
                <button
                  onClick={handleSendResetEmailClick}
                  className={styles.resendPasswordButton}
                />
              ),
            }}
          />
        </Text>
      </div>
    </div>
  );

};
 
export default ResendPasswordLinkPage;