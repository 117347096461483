import React from 'react';
import cx from 'classnames';

import styles from './TooltipIcon.module.scss';
import { TooltipIconProps } from './TooltipIcon.types';
import Icon from '../../atoms/Icon';
import Tooltip from '../../organisms/Tooltip';
import Text from '../../atoms/Text';
import { useTooltip } from '../../organisms/Tooltip/useTooltip';
import { TooltipDefs } from '../../organisms/Tooltip/Tooltip.constant';

const TooltipIcon: React.FC<TooltipIconProps> = (props) => {
  const {
    children,
    title,
    desc,
    ...rest
  } = props;

  const { 
    anchorRef, 
    tooltipRef, 
    isVisible,
    handleMouseEnter,
    handleMouseExit,
    coordinates,
  } = useTooltip(
    5,
    10,
  ); 

  return (
    <div {...rest} className = {cx(styles.TooltipIcon)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseExit} ref={anchorRef}>
      <Icon
        asset='InfoTooltip'
        colour='Secondary'
        state='Default'
      />
      {  <Tooltip animation='pop' ref={tooltipRef} className={styles.Tooltip} visible={isVisible} coordinates={coordinates}>
        <div  className = {cx(styles.HeaderTooltip)}>

          <Text size='sm' color='grey-2' weight='semi-bold' >{title}</Text> {/*title*/}
          <Text size='sm' type ='p' color='grey-4'>{TooltipDefs[title]}</Text> {/*desc*/}
          {/* <a href={label}>{label}</a> link */}
        </div>
      </Tooltip> }
    </div>
  );

};
 
export default TooltipIcon;