import React, { Dispatch, SetStateAction } from 'react';
import cx from 'classnames';
import styles from './FilterModal.module.scss';
import { CheckboxDropdownProps, Chip, FilterItem, FilterModalProps, HorizontalChipListProps, SearchInputProps } from './FilterModal.types';
import { Modal } from 'react-overlays';
import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import ExpandableComponent from '../../molecules/ExpandableComponent';
import List from '../../molecules/List';
import TextInput from '../../atoms/TextInput';
import CheckboxItem from '../../molecules/CheckboxItem';
import { CheckboxItemType } from '../../molecules/CheckboxItem/CheckboxItem.types';
import useFilterModalPresenter from './FilterModal.presenter';

const backdrop = (props) => <div {...props} className={styles.FilterModalBackdrop}></div>;

const ExpandableHeaderComponent = (title: string) => {
  return (isOpen: boolean) => (
    <div className={cx(styles.header)}>
      <Text
        size='sm'
        weight='semi-bold'
        color="grey-2">{title}</Text>
      <Icon className={cx(styles.icon)} asset={isOpen ? 'ChevronUp' : 'ChevronDown'} colour={'NeutralDefault'} state={'Default'} />
    </div>
  );
};

const ExpandableListComponent = (
  checkboxItemList: CheckboxDropdownProps,
  handleCheckboxToggle: (
    checkboxItem: CheckboxItemType,
    isSelected: boolean,
    selectedItems: FilterItem[],
    setSelectedItems: Dispatch<SetStateAction<FilterItem[]>>) => void,
  handleChildCheckboxToggle: (
    value: string,
    parentItem: CheckboxItemType,
    selectedItems: FilterItem[],
    setSelectedItems: Dispatch<SetStateAction<FilterItem[]>>) => void,
) => {
  return (isOpen: boolean) => (
    isOpen && <List
      className={styles.list}
      items={checkboxItemList?.items}
      renderItem={(item) =>
        <CheckboxItem
          item={item}
          selectedItems={checkboxItemList.selectedItems}
          setSelectedItems={checkboxItemList.setSelectedItems}
          type={checkboxItemList.type}
          handleCheckboxToggle={handleCheckboxToggle}
          handleChildCheckboxToggle={handleChildCheckboxToggle} />
      } />
  );
};

const SearchInput: React.FC<SearchInputProps> = ({ onChange, value }) => {
  return (
    <TextInput
      textPlaceholder='Search by name, region, or industry'
      className={styles.searchBar}
      inputStyle={styles.input}
      inputValue={value}
      beforeComponent={<Icon className={styles.searchIcon} asset={'Search'} colour={'NeutralDefault'} state={'Default'} />}
      onChange={onChange}
    />
  );
};

const HorizontalChipList: React.FC<HorizontalChipListProps> = ({ filteredChipList }) => {
  return (
    <List
      className={styles.horizontalChipList}
      items={filteredChipList}
      renderItem={(item: Chip) => (
        <Button className={styles.chipButton} onClick={item.onClick}>
          <Text type='p' size='xs' weight='regular' color="grey-2">{item.name}</Text>
          <Icon className={styles.chipIcon} asset={'Close'} colour={'NeutralDefault'} state={'Default'} />
        </Button>
      )}
    />
  );
};

const EmptyStateComponent: React.FC<{}> = () => {
  return (
    <div className={styles.emptyStateContainer}>
      <Icon className={styles.emptySearchIcon} asset={'Search'} colour={'NeutralDefault'} state={'Default'} />
      <Text
        type='p'
        size='lg'
        weight='bold'
        color="grey-2">No Results Found.
      </Text>
      <Text
        type='p'
        size='xs'
        weight='regular'
        color="grey-4">Try adjusting your search to find what you’re looking for.</Text>
    </div>
  );
};


const FilterModal: React.FC<FilterModalProps> = () => {
  const {
    sectorDropdownData,
    regionDropdownData,
    suppliersDropdownData,
    isOpen,
    closeModal,
    onSearchChange,
    searchText,
    handleCheckboxToggle,
    handleChildCheckboxToggle,
    filteredChipList,
    isAllSupplierSelected,
    isEmptyState,
    handleApplyFilterClick,
    applyFilterButtonDisabled,
  } = useFilterModalPresenter();

  return (
    <Modal
      className={cx(styles.FilterModal)}
      renderBackdrop={backdrop}
      show={isOpen}
    >
      <div className={cx(styles.FilterModalContainer)} >
        <div className={cx(styles.headerContent)}>
          <Text
            className={cx(styles.titleText)}
            type='h3'
            size='md'
            weight='bold'
            color="grey-2">Select Suppliers</Text>
          <button className={cx(styles.closeIconButton)} onClick={closeModal}>
            <Icon asset={'Close'} colour={'NeutralDefault'} state={'Default'} />
          </button>
        </div>
        <div className={cx(styles.filterColumnsContainer)}>
          <div className={cx(styles.filterColumnLeftContainer)}>
            <div className={cx(styles.columnHeader)}>
              <Text
                type="p"
                size='md'
                weight='semi-bold'
                color="grey-2">Filter Supplier List</Text>
            </div>
            <div className={styles.filterLeftContainer}>
              <ExpandableComponent className={styles.content} Header={ExpandableHeaderComponent('Industry')} content={ExpandableListComponent(sectorDropdownData, handleCheckboxToggle,
                handleChildCheckboxToggle,
              )} />
              <ExpandableComponent className={styles.content} Header={ExpandableHeaderComponent('Location')} content={ExpandableListComponent(regionDropdownData, handleCheckboxToggle,
                handleChildCheckboxToggle,
              )} />
            </div>
          </div>
          <div className={cx(styles.filterColumnRightContainer)}>
            <div className={cx(styles.columnHeader)}>
              <Text
                type="p"
                size='md'
                weight='semi-bold'
                color="grey-2">Suppliers</Text>
            </div>
            <div className={cx(styles.filterRightContainer)}>
              <SearchInput onChange={onSearchChange} value={searchText} />
              <div className={cx(styles.filterRow)}>
                <Text
                  type="p"
                  size='xs'
                  weight='regular'
                  color="grey-2">Filters </Text>
                <HorizontalChipList filteredChipList={filteredChipList} />
              </div>
              {isEmptyState ? <EmptyStateComponent /> : <>
                <CheckboxItem
                  item={{
                    name: 'Select All',
                    id: 'selectAll',
                  }}
                  isSelectAll={isAllSupplierSelected}
                  selectedItems={suppliersDropdownData.selectedItems}
                  setSelectedItems={suppliersDropdownData.setSelectedItems}
                  type={'Default'}
                  handleCheckboxToggle={handleCheckboxToggle}
                />
                <div className={styles.suppliersList}>
                  <List
                    className={styles.list}
                    items={suppliersDropdownData?.items}
                    renderItem={(item) =>
                      <CheckboxItem
                        item={item}
                        selectedItems={suppliersDropdownData.selectedItems}
                        setSelectedItems={suppliersDropdownData.setSelectedItems}
                        type={suppliersDropdownData.type}
                        handleCheckboxToggle={handleCheckboxToggle}
                        handleChildCheckboxToggle={handleChildCheckboxToggle}
                      />} />
                </div>
              </>}
            </div>
          </div>
        </div>
        <div className={cx(styles.bottomContent)}>
          <Button className={cx(styles.closeButton)} onClick={closeModal}>
            <Text
              type="p"
              size='sm'
              color="grey-2">Close</Text>
          </Button>
          <Button className={cx(styles.applyButton)} style='primary' onClick={handleApplyFilterClick} disabled={applyFilterButtonDisabled}>
            <Text
              type="p"
              size='sm'
              color="grey-8">Apply Filters</Text>
          </Button>
        </div>
      </div>
    </Modal>
  );

};

export default FilterModal;