import cx from 'classnames';
import React from 'react';

import { isSafari } from '../../../lib/utils';

import styles from './Page.module.scss';
import { PageProps } from './Page.types';

const PrintPage: React.FC<PageProps> = (props) => {
  const {
    className,
    children,
  } = props;
  return (
    <div className = {cx((isSafari ? styles.SafariPrintPage : styles.PrintPage), className)}>
      {children}
    </div>
  );

};
 
export default PrintPage;