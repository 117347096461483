import { EmployeeSummary } from '../../../lib/api/resources/reports';
import { DataFrame } from '../../data_frame/DataFrame';
import { DATA_COLORS } from '../../../lib/constants';
import { DatasetConfig, buildDataset } from '../../reports/utils';

// Function to process employee summary data and generate datasets and labels for gender and ethnicity
export const getGenderEthnicityDatasetAndLabels = (
  rawEmployeeSummary: EmployeeSummary[],
  keyField: keyof EmployeeSummary,
  year: number,
) => {
  // Query the dataset for the specified year
  const queriedDF = new DataFrame(rawEmployeeSummary).query({
    where: [{ comparator: (props) => props.row.year === year }],
    select: ['gender', 'ethnicity', keyField],
    roundValues: true,
  });

  // Initialize an accumulator to store aggregated data
  const accumulator: Record<string, Record<string, string | number>> = {};

  // Process queried data and generate by ethnicity and gender
  queriedDF.getDataset().forEach(item => {
    const { gender, ethnicity } = item;
    const aggFieldValue = item[keyField];

    // Ensure the ethnicity exists in the accumulator
    if (!accumulator[ethnicity]) {
      accumulator[ethnicity] = { ethnicity };
    }

    // Add gender-specific data to the accumulator
    const genderKey = gender;
    accumulator[ethnicity][genderKey] = Number(aggFieldValue);
  });

  // Convert accumulator into array of objects
  const transformed = Object.values(accumulator);

  // Configuration for dataset creation
  const config: DatasetConfig<typeof transformed[number]> = {
    labelKeys: ['ethnicity'],
    datasetKeys: [
      { datasetLabel: 'Male', datasetProperty: 'male', color: DATA_COLORS.BLUE },
      { datasetLabel: 'Female', datasetProperty: 'female', color: DATA_COLORS.GREEN },
    ],
  };

  // Generate datasets based on transformed data and configuration
  const generatedDataSets = buildDataset(transformed, config);
  // Calculate average value of key field

  return {
    labels: generatedDataSets.labels,
    chartDatasets: generatedDataSets.datasets,
  };
};