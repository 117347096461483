import { GenderTableProps } from './GenderTable.types';
import { buildDataForGenderTable } from './GenderTable.utils';

const useGenderTablePresenter = (props: GenderTableProps) => {
  const { data, year, workforceGraphType } = props;

  const {
    jobCategoryValueGroups,
    seniorityValueGroups,
    ethnicityLabels,
  } = buildDataForGenderTable(data, year);

  const categoryNames = workforceGraphType === 'Seniority' ? Object.keys(seniorityValueGroups).reverse() : Object.keys(jobCategoryValueGroups);

  return {
    workforceGraphType,
    categoryNameData: workforceGraphType === 'Seniority' ? seniorityValueGroups : jobCategoryValueGroups,
    ethnicityLabels,
    categoryNames,
  };
};

export default useGenderTablePresenter;