import React from 'react';
import cx from 'classnames';

import styles from './TextField.module.scss';
import { TextFieldProps } from './TextField.types';
import Icon, { IconProps } from '../../atoms/Icon';
import InputField from '../../atoms/InputField';
import Text from '../../atoms/Text';

const TextField: React.FC<TextFieldProps> = (props) => {
  const {
    className,
    state,
    text,
    labelText,
    inputField,
  } = props;

  let captionView;
  if (state === 'Error' || state === 'Caption') {
    const isError = state === 'Error';
    const captionIconProps = {
      asset: 'Info',
      colour: 'NeutralSubdued',
      state: 'Default',
    } as IconProps;
  
    const errorIconProps = {
      asset: 'ErrorOctagon',
      colour: 'Error',
      state: 'Default',
    } as IconProps;

    const iconProps = isError ? errorIconProps : captionIconProps;

    const captionTextFontColor = isError ? 'error' : 'grey-4';

    captionView = (
      <div className={styles.captionContent}>
        <Icon className={styles.icon} {...iconProps} />
        <Text
          size='xs'
          weight='regular'
          type='p'
          color={captionTextFontColor}
        >
          {text}
        </Text>
      </div>
    );
  }

  return (
    <div className = {cx(styles.TextField, className)}>
      <Text
        className={styles.label}
        size='sm'
        weight='semi-bold'
        type='p'
        color='grey-2'
      >
        {labelText}
      </Text>
      <InputField className={styles.inputField} {...inputField} />
      {captionView}
    </div>
  );

};
 
export default TextField;