import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { DASHBOARD_ROUTES } from '../../../lib/constants';
import WageQualityPage from '../../pages/WageQualityPage';
import DiversityPage from '../../pages/DiversityPage';
import OpportunityPage from '../../pages/OpportunityPage';
import JobCreationPage from '../../pages/JobCreationPage';
import OverviewPage from '../../pages/OverviewPage';

const DashboardRouter: React.FC<{ className?: string }> = () => {
  return (
    <Switch>
      <Route path={DASHBOARD_ROUTES.wageQuality} exact>
        <WageQualityPage />
      </Route>
      <Route path={DASHBOARD_ROUTES.diversity} exact>
        <DiversityPage />
      </Route>
      <Route path={DASHBOARD_ROUTES.opportunity} exact>
        <OpportunityPage />
      </Route>
      <Route path={DASHBOARD_ROUTES.jobCreation} exact>
        <JobCreationPage />
      </Route>
      <Route path={DASHBOARD_ROUTES.overview} exact>
        <OverviewPage />
      </Route>
      {/* If route does not match with above user will be redirected to overview page */}
      <Redirect to={DASHBOARD_ROUTES.overview} />
    </Switch>
  );
};

export default DashboardRouter;
