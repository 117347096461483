import React from 'react';
import cx from 'classnames';

import AuthenticationBackgroundImage from '../../../resources/images/LunumAuthImage.png';

import styles from './AuthenticationLayout.module.scss';
import { AuthenticationLayoutProps } from './AuthenticationLayout.types';
import Image from '../../atoms/Image';
import AuthenticationRouter from './AuthenticationLayout.router';
import AuthenticationFooter from '../../molecules/AuthenticationFooter';
import Header from '../../organisms/Header';

const AuthenticationLayout: React.FC<AuthenticationLayoutProps> = (props) => {
  const {
    className,
  } = props;
  return (
    <div className = {cx(styles.AuthenticationLayout, className)}>
      <Header className={styles.Header}/>
      
      <div className={styles.PageContent}>
        <Image className={styles.Image} imageSrc={AuthenticationBackgroundImage} />

        <div className={styles.FormContent}>

          <AuthenticationRouter className={styles.Form} />

          <AuthenticationFooter className={styles.Footer} />
        </div>
      </div>
    </div>
  );

};
 
export default AuthenticationLayout;