import { BFF_URL } from '../../config';
import axiosInstance from '../axios';

export type SignUpPayload = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  inviteToken?: string;
};
  
export type Account = {
  id: number;
  uuid: string;
  email: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
};
  
export type AccountResponse = Account & {
  createdAt: Date;
  updatedAt: Date;
  token: string;
};
export const signUp = async (payload: SignUpPayload): Promise<AccountResponse> => {
  const { data } = await axiosInstance.post<AccountResponse>(`${BFF_URL}/auth/signUp`, {
    ...payload,
    email: payload.email.trim(),
  });
  return data;
};