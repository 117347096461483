export const EMPLOYEE_LEVELS_ROWS = {
  '7': 'Level 7',
  '6': 'Level 6',
  '5': 'Level 5',
  '4': 'Level 4',
  '3': 'Level 3',
  '2': 'Level 2',
  '1': 'Level 1',
};

export const EMPLOYEE_LEVELS = {
  'Level 7': 'Senior Executive',
  'Level 6': 'Executive',
  'Level 5': 'Director',
  'Level 4': 'Manager',
  'Level 3': 'Associate',
  'Level 2': 'Junior',
  'Level 1': 'Entry',
};

export const GENDER_MAPPING = {
  'male': 'Male',
  'female': 'Female',
};

export const ETHNICITY_NAME_MAPPING = {
  'asian': 'Asian',
  'nh_black': 'Black',
  'hispanic': 'Hispanic',
  'nh_white': 'White',
  'local_other': 'Other',
};
