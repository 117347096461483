import { EmployeeSummary, Summary, SummaryKeys } from '../../../lib/api/resources/reports';
import { formatAmount, getMinMaxForKeyTakeaway, getYearSummaryTotalAdjustmentValue, getYoYDiffForProperty } from '../../../lib/utils';
import { DataFrame } from '../../../modules/data_frame/DataFrame';
import { View } from '../../organisms/FilterModal/FilterModal.types';
import { ETHNICITY_NAME_MAPPING, GENDER_MAPPING } from '../../organisms/GenderTable/GenderTable.constants';

const buildDataForPortfolioView = (summaryDF: DataFrame<Record<string, string | number>>) => {
  //Construct the list to select all intensities for the specified page
  const intensityKeys = ['diversityAdjustment', 'totalEmploymentImpact'];
  const {
    maxRecord,
    minRecord,
  } = getMinMaxForKeyTakeaway(summaryDF, intensityKeys, ['supplier'], 'diversityAdjustment', 'diversityAdjustment');

  const keyTakeawayValues = {
    'diversityAdjustmentGrowthPotential': {
      supplier: minRecord?.supplier,
    },
    'diversityAdjustmentPerformance': {
      supplier: maxRecord?.supplier,
    },
  };

  return {
    keyTakeawayValues,
  };
};

const buildDataForCompanyView = (summaryDF: DataFrame<Record<string, string | number>>) => {
  const intensityKeys = ['diversityAdjustment', 'totalEmploymentImpact'];
  const {
    maxRecord,
    minRecord,
  } = getMinMaxForKeyTakeaway(summaryDF, intensityKeys, ['supplier', 'location'], 'diversityAdjustment', 'diversityAdjustment');

  const keyTakeawayValues = {
    'diversityAdjustmentGrowthPotential': {
      supplier: minRecord?.supplier,
      location: minRecord?.location,
    },
    'diversityAdjustmentPerformance': {
      supplier: maxRecord?.supplier,
      location: maxRecord?.location,
    },
  };

  return {
    keyTakeawayValues,
  };
};

const buildDataForRegionView = (
  rawSummary: Summary[],
  employeeSummaryDF: DataFrame<Record<string, string | number>>,
  year: number,
) => {
  const summaryIntensityKeys: SummaryKeys[] = ['diversityAdjustment'];
  const rawSummaryDF = new DataFrame(rawSummary).query({});
  const { queriedDF } = getMinMaxForKeyTakeaway(rawSummaryDF, summaryIntensityKeys, ['location', 'supplier', 'year'], '', '');

  const { diffInPercentage, diffText, currentYearRecord } = getYoYDiffForProperty(queriedDF.getDataset(), 'diversityAdjustment', year);

  const { minRecord: minWorkforceGap, queriedDF: queriedEmpSummaryDF } = getMinMaxForKeyTakeaway(
    employeeSummaryDF,
    ['supplier', 'location', 'workforceGap'],
    ['gender', 'ethnicity'],
    'workforceGap',
    'workforceGap',
    false,
  );

  const ethnicityMatchingExpectedGenderCount = queriedEmpSummaryDF.query({
    where: [{
      comparator: (props) => {
        return Number(props.row.workforceGap) >= 0;
      },
    }],
  }).getDataset();

  const keyTakeawayValues = {
    'diversityAdjustmentGrowthPotential': {
      ethnicity: ETHNICITY_NAME_MAPPING[minWorkforceGap?.ethnicity || ''],
      gender: GENDER_MAPPING[minWorkforceGap?.gender || ''],
      supplier: minWorkforceGap?.supplier,
      location: minWorkforceGap?.location,
    },
    'diversityAdjustmentYoY': {
      supplier: currentYearRecord?.supplier,
      location: currentYearRecord?.location,
      diffText: diffText,
      diffInPercentage: diffInPercentage,
      currentYear: year,
      previousYear: (year - 1),
    },
  };

  if (ethnicityMatchingExpectedGenderCount) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    keyTakeawayValues['employeeRepresentation'] = {
      ethnicity: ETHNICITY_NAME_MAPPING[ethnicityMatchingExpectedGenderCount[0]?.ethnicity || ''],
      gender: GENDER_MAPPING[ethnicityMatchingExpectedGenderCount[0]?.gender || ''],
      supplier: ethnicityMatchingExpectedGenderCount[0]?.supplier,
      location: ethnicityMatchingExpectedGenderCount[0]?.location,
    };
  }

  return {
    keyTakeawayValues,
  };
};

const buildDataForPage = (view: View, year: number, rawSummary: Summary[], rawEmployeeSummary: EmployeeSummary[]) => {
  const {
    summaryDF,
    totalAdjustmentValue,
  } = getYearSummaryTotalAdjustmentValue(rawSummary, 'diversityAdjustment', year);

  if (view === 'Portfolio') {
    return {
      ...buildDataForPortfolioView(summaryDF),
      totalDiversityAdjustment: formatAmount(totalAdjustmentValue),
    };
  } else if (view === 'Company') {
    return {
      ...buildDataForCompanyView(summaryDF),
      totalDiversityAdjustment: formatAmount(totalAdjustmentValue),
    };
  }
  const filteredEmployeeSummaryDF = new DataFrame(rawEmployeeSummary).query({
    where: [{
      comparator: (props) => {
        return props.row.year === year;
      },
    }],
  });

  return {
    ...buildDataForRegionView(rawSummary, filteredEmployeeSummaryDF, year),
    totalDiversityAdjustment: formatAmount(totalAdjustmentValue),
  };
};

export default buildDataForPage;