import { useFilterModal } from '../../../shared_components/organisms/FilterModal/FilterModal.context';
import { getGenderEthnicityDatasetAndLabels } from './GenderEthnicityChart.utils';
import { GenderEthnicityChartProps } from './GenderEthnicityChart.types';

const useGenderEthnicityChartPresenter = (props: GenderEthnicityChartProps) => {
  const { filterState: { year } } = useFilterModal();

  const {
    data,
    datasetConfig,
  } = props;

  const {
    labels,
    chartDatasets: datasets,
  } = getGenderEthnicityDatasetAndLabels(data, datasetConfig, year);

  return {
    labels,
    datasets,
  };
};

export default useGenderEthnicityChartPresenter;