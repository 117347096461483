import { Summary } from '../../../lib/api/resources/reports';
import { calcIntensity } from '../../../lib/utils';
import { DataFrame } from '../../data_frame/DataFrame';
import { buildChartDatasets } from '../Chart.utils';
import { DatasetConfig } from '../Chart.types';

export const getYoYChartDatasetAndLabels = (
  data: Summary[],
  datasetConfig: DatasetConfig<Summary>[],
  isIntensity?: boolean,
) => {

  //Construct the list to select all intesities for the specified page
  const selectList = datasetConfig.map((dataset) => {
    return {
      as: dataset.datasetProperty,
      calculator: (props) => {
        return isIntensity
          ? calcIntensity(props.row, dataset.datasetProperty)
          : props.row[dataset.datasetProperty];
      },
    };
  });

  //Query the dataset
  const intensityDF = new DataFrame(data);
  const queriedDF = intensityDF.query({
    select: ['year', ...selectList],
    groupBy: ['year'], // grouping data by supplierName fields
    orderBy: ['year'], // ordering by year show it in sequential year order in chart
  });

  const datasets = buildChartDatasets(datasetConfig, queriedDF.getDataset() as Summary[], 'line');

  return {
    labels: queriedDF.col('year') as string[],
    chartDatasets: datasets,
  };
};