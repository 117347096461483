import React from 'react';
import cx from 'classnames';

import styles from './NavBar.module.scss';
import { NavBarProps } from './NavBar.types';
import NavItem from './NavItem/NavItem';
import useNavBarPresenter from './NavBar.presenter';

const NavBar: React.FC<NavBarProps> = () => {  

  const { navBarItems } = useNavBarPresenter();

  return (
    <nav className = {cx(styles.NavBar)}>
      {navBarItems?.map((navItem, index) => (
        <NavItem {...navItem} key={index} />
      ))}
    </nav>
  );

};
 
export default NavBar;