import React from 'react';

import { YoYChartProps } from './YoYChart.types';
import Chart from '../Chart';
import useYoYChartPresenter from './YoYChart.presenter';

const YoYChart: React.FC<YoYChartProps> = (props) => {
  const {
    className,
    stacked,
    height,
    width,
    printMode,
  } = props;

  const { labels, chartDatasets: datasets } = useYoYChartPresenter(props);

  return (
    <Chart
      className={className}
      height={height}
      width={width}
      labels={labels}
      datasets={datasets}
      alignment={'left'}
      orientation={'vertical'}
      type='line'
      stacked={stacked}
      displayXAxis={false}
      displayXAxisGrid={false}
      displayYAxisGrid={true}
      tooltipLayout={stacked ? 'stackedLine' : 'standardLine'}
      printMode={printMode}
    />
  );

};
 
export default YoYChart;