import DiversityImage from '../../../resources/images/diversity.png';
import WageQualityImage from '../../../resources/images/wage_image.png';
import OpportunityImage from '../../../resources/images/opportunity.png';
import JobCreationImage from '../../../resources/images/jobCreation.png';

export type GlossaryHeaderType = {
  title: string;
  desc: string;
  imageSrc?: string;
};
export const glossaryHeaderDefinition = {
  wageQuality: {
    title: 'Wage Quality',
    desc: 'Wage Quality is a significant contributor to human flourishing and varies widely at local and national levels. Transforming communities through the lens of employment requires that we look at the disparity in wages among groups and across geographies as the most vulnerable workers who earn the lowest wages also experience the highest levels of inequality. Wage Quality varies for employees within the same company, and structural inequalities often magnify pay inequity',
    imageSrc: WageQualityImage,
  },
  diversity: {
    title: 'Diversity',
    desc: 'The Diversity Adjustment determines the negative cost to the local labor community based on whether the organization’s workforce is representative of the demographics of the local population.adjustment.',
    imageSrc: DiversityImage,
  },
  opportunity: {
    title: 'Opportunity',
    desc: 'The Opportunity Adjustment evaluates how closely a company’s hierarchy and representation across different job roles reflect the demographics of the company as a whole by examining the demographic makeup of seniority levels and job categories within the firm.',
    imageSrc: OpportunityImage,
  },
  jobCreation: {
    title: 'Job Creation',
    desc: 'Quality work opportunities are imperative for health, well-being, and wealth creation and organizations can dramatically influence local economies with their practices. Many factors are considered when a company chooses to add headcount and the location of those jobs can have a significant impact on the stakeholders in the local community. Placing a value on Job Creation as a dimension of Employment Impact encourages companies to consider opportunities where they are most needed throughout their network and is one additional way to use Employment Impact as a managerial decision-making tool. Adding jobs to an area with high unemployment will increase Job Creation. Job Creation is monetized by calculating the additional income beyond unemployment benefits that an employee would earn from being employed by the company.',
    imageSrc: JobCreationImage,
  },
  overview: {
    title: '',
    desc: '',
    imageSrc: '',
  },
};