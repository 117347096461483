import { DataSetRecord } from '../../data_frame/DataFrame';
import { DatasetConfig } from '../Chart.types';

export const GENDER_ETHNICITY_CHART_LEGEND_KEYS: DatasetConfig<DataSetRecord>[] = [
  {
    color: 'BLUE',
    datasetLabel: 'Male',
    datasetProperty: 'Male',
  },
  {
    color: 'GREEN',
    datasetLabel: 'Female',
    datasetProperty: 'Female',
  },
];