import React from 'react';
import cx from 'classnames';

import styles from './Icon.module.scss';
import { IconCombinedProps } from './Icon.types';

import { ReactComponent as ArrowDownIcon } from '../../../resources/icons/ArrowDown.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../resources/icons/ArrowLeft.svg';
import { ReactComponent as ArrowUpIcon } from '../../../resources/icons/ArrowUp.svg';
import { ReactComponent as CalculationsIcon } from '../../../resources/icons/Calculations.svg';
import { ReactComponent as CheckIcon } from '../../../resources/icons/Check.svg';
import { ReactComponent as CheckboxEmptyIcon } from '../../../resources/icons/CheckboxEmpty.svg';
import { ReactComponent as CheckboxFilledIcon } from '../../../resources/icons/CheckboxFilled.svg';
import { ReactComponent as ChevronDownIcon } from '../../../resources/icons/ChevronDown.svg';
import { ReactComponent as ChevronUpIcon } from '../../../resources/icons/ChevronUp.svg';
import { ReactComponent as CloseIcon } from '../../../resources/icons/Close.svg';
import { ReactComponent as ColumnArrowIcon } from '../../../resources/icons/ColumnArrow.svg';
import { ReactComponent as ErrorCircleIcon } from '../../../resources/icons/ErrorCircle.svg';
import { ReactComponent as ErrorOctagonIcon } from '../../../resources/icons/ErrorOctagon.svg';
import { ReactComponent as ExpandIcon } from '../../../resources/icons/Expand.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../resources/icons/ExternalLink.svg';
import { ReactComponent as EyeClosedIcon } from '../../../resources/icons/EyeClosed.svg';
import { ReactComponent as EyeOpenIcon } from '../../../resources/icons/EyeOpen.svg';
import { ReactComponent as FilterIcon } from '../../../resources/icons/Filter.svg';
import { ReactComponent as GraphIcon } from '../../../resources/icons/Graph.svg';
import { ReactComponent as InfoIcon } from '../../../resources/icons/Info.svg';
import { ReactComponent as InfoTooltipIcon } from '../../../resources/icons/InfoTooltip.svg';
import { ReactComponent as KnowledgeIcon } from '../../../resources/icons/Knowledge.svg';
import { ReactComponent as LogoutIcon } from '../../../resources/icons/Logout.svg';
import { ReactComponent as PlusIcon } from '../../../resources/icons/Plus.svg';
import { ReactComponent as PolicyIcon } from '../../../resources/icons/Policy.svg';
import { ReactComponent as PrintIcon } from '../../../resources/icons/Print.svg';
import { ReactComponent as ProfileIcon } from '../../../resources/icons/Profile.svg';
import { ReactComponent as QuestionIcon } from '../../../resources/icons/Question.svg';
import { ReactComponent as ResetIcon } from '../../../resources/icons/Reset.svg';
import { ReactComponent as ScreenSizeIcon } from '../../../resources/icons/ScreenSize.svg';
import { ReactComponent as SearchIcon } from '../../../resources/icons/Search.svg';
import { ReactComponent as SortIcon } from '../../../resources/icons/Sort.svg';
import { ReactComponent as TermsIcon } from '../../../resources/icons/Terms.svg';
import { ReactComponent as USFlagIcon } from '../../../resources/icons/USFlag.svg';

const Icon: React.FC<IconCombinedProps> = (props) => {
  const {
    asset,
    colour,
    state,
    className,
  } = props.usePresenter?.(props) || props;

  const variantName = `${asset}${colour}${state}`;
  const currentStyle = styles[`icon${variantName}`];


  let componentView;

  switch (variantName) {
    case 'ArrowDownDataPurpleDefault':
    case 'ArrowDownPrimaryDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArrowDownIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ArrowLeftNeutralDefaultDefault':
    case 'ArrowLeftNeutralSubduedDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArrowLeftIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ArrowUpPrimaryDefault':
    case 'ArrowUpYoYGreenDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArrowUpIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CalculationsNeutralSubduedSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CalculationsIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CheckNeutralDisabledDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CheckIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CheckboxEmptyNeutralDisabledSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CheckboxEmptyIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CheckboxFilledPrimarySelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CheckboxFilledIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ChevronDownNeutralDefaultDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronDownIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ChevronUpNeutralDefaultDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronUpIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'CloseNeutralDefaultDefault':
    case 'CloseNeutralSubduedDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CloseIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ColumnArrowPrimaryDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ColumnArrowIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ErrorCircleErrorDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ErrorCircleIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ErrorOctagonErrorDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ErrorOctagonIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ExpandNeutralDefaultDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ExpandIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ExternalLinkNeutralDefaultDefault':
    case 'ExternalLinkPrimaryDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ExternalLinkIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'EyeClosedNeutralSubduedDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <EyeClosedIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'EyeOpenNeutralSubduedDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <EyeOpenIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'FilterNeutralDefaultDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <FilterIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'FilterNeutralSubduedDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <FilterIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'GraphNeutralSubduedSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <GraphIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'InfoNeutralSubduedDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <InfoIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'InfoTooltipSecondaryDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <InfoTooltipIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'KnowledgeNeutralDefaultSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <KnowledgeIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'LogoutNeutralSubduedDefault':
    case 'LogoutNeutralSubduedSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LogoutIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'PlusNeutralDefaultDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PlusIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'PolicyNeutralSubduedDefault':
    case 'PolicyNeutralSubduedSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PolicyIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'PrintNeutralDefaultDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PrintIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ProfileNeutralSubduedDefault':
    case 'ProfileNeutralSubduedSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ProfileIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'QuestionPrimaryDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <QuestionIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ResetPrimarySelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ResetIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'ScreenSizePrimarySelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ScreenSizeIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'SearchNeutralDefaultDefault':
    case 'SearchNeutralSubduedDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <SearchIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'SortNeutralDefaultDefault':
    case 'SortNeutralSubduedDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <SortIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'TermsNeutralSubduedDefault':
    case 'TermsNeutralSubduedSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <TermsIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    case 'USFlagNeutralDefaultDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <USFlagIcon style={{ width: '100%', height: '100%' }} />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Icon;

