import { ABOUT_LUNUM_LINK, PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from '../../../lib/constants';

export const UsefulLinks = [
  {
    buttonTextTranslationKey: 'MyProfilePage.termsOfUse',
    link: TERMS_OF_USE_LINK,
  },
  {
    buttonTextTranslationKey: 'MyProfilePage.privacyPolicy',
    link: PRIVACY_POLICY_LINK,
  },
  {
    buttonTextTranslationKey: 'MyProfilePage.aboutLunum',
    link: ABOUT_LUNUM_LINK,
  },
];