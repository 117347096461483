import { useState } from 'react';

const useCheckboxItemPresenter = () => {

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return {
    handleToggle,
    isOpen,
  };
};

export default useCheckboxItemPresenter;