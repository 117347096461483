import React, { createContext, useMemo, useState } from 'react';
import { Account, extractJwtPayload } from '../../lib/token';
import { getSessionCookie } from '../auth/utils';
import { ADMIN_ROLE_ID } from '../../lib/config';
import useLogout from './useLogout';

export const Logout: React.FC<{}> = () => {
  const handleLogout = useLogout();
  handleLogout();
  return null;
};

export type AuthContextValue = {
  account?: Account;
  updateAccount: (account?: Account) => void;
  refetchAccount: () => void;
  isAdmin?: boolean;
};

const initialAuthContext: AuthContextValue = {
  account: undefined,
  updateAccount: () => { },
  refetchAccount: () => { },
};

export const AuthContext = createContext<AuthContextValue>(initialAuthContext);

export const AuthProvider: React.FC<{}> = ({ children }) => {
  const cookie = getSessionCookie();
  const [account, setAccount] = useState<Account | undefined>(
    cookie ? extractJwtPayload(cookie) : undefined,
  );

  const updateAccount = (userAccount: Account | undefined) => {
    setAccount(userAccount);
  };

  const refetchAccount = () => {
    const refreshedCookie = getSessionCookie();
    setAccount(refreshedCookie ? extractJwtPayload(refreshedCookie) : undefined);
  };

  const isAdmin = useMemo(() => {
    return account?.roleId === Number(ADMIN_ROLE_ID);
  }, [account]);

  return (
    <AuthContext.Provider value={{
      account,
      updateAccount,
      refetchAccount,
      isAdmin,
    }}>
      {children}
    </AuthContext.Provider>
  );
};