import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';

import styles from './ActionPage.module.scss';
import { ActionPageProps } from './ActionPage.types';

const ActionPage: React.FC<ActionPageProps> = (props) => {
  const {
    className,
    labelText,
    instructions,
    buttonText,
    link,
    buttonClick,
  } = props;
  return (
    <div className = {cx(styles.ActionPage, className)}>
      <div className={styles.instructionContent}>
        <Text
          size='xl'
          type='p'
          color='grey-2'
          weight='bold'
        >
          {labelText}
        </Text>
        <Text
          size='sm'
          type='p'
          color='grey-4'
          weight='regular'
        >
          {instructions}
        </Text>
      </div>
      <Button onClick={buttonClick} href={link} style='primary'>
        <Text
          size='sm'
          type='p'
          weight='semi-bold'
          color='grey-8'
        >
          {buttonText}
        </Text>
      </Button>
    </div>
  );

};
 
export default ActionPage;