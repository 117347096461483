import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { DASHBOARD_ROUTES } from './lib/constants';
import { Logout } from './modules/auth/AuthContext';
import ProtectedRoute from './modules/auth/ProtectedRoute';
import AuthenticationLayout from './shared_components/layouts/AuthenticationLayout';
import DashboardLayout from './shared_components/layouts/DashboardLayout';
import ProfileLayout from './shared_components/layouts/ProfileLayout';
import ScreenSizeLayout from './shared_components/layouts/ScreenSizeLayout';

import styles from './App.module.scss';

const AppRouter: React.FC<{}> = () => {
  return (
    <>
      <Switch>
        <Route path='/logout'>
          <Logout />
        </Route>
        <Route path='/auth'>
          <AuthenticationLayout className={styles.layout} />
        </Route>
        <ProtectedRoute path='/profile'>
          <ProfileLayout className={styles.layout} />
        </ProtectedRoute>
        <ProtectedRoute path='/dashboard'>
          <DashboardLayout className={styles.layout} />
        </ProtectedRoute>
        {/* If base url doesn't matches with any of the above
            This will redirect user to overview page
            If user is not authenticated it will redirect them to signIn page
          because overview page is under protected route  */}
        <Redirect to={DASHBOARD_ROUTES.overview} />
      </Switch>
      <ScreenSizeLayout />
    </>
  );
};

export default AppRouter;