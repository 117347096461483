import React from 'react';
import cx from 'classnames';

import Text from '../../../atoms/Text';
import SupplierInfo from '../SupplierInfo';

import styles from './JobMapGraphCardSupplierList.module.scss';
import { JobMapGraphCardSupplierListProps } from './JobMapGraphCardSupplierList.types';
import { useTranslation } from 'react-i18next';

const JobMapGraphCardSupplierList: React.FC<JobMapGraphCardSupplierListProps> = (props) => {
  const {
    supplierInfoItems,
    printMode,
    showEmptyState,
  } = props;

  const { t } = useTranslation();

  return (
      <div className = {cx(styles.JobMapGraphCardSupplierList, (printMode ? styles.PrintJobMapGraphCardSupplierList : undefined))}>
        <div className={styles.supplierContentTitle}>
          <Text
            type='p'
            weight='semi-bold'
            color='grey-2'
            size='md'
            printMode={printMode}
          >
            {!showEmptyState && t('jobCreationPage.jobMapGraphCard.supplierListTitle')}
          </Text>
        </div>
        <div className={styles.supplierList}>

          {!showEmptyState && supplierInfoItems?.map((supplierInfoItem, index) => (
            <SupplierInfo {...supplierInfoItem} supplierIndex={`${index + 1}.`} key={index} printMode={printMode} />
          ))}

        </div>
      </div>
  );

};
 
export default JobMapGraphCardSupplierList;