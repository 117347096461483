import React from 'react';
import cx from 'classnames';

import styles from './NavItem.module.scss';
import { NavItemProps } from './NavItem.types';
import { NavLink } from 'react-router-dom';
import Text from '../../../atoms/Text';

const NavItem: React.FC<NavItemProps> = (props) => {
  const {
    className,
    to,
    label,
  } = props;

  return (
    <NavLink
      to={to}
      className={cx(styles.NavItem, className)}
    >
      <Text
      className={styles.Text}
        size='md'
        align='center'
        type='p'
        color='grey-2'
      >
        {label}
      </Text>
    </NavLink>
  );

};
export default NavItem;