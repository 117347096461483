import React, { useContext, useState } from 'react';
import cx from 'classnames';

import styles from './ChartCard.module.scss';
import Card from '../../atoms/Card';
import Text from '../../atoms/Text';
import Tooltip from '../../organisms/Tooltip/Tooltip';
import { DATA_COLOR } from '../../../modules/Chart/ChartTooltip/ChartTooltip.types';
import TooltipIcon from '../TooltipIcon';
import Button from '../../atoms/Button';
import { Modal } from 'react-overlays';
import { ReactComponent as ExpandIcon } from '../../../resources/icons/Expand.svg';
import { ReactComponent as CloseIcon } from '../../../resources/icons/Close.svg';
import { useTooltip } from '../../organisms/Tooltip/useTooltip';

import { LegendDefs, TooltipDefs } from '../../organisms/Tooltip/Tooltip.constant';
import { DatasetConfig } from '../../../modules/Chart/Chart.types';
import { DATA_COLORS } from '../../../lib/constants';
import { ChartCardProps } from './ChartCard.types';
import { HeaderProps } from './ChartCard.types';
import { LegendProps, LegendGroupProps } from './ChartCard.types';
import ChartCardContext from './ChartCard.context';

export const Header: React.FC<HeaderProps> = (props) => {
  const {
    title,
    subtitle,
    isExpandable = false,
    printMode,
  } = props;
  const {
    isExpanded,
    expandChart,
    collapseChart,
  } = useContext(ChartCardContext);


  //IF chart can be expanded, setup header buttons to manage expansion/collapse
  let expandButton;

  if (isExpandable) {
    const clickHandler = (isExpanded) ? collapseChart : expandChart;
    const expandButtonText = (isExpanded) ? 'Close' : 'Expand';
    const expandButtonIcon = (isExpanded) ? (<CloseIcon />) : (<div style={{ width: '14px', height: '14px' }} ><ExpandIcon /></div>);
    expandButton = (
      <Button
        onClick={clickHandler}
        className={styles.button}
      >
        {expandButtonText}
        {expandButtonIcon}
      </Button>
    );
  } else {
    expandButton = null;
  }

  return (
    <div className={cx(styles.Header, (printMode ? styles.PrintHeader : undefined))}>
      <div className={styles.title}>
        <div className={styles.leftside}>
          <Text
            type="h2"
            weight='bold'
            size='sm'
            color='grey-2'
            printMode={printMode}
          >{title}
            {!printMode ? <TooltipIcon
              style={{ marginLeft: '6px' }}
              title={title}
              desc={TooltipDefs[title]} /> : null
            }</Text>

        </div>
        {expandButton}
      </div>
      <div className={styles.subtitle}>
        <Text type="p" size='xs' color='grey-4' printMode={printMode}>{subtitle}</Text>
      </div>
    </div>
  );
};


const Legend: React.FC<LegendProps> = (props) => {
  const { label, backgroundColor, printMode, shouldShowDefinition = true } = props;

  //TODO: Refactor mouse and tooltip hook
  const {
    anchorRef,
    tooltipRef,
    isVisible,
    handleMouseEnter,
    handleMouseExit,
    coordinates,
  } = useTooltip(
    5,
    10,
    'top',
  );
  return (
    <div ref={anchorRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseExit} className={styles.Legend} style={{ position: 'relative' }}>
      <div
        className={styles.indicator}
        style={{ backgroundColor: backgroundColor }} />
      <Text
        className={shouldShowDefinition ? styles.label : ''}
        type='p'
        size={'xs'}
        weight={'regular'}
        color={'grey-4'}
        printMode={printMode}>{label}</Text>
      {shouldShowDefinition ? (
        <Tooltip
          ref={tooltipRef}
          visible={isVisible}
          coordinates={coordinates}
        >
          <div className={cx(styles.LegendTooltip)}>
            <Text size='sm' color='grey-2' weight='semi-bold' noWrap printMode={printMode}>{label}</Text> {/*title*/}
            <Text className={styles.desc} size='sm' type='p' color='grey-4' printMode={printMode}>{LegendDefs[label]}</Text> {/*desc*/}
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
};

// eslint-disable-next-line 
export const LegendGroup = <T,>(props: LegendGroupProps<T>) => {
  const {
    datasets,
    shouldShowDefinition,
    className,
    printMode,
  } = props;

  return (
    <Tooltip.Provider>
      <div className={cx(styles.LegendGroup, className, (printMode ? styles.PrintLegendGroup : undefined))}>
        {
          datasets
            .filter((set: DatasetConfig<T>) => !set.hidden) // Hide legend if data set is not hidden.
            .map((dataset) => {
              const {
                color,
                datasetLabel,
              } = dataset;
              return (
                <Legend
                  key={datasetLabel}
                  label={datasetLabel}
                  backgroundColor={DATA_COLORS[color] as DATA_COLOR}
                  shouldShowDefinition={shouldShowDefinition}
                  printMode={printMode}
                />
              );
            })
        }
      </div>
    </Tooltip.Provider>
  );
};

const ChartCard = (props: ChartCardProps & { children?: React.ReactNode }) => {
  const {
    children,
    width = 'fill',
    printMode,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const expandChart = () => {
    setIsExpanded(true);
  };
  const collapseChart = () => {
    setIsExpanded(false);
  };


  return (
    <ChartCardContext.Provider value={{ isExpanded, expandChart, collapseChart }}>
      <Card
        className={cx(styles.ChartCard, styles[width], (printMode ? styles.PrintChartCard : undefined))}
        printMode={printMode}>
        {children}
      </Card>

      {isExpanded &&

        <Modal
          show={isExpanded}
          className={styles.modalContent}
          autoFocus
        >
          <>
            {children}
          </>
        </Modal>
      }
    </ChartCardContext.Provider>
  );
};


ChartCard.Header = Header;
ChartCard.Legend = LegendGroup;
export default ChartCard;