import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { formatAmount, getStateShortForm } from '../../../../lib/utils';
import Text from '../../../atoms/Text';

import styles from './JobMapGraphCardHeader.module.scss';
import { JobMapGraphCardHeaderProps } from './JobMapGraphCardHeader.types';

const JobMapGraphCardHeader: React.FC<JobMapGraphCardHeaderProps> = (props) => {
  const {
    printMode,
    selectedState,
    intensity,
    totalSuppliers,
  } = props;

  const { t } = useTranslation();

  return (
    <div className={cx(styles.JobMapGraphCardHeader, styles.PrintJobMapGraphCardHeader)}>
      <div className={styles.title}>
        <div className={styles.label}>
          <Text
            color='grey-2'
            size='lg'
            type='p'
            weight='bold'
            printMode={printMode}
          >
            {selectedState
              ? t('jobCreationPage.jobMapGraphCard.title', {
                state: selectedState,
                stateNameShort: getStateShortForm(selectedState || ''),
              })
              : t('jobCreationPage.jobMapGraphCard.emptyStateTitle')}
          </Text>
        </div>
        <div className={styles.value}>
          <Text
            color='grey-2'
            size='lg'
            type='p'
            weight='bold'
            printMode={printMode}
          >
            {selectedState && formatAmount(intensity)}
          </Text>
        </div>
      </div>
      <Text
        color='grey-4'
        size='xs'
        type='p'
        weight='regular'
        printMode={printMode}
      > {selectedState
        ? t('jobCreationPage.jobMapGraphCard.description', {
          totalSuppliers: totalSuppliers,
        })
        : t('jobCreationPage.jobMapGraphCard.emptyStatedDescription')} </Text>
    </div>
  );

};
 
export default JobMapGraphCardHeader;