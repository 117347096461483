import React, { FC, HTMLAttributes, forwardRef, useCallback, useContext, useMemo, useState } from 'react';
import cx from 'classnames';
import { useSpring, animated } from 'react-spring';

import styles from './Tooltip.module.scss';
import { TooltipContextValue, TooltipContextProps, TooltipSettings, TooltipCoordinates, TooltipProps } from './Tooltip.types';

const initalSettingsState:TooltipSettings = {
  coordinates: { x: 0, y: 0 },
  isOpen: false,
};
const initalContextState:TooltipContextValue = {
  settings: initalSettingsState,
  openTooltip: () => {},
  closeTooltip: () => {},
  positionTooltip: () => {},
};
export const TooltipContext = React.createContext<TooltipContextValue>(initalContextState);

const TooltipProvider: FC<TooltipContextProps> = ({ children }) => {
  const [settings, updateSettings] = useState<TooltipSettings>(initalSettingsState);

  const closeTooltip = useCallback(() => {
    updateSettings(prev => ({ ...prev, isOpen: false }));
  }, []);

  const openTooltip = useCallback(() => {
    updateSettings(prev => ({ ...prev, isOpen: true }));
  }, []);

  const positionTooltip = useCallback((coordinates: TooltipCoordinates) => {
    updateSettings(prev => ({ ...prev, coordinates }));
  }, []);

  const contextValue = useMemo(() => ({
    settings,
    openTooltip,
    closeTooltip,
    positionTooltip,
  }), [settings, openTooltip, closeTooltip, positionTooltip ]);

  return (
    <TooltipContext.Provider value = {contextValue} >
      {children}
    </TooltipContext.Provider>
  );
};
//TODO: Refactor to remove context in favor of usestate
const TooltipComponent = forwardRef<HTMLDivElement, TooltipProps & HTMLAttributes<HTMLDivElement>>(
  (props, parentRef ) => {
    const { children, className, visible, coordinates: propCoords, animation = 'pop', ...rest } = props;
    const {
      settings,
    } = useContext(TooltipContext);

    const {
      coordinates: settingCoords,
      isOpen,
    } = settings;
    let left = 0;
    let top = 0;
    let isVisible = isOpen;

    if (settingCoords) {
      left = settingCoords.x;
      top = settingCoords.y;
    }
    if (propCoords) {
      left = propCoords.x;
      top = propCoords.y;
    }

    if (visible !== undefined) {
      isVisible = visible;
    }


    // Define the animation    
    const animationOptions = {
      pop:{
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'scale(1)' : 'scale(0.8)',
        config: { tension: 150, friction: 17 },
      },
      fade:{
        opacity: isVisible ? 1 : 0,
        config: { tension: 75, friction: 20 },
      },
    };

    const animationStyles = useSpring(animationOptions[animation]);

    //Do not render the component if it is not open
    if (!isVisible) return (null);

    //(!isVisible) ? styles.hidden : '')
    return (
        <animated.div
          ref={parentRef}
          className={cx(styles.Tooltip, className, (!isVisible) ? styles.hidden : '')} 
          // anchor = {anchor}
          style={{ 
            //TODO: Optimize by using translate
            left,
            top,
            position:'absolute', 
            ...animationStyles,
          }}
          data-animation={animation}
          {...rest}>
          {children}

        </animated.div>
    );
  },
);

//export a convience hook to access the context
export const useTooltip = (): TooltipContextValue => {
  const context = useContext(TooltipContext);

  if (context === undefined) {
    throw new Error('useTooltip must be used within a TooltipProvider');
  }
  return context;
};

// Define a type that includes the additional Provider property
interface TooltipWithProvider extends React.ForwardRefExoticComponent<TooltipProps & React.RefAttributes<HTMLDivElement>> {
  Provider: FC<TooltipContextProps>;
}

// Extend Tooltip with the Provider property
const Tooltip = TooltipComponent as TooltipWithProvider;
Tooltip.Provider = TooltipProvider;


export default Tooltip;