import React from 'react';
import cx from 'classnames';

import styles from './EmptyStateContent.module.scss';
import Text from '../../atoms/Text';
import Icon from '../../atoms/Icon';
import { EmptyStateContentProps } from './EmptyStateContent.types';

const EmptyStateContent: React.FC<EmptyStateContentProps> = (props) => {
  const {
    className,
    style = 'Horizontal',
  } = props;
  const styleClass = styles[`EmptyStateContent${style}`];
  return (
    <div className={cx(styles.EmptyStateContent, styleClass, className)}>
      <Icon
        className={cx(styles.icon)}
        colour='NeutralSubdued'
        asset='Graph'
        state='Selected'
      />
      <div className={cx(styles.descriptionContent)}>
        <Text
          color='grey-2'
          size='md'
          type='p'
          weight='bold'
        >
          No suppliers selected
        </Text>
        <Text
          color='grey-2'
          size='sm'
          type='p'
          weight='regular'
          className={styles.description}
        >
          Select suppliers from the top navigation to view this data.
        </Text>
      </div>
    </div>
  );

};
 
export default EmptyStateContent;