const percentageFormatter = ( limit: number ) => {
  const annotationFormatter = (value, ctx) => {

    //if the value is less than 1 don't show on chart
    if (value < limit) return '';
    
    //strip away decimal places and format with percentage
    return `${Math.floor(value)}%`;
  };
  return annotationFormatter;
};

export default percentageFormatter;