import React from 'react';
import cx from 'classnames';

import styles from './KeyTakeawayNumber.module.scss';
import Text from '../../../atoms/Text';

export type KeyTakeawayNumberProps = {
  keyTakeawayIndex?: React.ReactNode;
  printMode?: boolean;
};
const KeyTakeawayNumber: React.FC<KeyTakeawayNumberProps> = (props) => {
  const {
    keyTakeawayIndex,
    printMode,
  } = props;
  return (
    <div className={cx(styles.KeyTakeawayNumber, (printMode ? styles.PrintKeyTakeawayNumber : undefined))}>
      <Text
        color='grey-8'
        size='sm'
        type='p'
        weight='regular'
        printMode={printMode}
      >
        {keyTakeawayIndex}
      </Text>
    </div>
  );

};

export default KeyTakeawayNumber;