import React from 'react';
import cx from 'classnames';

import DefaultEmptyStateContent from '../../molecules/EmptyStateContent';

import WorkforcegapTable from './WorkforcegapTable';
import EmptyStateContent from './EmptyStateContent';

import styles from './WorkforceGapGenderEthnicityCard.module.scss';
import { WorkforceGapGenderEthnicityCardProps } from './types';
import EthnicityGenderPeopleChart from './EthnicityGenderPeopleChart';
import useWorkforceGapGenderEthnicityCardPresenter from './WorkforceGapGenderEthnicityCard.presenter';

const WorkforceGapGenderEthnicityCard: React.FC<WorkforceGapGenderEthnicityCardProps> = (props) => {
  const {
    view,
    printMode,
  } = props;

  const { workforceGapData } = useWorkforceGapGenderEthnicityCardPresenter(props);

  let componentView;
  switch (view) {
    case 'Region':
      componentView = (
        <>
          <div className={styles.chart}>
            <EthnicityGenderPeopleChart dataRows={workforceGapData} printMode={printMode} />
          </div>
          <WorkforcegapTable className={styles.table} dataRows={workforceGapData} printMode={printMode} />
        </>
      );
      break;

    case 'Company':
    case 'Portfolio':
      componentView = <EmptyStateContent />;
      break;
  
    default:
      componentView = <DefaultEmptyStateContent />;
      break;
  }

  if (view === 'Empty') {
    return <DefaultEmptyStateContent />;
  }

  if (view === 'Company' || view === 'Portfolio') {
    return <EmptyStateContent />;
  }

  return (
    <div className = {cx(styles.WorkforceGapGenderEthnicityCard, styles[`${view}State`])}>
      {componentView}
    </div>
  );

};
 
export default WorkforceGapGenderEthnicityCard;