import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import GenderEthnicityChart from '../../../modules/Chart/GenderEthnicityChart';
import { GENDER_ETHNICITY_CHART_LEGEND_KEYS } from '../../../modules/Chart/GenderEthnicityChart/GenderEthnicityChart.constants';
import IntensityChart from '../../../modules/Chart/IntensityChart';
import ProportionalChart from '../../../modules/Chart/ProportionalChart';
import YoYChart from '../../../modules/Chart/YoYChart';
import Card from '../../atoms/Card';
import Page from '../../atoms/Page';
import Text from '../../atoms/Text';
import ChartCard from '../../molecules/ChartCard';
import DimensionHeader from '../../molecules/DimensionHeader';
import TotalAdjustmentCard from '../../molecules/TotalAdjustmentCard';
import KeyTakeawaysCard from '../../organisms/KeyTakeawaysCard';

import styles from './WageQualityPage.module.scss';
import useWageQualityPagePresenter from './WageQualityPage.presenter';
import { WageQualityPageProps } from './WageQualityPage.types';

const WageQualityPage: React.FC<WageQualityPageProps> = () => {
  const { t } = useTranslation();
  const {
    view,
    appliedFilters,
    totalWageQualityAdjustmentAmount,
    genderEthnicityChartData,
    keyTakeawaysCard,
    femaleEmpowermentChart,
    maleEmpowermentChart,
    wageQualityIntensityChart,
    wageQualityYoYChart,
  } = useWageQualityPagePresenter();


  return (
    <Page className={cx(styles.WageQualityPage)} >
      <DimensionHeader page={'wageQuality'} />

      <div style={{
        display: 'flex', flexDirection: 'column', gap: '1.5rem', width: '312px', flexShrink: 0,
      }}>
        <Card>
          <TotalAdjustmentCard
            page='wageQuality'
            value={totalWageQualityAdjustmentAmount}
          />
        </Card>
        <KeyTakeawaysCard
          page='wageQuality'
          view={view}
          state={keyTakeawaysCard.state}
          keyTakeawaysValues={keyTakeawaysCard.values}
        />
      </div>

      <ChartCard
        width='offset'
      >
        <ChartCard.Header
          title={view === 'Region'
            ? t('WageQualityPage.wageQualityAdjustmentGraphTitle.intensity')
            : t('WageQualityPage.wageQualityAdjustmentGraphTitle.forEachSupplier')}
          subtitle={t('filtersApplied', { filters: appliedFilters })}
          isExpandable={view !== 'Region'}
        />
        {view === 'Region' ? (
          <YoYChart
            height={545}
            stacked={true}
            datasetConfig={wageQualityYoYChart.datasetConfig}
            data={wageQualityYoYChart.data}
            isIntensity
          />
        ) : (
          <IntensityChart
            datasetConfig={wageQualityIntensityChart.datasetConfig}
            alignment='right'
            data={wageQualityIntensityChart.data}
            height={545}
          />
        )}
        <ChartCard.Legend
          datasets={view === 'Region'
            ? wageQualityYoYChart.datasetConfig
            : wageQualityIntensityChart.datasetConfig}
        />
      </ChartCard>

      <ChartCard
        width='half'
      >
        <ChartCard.Header
          title={t('WageQualityPage.genderEthnicityGraph.title')}
          subtitle={t('filtersApplied', { filters: appliedFilters })}
          isExpandable={false}
        />
        <GenderEthnicityChart
          height={545}
          data={genderEthnicityChartData}
          datasetConfig={'wageQualityAdjustment'}
        />
        <ChartCard.Legend datasets={GENDER_ETHNICITY_CHART_LEGEND_KEYS} shouldShowDefinition={false} />
      </ChartCard>
      <ChartCard
        width='half'
      >
        <ChartCard.Header
          title={t('WageQualityPage.genderEthnicityEmpowermentBreakdown.title')}
          subtitle={t('filtersApplied', { filters: appliedFilters })}
          isExpandable={false}
        />
        <div className={styles.genderGrouping}>
          <Text
            type="p"
            size='lg'
            color='grey-2'
            weight='bold'
            align='center'
          >Male</Text>
          <ProportionalChart
            height={225}
            {...maleEmpowermentChart}
          />
        </div>
        <div className={styles.genderGrouping}>
          <Text
            type="p"
            size='lg'
            color='grey-2'
            weight='bold'
            align='center'
          >Female</Text>
          <ProportionalChart
            height={225}
            {...femaleEmpowermentChart}
          />
        </div>
        <ChartCard.Legend datasets={femaleEmpowermentChart.datasetConfig} />
      </ChartCard>
    </Page>
  );
};

export default WageQualityPage;