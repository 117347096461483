import { ChartOptions, ChartType } from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DATA_COLORS } from '../../lib/constants';
import { DataFrame, DataSetRecord } from '../data_frame/DataFrame';
import { DATA_COLOR } from './ChartTooltip/ChartTooltip.types';
import { MAX_BAR_WIDTH_IN_PIXELS, MAX_LABEL_LENGTH, MAX_SUPPLIER_ON_CHART } from './Chart.constants';
import { ChartDataset, DatasetConfig } from './Chart.types';

export const buildChartDatasets = <T extends DataSetRecord>( dataConfig: DatasetConfig<T>[], datasets: T[], type: 'line' | 'bar' = 'bar') => {
  const dataFrame = new DataFrame(datasets);
  
  //Loop over dataConfig to decorate it with additional propoerties
  return dataConfig
    .map((config): ChartDataset => {
  
      //extract config values
      const {
        color,
        datasetLabel,
        datasetProperty,
        hidden,
      } = config;
        
      //Extract the dataset values. 
      //TODO: Added type check to ensure selected colu
      const data = dataFrame.col(datasetProperty) as number[]; //required coercion because col can return string and numbers 
      const colorHex = DATA_COLORS[color] as DATA_COLOR; //TODO: fix typings and remove as coerscion
  
      if (type === 'line') {
        return {
          label: datasetLabel,
          data,
          line: 1,
          borderColor: colorHex,
          backgroundColor: colorHex,
          hidden,
        };
      } else {
        return {
          label: datasetLabel,
          data,
          barPercentage: 0.97,
          categoryPercentage: 1,
          maxBarThickness: MAX_BAR_WIDTH_IN_PIXELS,
          backgroundColor: colorHex,
          hidden,
        };
      }
    });
  
};

export const getDatasetPropertiesFromConfig = <T>(datasetConfig: DatasetConfig<T>[]):(keyof T)[] => {
  return datasetConfig.map((config)=>config.datasetProperty);
};

/**
 * Function to shorten a number into shorthand format like '20K'
 * @param numberToShorten Number
 * @returns sting
 */
export const shortenNumber = (numberToShorten: number): string => {
  // Check if the number is in the thousands range
  if (numberToShorten >= 1000 && numberToShorten < 3000) {
    // Convert the number to 'K' format (dividing by 1000) and round to 1 decimal place
    return (numberToShorten / 1000).toFixed(1) + 'K';
  } else if (numberToShorten >= 3000 && numberToShorten < 1000000) {
    // Convert the number to 'K' format (dividing by 1000) and round to 1 decimal place
    return (numberToShorten / 1000).toFixed(0) + 'K';
  } else if (numberToShorten >= 1000000 && numberToShorten < 1000000000) {
    // Convert the number to 'M' format (dividing by 1,000,000) and round to 1 decimal place
    return (numberToShorten / 1000000).toFixed(0) + 'M';
  } else if (numberToShorten >= 1000000000 && numberToShorten < 1000000000000) {
    // Convert the number to 'B' format (dividing by 1,000,000,000) and round to 1 decimal place
    return (numberToShorten / 1000000000).toFixed(0) + 'B';
  } else {
    // If the number is smaller than 1000 or larger than 1 trillion, return the original number as a string
    return numberToShorten.toString();
  }
};
/**
 * Function to add square brackets for negative number like for -200, '[-200]'
 * @param value Number
 * @returns string
 */

export const convertToSquareBrackets = (value: number): string => {
  // If the number is negative, convert it to its absolute value and enclose in square brackets
  if (value < 0) {
    return '[' + shortenNumber(Math.abs(value)) + ']';
  } else {
    // If the number is positive or zero, return it as is
    return shortenNumber(value);
  }
};
/**
 * Function to add square brackets for negative number like for -200, '[-200]'
 * @param value Number
 * @returns string
 */

export const convertToRoundBrackets = (value: number): string => {
  // If the number is negative, convert it to its absolute value and enclose in round brackets
  if (value < 0) {
    return '(' + shortenNumber(Math.abs(value)) + ')';
  } else {
    // If the number is positive or zero, return it as is
    return shortenNumber(value);
  }
};

export const formatValue = (value: number): string => {
  return convertToRoundBrackets(value);
};

export const buildPlugins = (isAnnotated: boolean): any[] => {
  const plugins: any = [];
  if (isAnnotated) {
    plugins.push(ChartDataLabels);
  }
  return plugins;
};
const buildAvgLine = (avg: number, avgLineEnterHandler?, avgLineExitHandler?): AnnotationOptions => {
  return {
    type: 'line',
    scaleID: 'x',
    value: avg,
    borderColor: '#002664',
    // borderWidth: 10,
    borderDash: [6, 6],
    borderDashOffset: 0,
    label: {
      backgroundColor: '#002664',
      content: convertToSquareBrackets(avg),
      position: 'center',
      display: false,
    },
    enter: avgLineEnterHandler,
    leave: avgLineExitHandler,
  };
};

export const buildOptions = (
  alignment,
  orientation: string,
  displayXAxis: boolean,
  displayXAxisGrid: boolean,
  displayYAxisGrid: boolean,
  avg: number | undefined,
  stacked: boolean,
  minX: number | undefined,
  maxX: number | undefined,
  annotationFormatter: ((value, ctx) => string) | undefined,
  externalFunction: (context, chart) => void,
  avgLineEnterHandler: () => void,
  avgLineExitHandler: () => void,
  type: ChartType,
  labels: string[],
  printMode: boolean,
  isExpanded: boolean,
) => {

  //Define default scales
  const scales = {
    x: {
      stacked: stacked,
      reverse: false,
      border: {
        display: false,
      },
      // toggling x axis grid based on param
      grid: {
        display: displayXAxisGrid,
      },
      ticks: {
        callback: (tickValue) => {
          if (type === 'line') {
            // for line charts showing ticks from labels for example years in YoY charts
            const numberTickValue = Number(tickValue);
            return labels[numberTickValue];
          } else {
            // for bar charts formating numbers
            const numberTickValue = Number(tickValue);
            return formatValue(numberTickValue);
          }
        },
        font: {
          size: printMode ? 5.1 : 12,
        },
        color: '#333333',
        // toggling x axis based on param
        display: displayXAxis,
      },
    },
    y: {
      stacked: stacked,
      // positioning y axis bases alignment of the chart (vartical or horizontal)
      position: alignment,
      border: {
        display: false,
      },
      ticks: {
        display: type === 'line'
          || labels.length <= MAX_SUPPLIER_ON_CHART // hiding labels in y axis when there more than limited labels
          || isExpanded, // showing all labels when chart is expanded
        font: {
          size: printMode ? 5.1 : 12,
        },
        callback: (tickValue) => {
          const label = labels[Number(tickValue)];
          if (type === 'bar') {
            // for bar charts, truncating label if label is too long
            if (typeof label === 'string' && label.length > MAX_LABEL_LENGTH) {
              return `${label.substring(0, MAX_LABEL_LENGTH)}...`;
            }
          } else if (type === 'line') {
            // for line charts, formating numbers
            const numberTickValue = Number(tickValue);
            return formatValue(numberTickValue);
          }
          return labels[tickValue];
        },
      },
      // toggling y axis grid based on param
      grid: {
        display: displayYAxisGrid,
      },
    },
  };

  const chartPlugins = {
    //remove default chart legends
    legend: {
      display: false,
    },
    //remove default chart titles
    title: {
      display: false,
    },
    //placeholder for annotations
    annotation: {},
    tooltip: {},

    //set font styles for datalables overylayed on the bar
    datalabels: {
      color: '#ffffff',
      font: {
        family: 'Lato',
        weight: 600,
        size: printMode ? 5.1 : 12,
      },

      formatter: annotationFormatter,
    },
  };

  //Draw avg line if average value is passed in
  if (avg !== undefined) {
    const averageLinePlugin: AnnotationOptions = buildAvgLine(
      avg,
      { enter: avgLineEnterHandler, leave: avgLineExitHandler },
    );
    chartPlugins.annotation = {
      annotations: [
        averageLinePlugin,
      ],
    };
  }

  // configuring tooltip behaviour to use external tooltip
  chartPlugins.tooltip = {
    enabled: false,
    external: externalFunction,
  };

  // combining all chart options
  const options: ChartOptions<'bar' | 'line'> = {
    plugins: chartPlugins,
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 4,
    scales,
  };

  //Changes chart orientation
  if (orientation === 'horizontal') {
    options.indexAxis = 'y' as const;
  }
  //Set minX value
  if (minX !== undefined) {
    const x = scales.x;
    /* eslint-disable-next-line*/
    x['min'] = minX;
  }
  //Set maxX value
  if (maxX !== undefined) {
    const x = scales.x;
    /* eslint-disable-next-line*/
    x['max'] = maxX;
  }

  // Show X-axis labels for line chart
  if (type === 'line') {
    scales.x.ticks.display = true;
  }

  return options;
};

export const extractDataset = <T>(records: T[], property: keyof T, trasformer?: (record: T, property: keyof T) => number, roundOff = true) => {
  if (!records) return [];
  return records.map((record) => {
    if (trasformer) {
      return trasformer(record, property);
    } else {
      const propertyValue = Number(record[property]);
      return roundOff ? Math.round(propertyValue) : propertyValue;
    }

  });
};

export const getDatasetAvg = (dataset: number[], roundOff = true): number => {
  const sumOfDataset = dataset.reduce((accumulatedValue, currentValue) => accumulatedValue + currentValue, 0);
  const average = sumOfDataset / dataset.length;
  return roundOff ? Math.round(average) : average;
};
export const getDatasetMin = (dataset: number[]): number => {
  const minOfDataset = dataset.reduce((accumulatedValue, currentValue) => (
    accumulatedValue < currentValue ? accumulatedValue : currentValue
  ), 0);
  return minOfDataset;
};
export const getDatasetMax = (dataset: number[]): number => {
  const minOfDataset = dataset.reduce((accumulatedValue, currentValue) => (
    accumulatedValue > currentValue ? accumulatedValue : currentValue
  ), 0);
  return minOfDataset;
};


export const sliceDataset = (datasets: ChartDataset[], index) => {
  return datasets.map(ds => ds.data[index]);
};

export const getDatasetSum = (dataset: number[]): number => {
  const sumOfDataset = dataset.reduce((accumulatedValue, currentValue) => accumulatedValue + currentValue, 0);
  return sumOfDataset;
};

export const calculateChartAverage = (datasets: ChartDataset[], stacked: boolean) => {
  const visibleDatasets = datasets.filter((dataset) => !dataset.hidden);
  if (!stacked) {
    return getDatasetAvg(visibleDatasets.flatMap((visibleDataset) => visibleDataset.data));
  }

  const datasetAvgs = visibleDatasets.map((visibleDataset) => {
    return getDatasetAvg(visibleDataset.data);
  });
  return getDatasetSum(datasetAvgs);
};


  