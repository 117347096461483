export const glossaryDefs = {
  wageQuality: [
    {
      'title': 'Wage Quality Adjustment',
      'desc': 'The sum of living wage, wage equity and income satiation adjustments',
    },
    {
      'title': 'Living Wage Adjustment',
      'desc': 'Assesses whether employees are compensated at a level that meets living wage standards',
    },
    {
      'title': 'Income Satiation Adjustment',
      'desc': 'Assesses whether wages paid are in excess of what is needed for happiness',
    },
    {
      'title': 'Wage Equity Adjustment',
      'desc': 'Assesses if wages are equitable within job types and seniority levels such that equal pay is earned for equal work.',
    },
    {
      'title': 'Wage Quality Adjustment Intensity',
      'desc': 'Wage quality adjustment on a per employee basis.',
    },
    {
      'title': 'Employee Empowerment Bands',
      'desc': 'Bands that reflect workers earnings as a percentage of living wage (MIT) and highlights where well-being may be at risk to empowered and positively impacted.',
    },
  ],
  empowerment: [
    {
      'title': 'Reliant',
      'desc': 'Individuals earning under 50% of living wage as defined by MIT; reliant on external sources to meet basic needs',
    },
    {
      'title': 'Mostly Reliant',
      'desc': 'Individuals between 50-75% of a living wage as defined by MIT; may be reliant on external sources to meet basic needs',
    },
    {
      'title': 'Somewhat Reliant',
      'desc': 'Individuals earning between 75-90% of living wage as defined by MIT',
    },
    {
      'title': 'Self Reliant',
      'desc': 'Individuals earning between 90-100% of living wage as defined by MIT; able to meet basic needs from wages',
    },
    {
      'title': 'Empowered',
      'desc': 'Individuals earning 100%+ of living wage where basic needs are met and a typical experience is financially empowering',
    },
  ],
  diversity: [
    {
      'title': 'Diversity Adjustment',
      'desc': 'Determines the negative cost to the local labor community based on whether the organization’s workforce is representative of the demographics of the local population. The value may be zero or negative for this adjustment',
    },
    {
      'title': 'Diversity Adjustment Intensity',
      'desc': 'Diversity adjustment normalized to a per employee basis.',
    },
    {
      'title': 'Representation Gap',
      'desc': 'Percentage difference, for each demographic group, between the local population and firm workforce.',
    },
    {
      'title': 'Workforce Gap',
      'desc': 'The number of firm employees multiplied by the representation gap.',
    },
  ],
  opportunity: [
    {
      'title': 'Opportunity Adjustment',
      'desc': 'Determines the negative cost to the workforce based on whether the organization’s Job categories and seniority levels are representative of the firm’s demographics.',
    },
    {
      'title': 'Opportunity by Job Category',
      'desc': 'Determines the negative cost to the workforce based on whether the organization’s Job categories are representative of the firm’s demographics.',
    },
    {
      'title': 'Opportunity by Seniority',
      'desc': 'Determines the negative cost to the workforce based on whether the organization’s seniority levels are representative of the firm’s demographics.',
    },
    {
      'title': 'Opportunity Adjustment Intensity',
      'desc': 'Opportunity adjustment on a per employee basis.',
    },
    {
      'title': 'Workforce Gap in Seniority',
      'desc': 'Whole number gap or difference between expected vs actual firm representation in each seniority level for each demographic group.',
    },
    {
      'title': 'Workforce Gap in Job Category',
      'desc': 'Whole number gap or difference between expected vs actual firm representation in each job category for each demographic group.',
    },
  ],
  jobCreation: [
    {
      'title': 'Job Creation Adjustment',
      'desc': 'Determines the positive impact on the local labor community based on job creation.',
    },
    {
      'title': 'Job Creation Adjustment Intensity',
      'desc': 'Job creation adjustment on a per employee basis.',
    },
    {
      'title': 'Hypothesized Unemployment rate',
      'desc': 'Expected unemployment rate without job creation from the firm.',
    },
  ],
  overview: [
    {
      'title': 'Impact',
      'desc': 'A change in one or more dimensions of people’s well-being directly or through a change in the condition of the natural environment.',
    },
    {
      'title': 'Impact accounts',
      'desc': 'A set of accounts that contain the material positive and negative impacts of an entity valued in monetary terms.',
    },
    {
      'title': 'Impact drivers',
      'desc': 'Refer to the sequence of an entity’s inputs and outputs that may have positive and/or negative impacts on people’s well-being.',
    },
    {
      'title': 'Impact accounting',
      'desc': 'The system for measuring and valuing the impacts of corporate entities and generating impact information to inform decisions related to sustainability performance.',
    },
    {
      'title': 'Impact information',
      'desc': 'Impact information is derived from impact accounts and informs decision-making related to the sustainability performance of an entity.',
    },
    {
      'title': 'Dimensions in Employment Impact Accounting',
      'desc': 'Wage Quality, Diversity, Opportunity, Job Creation (Location)',
    },
    {
      'title': 'Employment Impact',
      'desc': 'Total Wages Paid by a firm,  plus or minus adjustments for diversity, opportunity, wage quality and local job creation.',
    },
    {
      'title': 'Employment Impact Intensity',
      'desc': 'The sum of all adjustment values divided by the number of affected employees. This figure can be described as “impact per employee.”',
    },
    {
      'title': 'Monetization',
      'desc': 'Assigning a dollar value to impact values. This helps us to contextualize impact figures.',
    },
  ],
};