import React from 'react';
import cx from 'classnames';

import styles from './SkeletonRectangle.module.scss';
import { SkeletonRectangleProps } from './SkeletonRectangle.types';

const SkeletonRectangle: React.FC<SkeletonRectangleProps> = (props) => {
  return (
    <div className = {cx(styles.SkeletonRectangle)}>
    </div>
  );

};
 
export default SkeletonRectangle;