import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import Button from '../Button';
import Icon from '../Icon';

import styles from './InputField.module.scss';
import useInputFieldPresenter from './InputField.presenter';
import { InputFieldProps } from './InputField.types';

const InputField: React.FC<InputFieldProps> = (props) => {
  const {
    ref,
    state,
    disabled,
    className,
    classes,
    maxLength,
    onBlur,
    onClick,
    onKeyDown,
    onTextChanged,
    onValidateText,
    preProcessTextInput,
    textPlaceholder,
    textValue,
    type = 'text',
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const inputFieldRef = ref || inputRef;

  const {
    inputType = 'text',
    passwordIconAsset,
    buttonOnClick,
  } = useInputFieldPresenter(props);

  let imageView;
  let buttonView;

  if (type === 'password') {
    buttonView = (
      <Button className={styles.button} onClick={buttonOnClick} style='default'>
        <Icon
          className={styles.icon}
          asset={passwordIconAsset}
          colour='NeutralSubdued'
          state='Default'
        />
      </Button>
    );
  }

  if (type === 'phone') {
    imageView = (
      <Icon className={styles.phoneIcon} asset='USFlag' colour='NeutralDefault' state='Default' />
    );
  }

  const [inputValue, setInputValue] = useState<string | number>('');


  useEffect(() => {
    if (inputType === 'number') {
      setInputValue(`${textValue || 0}`);
    } else {
      setInputValue(textValue || '');
    }
  }, [textValue]);

  const onInputChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const validEntry = onValidateText ? onValidateText(event.target.value) : true;
    if (validEntry) {
      if (preProcessTextInput && inputFieldRef.current) {
        inputFieldRef.current.value = preProcessTextInput(inputFieldRef.current.value);
      }
      const updatedValue = inputFieldRef.current?.value || '';
      setInputValue(updatedValue);
      if (onTextChanged) {
        onTextChanged(updatedValue);
      }
    }
  };

  return (
    <div className = {cx(styles.InputField, className, styles[type], styles[state || ''], {
      [styles.disabled]: disabled,
    })}>
      {imageView}
      <input
        ref={inputFieldRef}
        className={cx(styles.input, classes?.input)}
        placeholder={textPlaceholder}
        value={inputValue}
        onChange={onInputChanged}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onClick={onClick}
        disabled={disabled}
        type={inputType}
        maxLength={maxLength}/>
      {buttonView}
    </div>
  );

};
 
export default InputField;