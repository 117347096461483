import { Summary, SummaryKeys } from '../../../lib/api/resources/reports';
import { calcIntensity, round } from '../../../lib/utils';
import { CalculatedField } from '../../../modules/data_frame/DataFrame';
import { View } from '../../organisms/FilterModal/FilterModal.types';



export const getSelectedFieldList = (view: View) => {
  const labelKeys: SummaryKeys[] = view === 'Portfolio'
    ? ['supplier'] : ['location', 'supplier'];

  const labelColumn: CalculatedField<Summary> = {
    as: 'supplierName',
    calculator: (summaryProps) => {
      return labelKeys.map((summaryKey) => summaryProps.row[summaryKey]).join(' | ');
    },
  };

  const jobCreationIntensityColumn: CalculatedField<Summary> = {
    as: 'jobCreationAdjustment',
    calculator: (summaryProps) => {
      const intensity = calcIntensity(summaryProps.row, 'jobCreationAdjustment');
      return round(intensity, true);
    },
  };

  const selectFieldList: (SummaryKeys | CalculatedField<Summary>)[] = [
    labelColumn, jobCreationIntensityColumn, 'location', 'unemploymentRate', 'hypoUnemploymentRate',
  ];

  return selectFieldList;
};
