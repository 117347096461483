import React, { useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import Button from '../../shared_components/atoms/Button';
import Icon from '../../shared_components/atoms/Icon';
import Text from '../../shared_components/atoms/Text/Text';
import { useFilterModal } from '../../shared_components/organisms/FilterModal/FilterModal.context';
import DiversityPrintPage from '../../shared_components/pages/DiversityPage/DiversityPrintPage';
import JobCreationPrintPage from '../../shared_components/pages/JobCreationPage/JobCreationPrintPage';
import OpportunityPrintPage from '../../shared_components/pages/OpportunityPage/OpportunityPrintPage';
import OverviewPrintPage from '../../shared_components/pages/OverviewPage/OverviewPrintPage';
import WageQualityPrintPage from '../../shared_components/pages/WageQualityPage/WageQualityPrintPage';
import { PageContext } from '../page/PageContext';
import { UserContext } from '../user/UserContext';
import { EndPage } from './EndPage';
import { NotesPage } from './NotesPage';
import { TitlePage } from './TitlePage';

export type PrintPDFButtonProps = {
  className?: string;
};

export const PrintPDFButton: React.FC<PrintPDFButtonProps> = (props) => {
  const { t } = useTranslation();

  const contentRef = useRef<HTMLDivElement>(null);
  const [renderPDF, setRenderPDF] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    pageStyle: `
    @media print {
      html, body {
        height: 100vh; /* Use 100% here to support printing more than a single page*/
        margin: 0 !important;
        padding: 0 !important;
        -webkit-print-color-adjust: exact; 
        color-adjust: exact;
        background-color: red; // TODO remove
        // overflow: hidden;
      }
    }
    `,
  });

  const preparePrint = () => {
    setRenderPDF(true);
    setTimeout(() => {
      handlePrint();
    }, 250);
  };

  const { user } = useContext(UserContext);
  const { filterState, pageView } = useFilterModal();
  const summaryTables = useContext(PageContext).chartsData;
  
  const pdfContent = useMemo(() => {
    if (renderPDF) {
      return (
        <div style={{ 
          visibility: 'hidden', 
          position: 'fixed', 
          top: 0, 
          left: 0, 
        }}>
          <div 
            ref={contentRef}
            style={{
              width: '100%',
            }}>
            <TitlePage />
            <OverviewPrintPage />
            <WageQualityPrintPage />
            <DiversityPrintPage />
            <OpportunityPrintPage />
            <JobCreationPrintPage />
            <NotesPage />
            <EndPage />
          </div>
        </div> 
      );
    }
    return undefined;
  }, [renderPDF, summaryTables, filterState, pageView, user]);

  const { className } = props;
  return (
    <>
      {pdfContent}
      <Button 
        className={className}
        onClick={preparePrint}
        style='dropdown'
      >
        <Icon asset='Print' colour='NeutralDefault' state='Default' />
        <Text
          size='sm'
          color='grey-2'
          type='p'
          weight='regular'
        >
          {t('topNav.printReport')}
        </Text>
      </Button>
    </>
    
  );
};

export default PrintPDFButton;
