import { BFF_URL } from '../../config';
import axiosInstance from '../axios';
import { AccountResponse } from './auth';

export type SignInPayload = {
  email: string;
  password: string;
};
  
export type UpdatePasswordPayload = {
  id: number;
  password: string;
  token: string;
};
  
export type EmailState = {
  email: string;
};

export const updatePassword = async (payload: UpdatePasswordPayload): Promise<void> => {
  await axiosInstance.patch(`${BFF_URL}/auth/updatePassword`, payload);
};

export const forgotPassword = async (email: string): Promise<void> => {
  await axiosInstance.post(`${BFF_URL}/auth/forgotPassword`, { email: email.trim() });
};

export const resendVerificationEmail = async (email: string): Promise<void> => {
  await axiosInstance.post(`${BFF_URL}/auth/resendVerifyEmail`, { email: email.trim() });
};

export const signIn = async (payload: SignInPayload): Promise<AccountResponse> => {
  const { data } = await axiosInstance.post<AccountResponse>(`${BFF_URL}/auth/signIn`, {
    ...payload,
    email: payload.email.trim(),
  });
  return data;
};