import React, { createContext, useContext, useState, FC, useMemo, useEffect } from 'react';
import { DataEntry } from '../../../lib/api/types';
import { Sectors, getSectors } from '../../../lib/api/resources/sectors';
import { Region, getRegions } from '../../../lib/api/resources/regions';
import { Supplier, getSuppliers } from '../../../lib/api/resources/suppliers';
import { SelectedFilterState, View } from './FilterModal.types';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../modules/auth';
import { getPageView, getLocallyStoredFilters, reconnectSectors } from './FilterModal.utils';


type FilterModalContextType = {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  sectorsData: DataEntry<Sectors>[];
  regionsData: DataEntry<Region>[];
  rawSuppliersData: DataEntry<Supplier>[];
  filterState: SelectedFilterState;
  updateFilterState: (updatedFilter: SelectedFilterState) => void;
  pageView: View,
  appliedFilters?: string;
  noOfSuppliers: number;
  formattedFilterString: string;
  isFilterDataLoading: boolean;
  updateIsFilterDataLoading: (isLoading: boolean) => void;
};

export const initialSelectedFilterState: SelectedFilterState = {
  selectedSuppliers: [],
  selectedSectors: [],
  selectedRegions: [],
  year: 2022,
};

export const initialFilterContext: FilterModalContextType = {
  isOpen: false,
  openModal: () => { },
  closeModal: () => { },
  sectorsData: [],
  regionsData: [],
  rawSuppliersData: [],
  filterState: initialSelectedFilterState,
  updateFilterState: () => { },
  pageView: 'Empty',
  noOfSuppliers: 0,
  formattedFilterString: '',
  isFilterDataLoading: false,
  updateIsFilterDataLoading: () => { },
};

const FilterModalContext = createContext<FilterModalContextType | undefined>(initialFilterContext);

export const useFilterModal = () => {
  const context = useContext(FilterModalContext);
  if (context === undefined) {
    throw new Error('useFilterModal must be used within a ModalProvider');
  }
  return context;
};

type FilterModalProviderProps = {
  children?: React.ReactNode;
};

export const FilterModalProvider: FC<FilterModalProviderProps> = ({ children }) => {
  const locallyStoredFilters = getLocallyStoredFilters();
  const { t } = useTranslation();
  const { isAdmin, account } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);

  const [sectorsData, setSectorsData] = useState<DataEntry<Sectors>[]>([]);
  const [regionsData, setRegionsData] = useState<DataEntry<Region>[]>([]);
  const [rawSuppliersData, setRawSuppliersData] = useState<DataEntry<Supplier>[]>([]);
  const [filterState, setFilterState] = useState<SelectedFilterState>(initialSelectedFilterState);
  const [isFilterDataLoading, setIsFilterDataLoading] = useState<boolean>(false);

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => setIsOpen(false);

  const updateFilterState = (updatedFilter: SelectedFilterState) => {
    setFilterState(updatedFilter);
    localStorage.setItem('filterState', JSON.stringify({ ...updatedFilter, userId: account?.uuid }));
  };

  const pageView = useMemo((): View => {
    return getPageView(isAdmin, filterState.selectedSuppliers);
  }, [isAdmin, filterState.selectedSuppliers]);

  const noOfSuppliers = useMemo(() => {
    return filterState.selectedSuppliers.length;
  }, [filterState.selectedSuppliers]);

  const appliedFilters = useMemo(() => {
    const { selectedSectors, selectedRegions, year } = filterState;
    if (noOfSuppliers === 0) {
      return '';
    }
    let filterString = t('filterModal.noOfSuppliers', { count: noOfSuppliers });

    const appliedSectorFilter = selectedSectors.map(selectedSector => selectedSector.name);
    filterString = selectedSectors.length ? `${filterString}, ${appliedSectorFilter.join(', ')}` : filterString;

    // TODO: ASK for Industry Filter String;

    const appliedRegionFilter = selectedRegions.map((region) => region.name);
    filterString = appliedRegionFilter.length ? `${filterString}, ${appliedRegionFilter.join(', ')}` : filterString;

    return `${filterString}, ${year}`;
  }, [filterState, noOfSuppliers]);

  const formattedFilterString = useMemo(
    () => {
      return (t('filterApplied', {
        appliedFilters: appliedFilters,
      }));
    }, [appliedFilters]);


  useEffect(() => {
    if (locallyStoredFilters) {
      setFilterState(locallyStoredFilters);
    }
  }, []);

  useEffect(() => {
    const fetchInitialFilterListData = async () => {
      try {
        setIsFilterDataLoading(true);
        const [sectorsResponse, regionResponse, supplierResponse] = await Promise.all([
          getSectors(),
          getRegions(),
          getSuppliers(),
        ]);
        setRegionsData(regionResponse);
        setSectorsData(sectorsResponse);
        setRawSuppliersData(reconnectSectors(supplierResponse, sectorsResponse));
      } catch (error) {
        console.error('Error fetching filter data:', error);
      } finally {
        setIsFilterDataLoading(false);
        if (!locallyStoredFilters) {
          openModal();
        }
      }
    };

    if (account) {
      fetchInitialFilterListData().catch(error => {
        console.error('Error fetching filter data:', error);
      });
    }
  }, [account]);


  return (
    <FilterModalContext.Provider value={{
      isOpen,
      openModal,
      closeModal,
      sectorsData,
      regionsData,
      rawSuppliersData,
      filterState,
      updateFilterState,
      pageView,
      noOfSuppliers,
      appliedFilters,
      formattedFilterString,
      isFilterDataLoading,
      updateIsFilterDataLoading: setIsFilterDataLoading,
    }}>
      {children}
    </FilterModalContext.Provider>
  );

};
