import React from 'react';
import cx from 'classnames';

import styles from './AuthenticationFooter.module.scss';
import { AuthenticationFooterProps } from './AuthenticationFooter.types';
import Text from '../../atoms/Text';
import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from '../../../lib/constants';
import { useTranslation } from 'react-i18next';

const AuthenticationFooter: React.FC<AuthenticationFooterProps> = (props) => {
  const {
    className,
  } = props;

  const { t } = useTranslation();

  return (
    <div className = {cx(styles.AuthenticationFooter, className)}>
      <a href={TERMS_OF_USE_LINK} target="_blank" rel="noreferrer">
        <Text
          size='sm'
          weight='regular'
          color='grey-4'
          type='p'
          underline={true}
        >
          {t('TermAndPrivacyBanner.termsButtonText')}
        </Text>
      </a>
      <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
        <Text
          size='sm'
          weight='regular'
          color='grey-4'
          type='p'
          underline={true}
        >
          {t('TermAndPrivacyBanner.privacyButtonText')}
        </Text>
      </a>
    </div>
  );

};
 
export default AuthenticationFooter;