import { useEffect, useMemo, useState } from 'react';
import {  formatAmount } from '../../../lib/utils';
import { DataFrame } from '../../../modules/data_frame/DataFrame';
import { extractDataset, getDatasetAvg, getDatasetSum } from '../../../modules/Chart/Chart.utils';
import { useFilterModal } from '../../organisms/FilterModal/FilterModal.context';
import { MapData } from '../../organisms/MapChart/MapChart.types';
import { JobMapGraphCardProps } from './JobMapGraphCard.types';
import { getSelectedFieldList } from './JobMapGraph.utils';

const usePresenter = (props: JobMapGraphCardProps) => {
  const { filterState: { year }, pageView } = useFilterModal();

  const { data, selectedState: initialSelectedState } = props;
  const [selectedState, setSelectedState] = useState<string | undefined>(initialSelectedState);

  const selectFieldList = getSelectedFieldList(pageView);

  const yearFilteredDataFrame = useMemo(() => {
    return new DataFrame(data).query({
      where: [{
        comparator: (summaryProps) => summaryProps.row.year === year,
      }],
      select: selectFieldList,
      orderBy: ['jobCreationAdjustment'],
      orderDirection: 'desc',
      roundValues: false,
    });
  }, [data, year]);


  // to updated selected state to initialSelectedState when data is changed
  useEffect(() => {
    setSelectedState(initialSelectedState);
  }, [data, initialSelectedState]);

  // filtered summary based on selected state
  // queried on already filtered dataframe for year
  const stateSummary = useMemo(() => {
    return yearFilteredDataFrame.query({
      where: [{
        comparator: (summaryProps) => summaryProps.row.location === selectedState,
      }],
    }).getDataset();
  }, [data, selectedState]);

  // Unemployment rate for state is same for all supplier rows
  // so we are getting it from first supplier summary row
  const selectedStateUnemploymentRate = useMemo(() => {
    return stateSummary.length ? Number(stateSummary[0].unemploymentRate).toFixed(2) : undefined;
  }, [stateSummary]);

  // hypoUnemploymentRate is different for each supplier
  // so we need to calculate the average for each selected supplier's summary rows 
  const selectedStateHypoUnemploymentRate = useMemo(() => {
    if (stateSummary.length) {
      const hypoUnemploymentRateDataset = extractDataset(stateSummary, 'hypoUnemploymentRate', undefined, false);
      const hypoUnemploymentRateDatasetAvg = getDatasetAvg(hypoUnemploymentRateDataset, false);
      return hypoUnemploymentRateDatasetAvg.toFixed(5);
    }
  }, [stateSummary]);

  // sum of all the supplier's job creation adj intensity
  const selectedStateJobCreationAdjIntensity = useMemo(() => {
    return getDatasetSum(
      extractDataset(stateSummary, 'jobCreationAdjustment'),
    );
  }, [stateSummary, selectedState]);

  // data for all state for selected supplier to draw map chart
  const allStateData = yearFilteredDataFrame.getDataset() as MapData[];

  return {
    pageView,
    supplierList: {
      supplierInfoItems: stateSummary.map((supplier) => ({
        price: formatAmount(supplier.jobCreationAdjustment),
        supplierName: String(supplier.supplierName),
      })),
      showEmptyState: !selectedState,
    },
    mapChart: {
      updateState: setSelectedState,
      selectedState,
      data: allStateData,
    },
    cardHeader: {
      selectedState,
      intensity: selectedStateJobCreationAdjIntensity,
      totalSuppliers: stateSummary.length,
    },
    rateContent: {
      selectedStateHypoUnemploymentRate,
      selectedStateUnemploymentRate,
      showEmptyState: !selectedState,
    },
  };
};

export default usePresenter;
