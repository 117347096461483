import { useState } from 'react';
import { GlossaryProps } from './Glossary';
import { glossaryDefs } from './Glossary.constant';
import { GlossaryTerm } from './Glossary.types';

type UseGlossaryPresenterReturnProps = {
  handleExpandToggle: ()=>void;
  isGlossaryVisible: boolean;
  definitions: GlossaryTerm[];
};
const useGlossaryPresenter = ( props:GlossaryProps ):UseGlossaryPresenterReturnProps => {
  const {
    page,
  } = props;

  //Control the expanding and collapsing of content area
  const [isGlossaryVisible, setGlossaryVisible] = useState(false);
  const handleExpandToggle = () => {
    setGlossaryVisible(!isGlossaryVisible);
  };
  //Select the correct set of defintions for the page
  //TODO: Implement translations
  const definitions = glossaryDefs[page];
  return {
    handleExpandToggle,
    isGlossaryVisible,
    definitions,
  };
};

export default useGlossaryPresenter;