import { EmployeeSummary, EmployeeSummaryKeys } from '../../../lib/api/resources/reports';
import { CalculatedField, DataFrame } from '../../../modules/data_frame/DataFrame';
import { ETHNICITY_NAME_MAPPING } from '../GenderTable/GenderTable.constants';
import { DataRow } from './types';

export const buildWorkforceGapDataForEthnicities = (data: EmployeeSummary[], year: number) => {
  const dataFields: (EmployeeSummaryKeys | CalculatedField<EmployeeSummary>)[] = [
    'gender', 'expectedCount', 'currentCount', 'workforceGap', 'diversityAdjustment',
    {
      as: 'ethnicity',
      calculator: (dataRow) => ETHNICITY_NAME_MAPPING[dataRow.row.ethnicity],
    },
    {
      as: 'femaleCurrentCount',
      calculator: (dataRow) => {
        return dataRow.row.gender === 'female' ? dataRow.row.currentCount : 0;
      },
    },
    {
      as: 'femaleExpectedCount',
      calculator: (dataRow) => {
        return dataRow.row.gender === 'female' ? dataRow.row.expectedCount : 0;
      },
    },
    {
      as: 'femaleAdjustmentValue',
      calculator: (dataRow) => {
        return dataRow.row.gender === 'female' ? dataRow.row.diversityAdjustment : 0;
      },
    },
    {
      as: 'maleCurrentCount',
      calculator: (dataRow) => {
        return dataRow.row.gender === 'male' ? dataRow.row.currentCount : 0;
      },
    },
    {
      as: 'maleExpectedCount',
      calculator: (dataRow) => {
        return dataRow.row.gender === 'male' ? dataRow.row.expectedCount : 0;
      },
    },
    {
      as: 'maleAdjustmentValue',
      calculator: (dataRow) => {
        return dataRow.row.gender === 'male' ? dataRow.row.diversityAdjustment : 0;
      },
    },
  ];

  const workforceGapDataFrame = new DataFrame(data).query({
    where: [{
      comparator: (dataRow) => dataRow.row.year === year,
    }],
    select: dataFields,
    groupBy: ['gender', 'ethnicity'],
  }).query({
    groupBy: ['ethnicity'],
  });

  return workforceGapDataFrame.getDataset() as DataRow[];
};