import { useLayoutEffect, useState, useRef, RefObject, useCallback } from 'react';

export type MouseState = {
  //Cursor Position Relative to Viewport
  x: number;
  y: number;

  //Cursor Position Relative to Bounding Element(negative if above or left of element)
  elementX: number;
  elementY: number;

  //Bounding Element's Current Position
  elementPositionX: number;
  elementPositionY: number;
};
const useMouse = ( isSubscribed = true ):[MouseState, RefObject<Element>, ()=>void, ()=>void] => {
  const [state, setState] = useState({
    x: 0,
    y: 0,
    elementX: 0,
    elementY: 0,
    elementPositionX: 0,
    elementPositionY: 0,
  });

  //Get Element that will be used to relawtively calucalte positioning
  const mouseRef = useRef<Element>(null);

  //Recalculate positioning values and tring rerender
  const handleMouseMove = (event:MouseEvent) => {
    const newState:MouseState = {
      x: event.pageX,
      y: event.pageY,
      elementX: 0,
      elementY: 0,
      elementPositionX: 0,
      elementPositionY: 0,
    };
  
    if (mouseRef.current?.nodeType === Node.ELEMENT_NODE) {
      const { left, top } = mouseRef.current.getBoundingClientRect();
      const elementPositionX = left + window.scrollX;
      const elementPositionY = top + window.scrollY;
      const elementX = event.pageX - elementPositionX;
      const elementY = event.pageY - elementPositionY;
  
      newState.elementX = elementX;
      newState.elementY = elementY;
      newState.elementPositionX = elementPositionX;
      newState.elementPositionY = elementPositionY;

    }
  
    setState((s:MouseState) => {
      return {
        ...s,
        ...newState,
      };
    });
  };

  //Convience functions to temporarily disable the listners imperatively 
  const subscribe = useCallback(()=>{
    document.addEventListener('mousemove', handleMouseMove);
  }, []);
  const unsubscribe = useCallback(()=>{
    document.removeEventListener('mousemove', handleMouseMove);
  }, []);

  //Once Dom is ready, subscribe to mouse events
  useLayoutEffect(() => {
    if ( isSubscribed ) {
      subscribe();
    }
    return () => {
      unsubscribe();
    };
  }, []);

  return [state, mouseRef, subscribe, unsubscribe];
};

export default useMouse;



  
