import React from 'react';
import cx from 'classnames';

import LoaderImage from '../../../resources/images/bar-chart-animation.gif';

import styles from './LoaderModal.module.scss';
import ModalWrapper, { ExportedModalProps } from '../../../modules/common/ModalWrapper';

export type LoaderModalProps = {

};
const Loader: React.FC<LoaderModalProps> = () => {
  return (
    <div className={cx(styles.LoaderModal)}>
      <img src={LoaderImage} alt="loading..." className={styles.loader} tabIndex={-1} />
    </div>
  );

};

export type LoaderModalCombinedProps = ExportedModalProps<LoaderModalProps>;

const LoaderModal: React.FC<LoaderModalCombinedProps> = ({ onHide, show }) => {
  return (
    <ModalWrapper
      className={styles.LoaderModal}
      backDropClassName={styles.LoaderModalBackDrop}
      content={
        <Loader />
      }
      onHide={onHide}
      show={show}
      autoFocus={false}
      testId='LoaderModal'
    />
  );
};

export default LoaderModal;