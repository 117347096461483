import React from 'react';
import cx from 'classnames';

import styles from './InitialContainer.module.scss';
import { InitialContainerProps } from './InitialContainer.types';
import Text from '../Text';

const InitialContainer: React.FC<InitialContainerProps> = (props) => {
  const {
    initials,
  } = props;
  return (
    <div className = {cx(styles.InitialContainer)}>
      <Text
        type='p'
        size='sm'
        align='left'
        color='grey-2'
        weight='semi-bold'
      >
        {initials}
      </Text>
    </div>
  );

};
 
export default InitialContainer;