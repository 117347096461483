import React from 'react';
import { useTranslation } from 'react-i18next';

import IntensityChart from '../../../modules/Chart/IntensityChart';
import YoYChart from '../../../modules/Chart/YoYChart';
import Button from '../../atoms/Button';
import Card from '../../atoms/Card';
import Icon from '../../atoms/Icon';
import Page from '../../atoms/Page';
import Text from '../../atoms/Text';
import ChartCard from '../../molecules/ChartCard';
import DimensionHeader from '../../molecules/DimensionHeader';
import TotalEICard from '../../molecules/TotalEICard/TotalEICard';
import EITable from '../../organisms/EITable';
import GlossaryHeader from '../../organisms/GlossaryHeader';
import KeyTakeawaysCard from '../../organisms/KeyTakeawaysCard';

import styles from './OverviewPage.module.scss';
import useOverviewPagePresenter from './OverviewPage.presenter';
import { OverviewPageCombinedProps } from './OverviewPage.types';

const OverviewPage: React.FC<OverviewPageCombinedProps> = () => {
  const { t }  = useTranslation();

  const {
    view,
    appliedFilters,
    totalEIAmount,
    avgTotalEIIntensityAmount,
    keyTakeawaysCard,
    eITableProps,
    totalAdjustmentIntensityChart,
    totalEIIntesityChart,
    eIYoYChart,
    allAdjustmentsYoYChart,
  } = useOverviewPagePresenter();
  const {
    state, values,
  } = keyTakeawaysCard;

  const {
    legendDatasets: eITableLegendDataset,
    rows,
    thresholds,
  } = eITableProps;

  const eIIntensity = (
    <ChartCard
      width='offset'
    >
      <ChartCard.Header
        title={t('OverviewPage.eIGraphCard.title')}
        subtitle={t('filtersApplied', { filters: appliedFilters })}
        isExpandable={true}
      />
      <IntensityChart
        datasetConfig={totalEIIntesityChart.datasetConfig}
        alignment='left'
        data={totalEIIntesityChart.data}
        height={545}
      />
      <ChartCard.Legend datasets={totalEIIntesityChart.datasetConfig} />
    </ChartCard>
  );

  const totalEIAdjIntensity = (
    <ChartCard
      width='fill'
    >
      <ChartCard.Header
        title={t('OverviewPage.totalEIAdjustmentIntensityGraphCard.title')}
        subtitle={t('filtersApplied', { filters: appliedFilters })}
        isExpandable={true}
      />
      <IntensityChart
        datasetConfig={totalAdjustmentIntensityChart.adjustmentKeys}
        alignment='right'
        data={totalAdjustmentIntensityChart.data}
        height={545}
      />
      <ChartCard.Legend datasets={totalAdjustmentIntensityChart.adjustmentKeys} />
    </ChartCard>
  );

  const eIYoY = (
    <ChartCard
      width={view === 'Region' ? 'offset' : 'half'}>
      <ChartCard.Header
        title={view === 'Region'
          ? t('OverviewPage.eIIntensityYoYGraphCard.title')
          : t('OverviewPage.eIAggregateYoYGraphCard.title')}
        subtitle={t('filtersApplied', { filters: appliedFilters })}
        isExpandable={false}
      />
      <YoYChart
        height={545}
        data={eIYoYChart.data} 
        stacked={false}
        datasetConfig={eIYoYChart.datasetConfig}
        isIntensity={view === 'Region'}
      />
      <ChartCard.Legend datasets={eIYoYChart.datasetConfig} />
    </ChartCard>
  );

  const adjByDimensionYoY = (
    <ChartCard
      width={view === 'Region' ? 'fill' : 'half'}
    >
      <ChartCard.Header
        title={view === 'Region'
          ? t('OverviewPage.dimensionIntensityYoYGraphCard.title')
          : t('OverviewPage.dimensionAggregateYoYGraphCard.title')}
        subtitle={t('filtersApplied', { filters: appliedFilters })}
        isExpandable={false}
      />
      <YoYChart
        height={545}
        data={allAdjustmentsYoYChart.data} 
        stacked={false}
        datasetConfig={allAdjustmentsYoYChart.datasetConfig}
        isIntensity={view === 'Region'}
      />
      <ChartCard.Legend datasets={allAdjustmentsYoYChart.datasetConfig} />
    </ChartCard>
  );

  
    
  return (
    <Page>

      {/* PAGE HEADER */}
      {view === 'Company' || view === 'Region' ? <GlossaryHeader page={'overview'} /> : null}
      <DimensionHeader page={'overview'} />

      {/* EI CARDS */}
      <div className={styles.eICardContainer}>
        <Card className={styles.totalEICard}>
          <TotalEICard type='total' value={totalEIAmount} />
        </Card>

        <Card className={styles.totalEICard}>
          <TotalEICard type='average' value={avgTotalEIIntensityAmount} />
        </Card>
      </div>

      {/* EMPLOYMENT IMPACT TABLE */}
      <ChartCard>
        <ChartCard.Header
          title={t('OverviewPage.employmentImpactTable.title')}
          subtitle={t('filtersApplied', { filters: appliedFilters })}
        />
        <Button
          onClick={() => { }} // TODO: add link to knowledge base
          className={styles.viewIndustryAverageButton}
        >
          <Text
            color='grey-2'
            weight='regular'
            size='sm'
            type='p'
          >
            {t('OverviewPage.employmentImpactTable.viewIndustryAverage')}
          </Text>
          <Icon className={styles.icon} asset={'ExternalLink'} colour={'NeutralDefault'} state={'Default'} />
        </Button>
        <EITable rows={rows} thresholds={thresholds} className={styles.maxHeightTableContainer} />
        <ChartCard.Legend datasets={eITableLegendDataset} />
        <Text
          color='grey-4'
          size='sm'
          type='p'
          weight='regular'
        >
          {t('OverviewPage.employmentImpactTable.note')}
        </Text>
      </ChartCard>

      {/* KEY TAKEAWAYS */}
      <div style={{
        display: 'flex', flexDirection: 'column', gap: '1.5rem', width: '312px', flexShrink: 0,
      }}>
        <KeyTakeawaysCard
          page='overview'
          view={view}
          state={state}
          keyTakeawaysValues={values} />
      </div>

      {/* EI INTENSITY BAR GRAPH */}
      {view !== 'Region' ? eIIntensity : null}

      {/* TOTAL ADJUSTMENT INTENSITY BAR GRAPH */}
      {view !== 'Region' ? totalEIAdjIntensity : null}

      {/* EI YoY LINE CHART */}
      {eIYoY}

      {/* ADJUSTMENTS YoY LINE CHART */}
      {adjByDimensionYoY}
    </Page>
  );

};

export default OverviewPage;

