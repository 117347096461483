import { TFunction, useTranslation } from 'react-i18next';
import { KeyTakeawayProps } from './KeyTakeaway/KeyTakeaway';
import { KeyTakeawaysCardProps } from './KeyTakeawaysCard';
import { keyTakeawaysDefinitions } from './KeyTakeawaysCard.constant';

export type KeyTakeawaysCardPresenterReturnProps = {
  title?: string;
  keyTakeaways?: Pick<KeyTakeawayProps, 'title' | 'description'>[];
};

export const buildPageKeyTakeaways = (t: TFunction, page: string, view: string, values?: Record<string, Record<string, string | number | undefined>>) => {
  const KeyTakeaways = keyTakeawaysDefinitions[page][view] || [];

  if (KeyTakeaways.length) {
    // using flatMap to not show key takeaway which doesn't have values
    const keyTakeaways = KeyTakeaways.flatMap(KeyTakeaway => {
      if (!values || !values[KeyTakeaway]) return [];
      return [
        {
          title: t(`keyTakeaways.${page}.${view}.${KeyTakeaway}.title`),
          description: t(`keyTakeaways.${page}.${view}.${KeyTakeaway}.description`, {
            ...(values ? values[KeyTakeaway] || {} : {}),
          }),
        },
      ];
    });

    return keyTakeaways;
  }

  return [];
};

const useKeyTakeawaysCardPresenter = (props: KeyTakeawaysCardProps): KeyTakeawaysCardPresenterReturnProps => {
  const { page, view, keyTakeawaysValues } = props;
  const { t } = useTranslation();
  return {
    title: t('keyTakeaways.title'),
    keyTakeaways: buildPageKeyTakeaways(t, page || '', view || '', keyTakeawaysValues),
  };
};

export default useKeyTakeawaysCardPresenter;