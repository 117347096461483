import React from 'react';
import cx from 'classnames';

import Text from '../../../atoms/Text';

import { WorkforceGapTableHeaders } from './WorkforcegapTable.constants';
import styles from './WorkforcegapTable.module.scss';
import { WorkforcegapTableProps } from './WorkforcegapTable.types';

const WorkforcegapTable: React.FC<WorkforcegapTableProps> = (props) => {
  const {
    className,
    dataRows,
    printMode,
  } = props;
  const headersContent = WorkforceGapTableHeaders?.map((header, index) => (
    <div className={styles.cell} key={index}>
      <Text
        color='grey-4'
        weight='bold'
        size='md'
        type='p'
        printMode={printMode}
        >
        {header.title}
      </Text>
    </div>
  ));
  return (
    <div className = {cx((printMode ? styles.PrintWorkforcegapTable : styles.WorkforcegapTable), className)}>
      <div className={styles.header}>
        {headersContent}
      </div>
      {dataRows?.map((dataRow, dataRowIndex) => (
        <div className={styles.row} key={dataRowIndex}>
          {WorkforceGapTableHeaders?.map((header, index) => (
            <div className={styles.cell} key={index}>
              <Text
                color='grey-4'
                weight={index === 0 ? 'bold' : 'regular'}
                size='md'
                type='p'
                printMode={printMode}
              >
                {dataRow[header.key]}
              </Text>
            </div>
          ))}
        </div>
      ))}
    </div>
  );

};
 
export default WorkforcegapTable;