export const getIntensityColors = (value: number, increment: number, printMode: boolean) => {
  let bgColor = '#D0E8F5';
  let fontColor = printMode ? '#000' : '#2F3846';

  if (value > increment) {
    bgColor = '#A1D0EB';
  }
  if (value > increment * 2) {
    bgColor = '#43A2D6';
  }
  if (value > increment * 3) {
    bgColor = '#148BCC';
    fontColor = '#FFF';
  }
  if (value > increment * 4) {
    bgColor = '#004282';
  }

  return {
    fontColor,
    bgColor,
  };
};

