import React from 'react';
import cx from 'classnames';

import styles from './DropdownButton.module.scss';
import { DropdownButtonProps } from './DropdownButton.types';
import Button from '../../atoms/Button';
import List from '../List';

const DropdownButton: React.FC<DropdownButtonProps> = (props) => {
  const {
    className,
    classes,
    buttonContent,
    buttonType,
    list,
  } = props;
  
  return (
    <div className = {cx(styles.DropdownButton, className)}>
      <Button className={cx(styles.Button, classes?.button)} onClick={() => {}} style={buttonType}> 
        {buttonContent}
      </Button>
      <div className={cx(styles.ListContainer, classes?.listContainer)}>
        <List {...list} />
      </div>
    </div>
  );

};
 
export default DropdownButton;