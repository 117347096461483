import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { DASHBOARD_ROUTES } from '../../../lib/constants';
import ProfilePage from '../../pages/ProfilePage';

const DashboardRouter: React.FC<{ className?: string }> = () => {
  return (
    <Switch>
      <Route path='/profile' exact>
        <ProfilePage />
      </Route>
      {/* If route does not match with above user will be redirected to overview page */}
      <Redirect to={DASHBOARD_ROUTES.overview} />
    </Switch>
  );
};

export default DashboardRouter;
