import decode from 'jwt-decode';

export type Account = {
  id: number;
  uuid: string;
  email: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  roleId?: number;
};

export type InviteTokenPayload = {
  appId: number;
  email: string;
  exp: number;
  iat: number;
};

/**
 * Extracts payload from a jwt
 */
export const extractJwtPayload = (jwt: string): Account & {
  exp: number,
} => {
  const payload = window.atob(jwt.split('.')[1]);
  return JSON.parse(payload);
};

export const decodeInviteToken = (token: string): InviteTokenPayload | null => {
  try {
    return decode<InviteTokenPayload>(token);
  } catch {
    return null;
  }
};
