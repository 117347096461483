import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import ErrorBanner from '../../molecules/ErrorBanner';
import TextField from '../../molecules/TextField';

import styles from './CreateAccountPage.module.scss';
import useCreateAccountPagePresenter from './CreateAccountPage.presenter';
import { CreateAccountPageProps } from './CreateAccountPage.types';

const CreateAccountPage: React.FC<CreateAccountPageProps> = (props) => {
  const { t } = useTranslation();
  const { className } = props;
  const {
    confirmPasswordTextFeild,
    createAccountButton,
    emailInputField,
    firstNameInputField,
    isError,
    lastNameInputField,
    passwordTextField,
  } = useCreateAccountPagePresenter();
  const errorBannerView = isError && (
    <ErrorBanner error={t('GenericError.somethingWentWrong')} />
  );
  return (
    <div className = {cx(styles.CreateAccountPage, className)}>
      <div className={styles.instructionContent}>
          <Text
            size='sm'
            type='p'
            color='grey-4'
            weight='semi-bold'
          >
            {t('CreateAccountPage.steps', {
              currentStep: 1,
              totalSteps: 2,
            })}
          </Text>
          <Text
            className={styles.label}
            size='xl'
            type='p'
            color='grey-2'
            weight='bold'
          >
            {t('CreateAccountPage.title')}
          </Text>
          <Text
            className={styles.instruction}
            size='sm'
            type='p'
            color='grey-4'
            weight='regular'
          >
            {t('CreateAccountPage.description')}
          </Text>
      </div>
      {errorBannerView}
      <div className={styles.formContent}>
        <div className={styles.nameContent}>
          <TextField
            className={styles.nameTextFields}
            labelText={t('CreateAccountPage.labels.firstName')}
            inputField={firstNameInputField}
          />
          <TextField
            className={styles.nameTextFields}
            labelText={t('CreateAccountPage.labels.lastName')}
            inputField={lastNameInputField}
          />
        </div>
        <TextField
          state={'Caption'}
          labelText={t('CreateAccountPage.labels.email')}
          text={t('CreateAccountPage.fieldDescriptions.email')}
          inputField={emailInputField}
        />
        <TextField
          labelText={t('CreateAccountPage.labels.password')}
          inputField={passwordTextField.inputField}
          state={passwordTextField.state}
          text={passwordTextField.text}
        />
        <TextField
          labelText={t('CreateAccountPage.labels.confirmPassword')}
          inputField={confirmPasswordTextFeild.inputField}
          state={confirmPasswordTextFeild.state}
          text={confirmPasswordTextFeild.text}
        />
        <Button onClick={createAccountButton.onClick} style='primary' disabled={createAccountButton.disabled}>
          <Text
            size='sm'
            type='p'
            weight='semi-bold'
            color='grey-8'
          >
            {t('CreateAccountPage.buttons.createAccount')}
          </Text>
        </Button>
      </div>
    </div>
  );

};
 
export default CreateAccountPage;