import { HeaderProps } from './WorkforcegapTable.types';

export const WorkforceGapTableHeaders: HeaderProps[] = [
  {
    key: 'ethnicity',
    title: 'Ethnicity',
  },
  {
    key: 'expectedCount',
    title: 'Expected',
  },
  {
    key: 'currentCount',
    title: 'Current',
  },
  {
    key: 'workforceGap',
    title: 'Gap',
  },
];