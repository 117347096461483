import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import TextField from '../../molecules/TextField';
import Button from '../../atoms/Button';
import { useTranslation } from 'react-i18next';
import Icon from '../../atoms/Icon';

import styles from './CompleteProfilePage.module.scss';
import useCompleteProfilePagePresenter from './CompleteProfilePage.presenter';
import { CompleteProfilePageProps } from './CompleteProfilePage.types';

const CompleteProfilePage: React.FC<CompleteProfilePageProps> = () => {
  const { t } = useTranslation();
  const {
    companyNameInputField,
    jobTitleInputField,
    phoneNumberInputField,
    saveButton,
    handleCheckboxToggle,
    checkboxItemIcon,
  } = useCompleteProfilePagePresenter();
  return (
    <div className={cx(styles.CompleteProfilePage)}>
      <div className={styles.instructionContent}>
        <Text
          size='sm'
          type='p'
          color='grey-4'
          weight='semi-bold'
        >
          {t('CreateAccountPage.steps', {
            currentStep: 2,
            totalSteps: 2,
          })}
        </Text>
        <Text
          className={styles.label}
          size='xl'
          type='p'
          color='grey-2'
          weight='bold'
        >
          {t('CompleteProfile.title')}
        </Text>
      </div>
      <div className={styles.formContent}>
        <TextField
          labelText={t('CompleteProfile.labels.companyName')}
          inputField={companyNameInputField}
        />
        <TextField
          labelText={t('CompleteProfile.labels.jobTitle')}
          inputField={jobTitleInputField}
        />
        <TextField
          labelText={t('CompleteProfile.labels.phoneNumber')}
          inputField={phoneNumberInputField}
        />

        <div className={styles.CheckboxItem}>
          <Button className={styles.CheckboxButton} onClick={handleCheckboxToggle} style='default'>
            <Icon
              asset={checkboxItemIcon.asset}
              colour={checkboxItemIcon.colour}
              state='Selected'
            />
            <Text
              className={styles.CheckboxButtonText}
              size='sm'
              type='p'
              weight='regular'
              color='grey-2'
              align='left'
            >
              {t('CompleteProfile.termsCheck')}
            </Text>
          </Button>
        </div>

        <Button onClick={saveButton.onClick} style='primary' disabled={saveButton.disabled}>
          <Text
            size='sm'
            type='p'
            weight='semi-bold'
            color='grey-8'
          >
            {t('CompleteProfile.buttons.save')}
          </Text>
        </Button>
      </div>
    </div>
  );

};

export default CompleteProfilePage;