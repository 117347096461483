import React from 'react';
import { useTranslation } from 'react-i18next';

import IntensityChart from '../../../modules/Chart/IntensityChart';
import YoYChart from '../../../modules/Chart/YoYChart';
import Card from '../../atoms/Card';
import PrintPage from '../../atoms/Page/PrintPage';
import ChartCard from '../../molecules/ChartCard';
import TotalEICard from '../../molecules/TotalEICard/TotalEICard';
import EITable from '../../organisms/EITable';
import { EITableProps, EITableDataRow } from '../../organisms/EITable/EITable.types';
import KeyTakeawaysCard from '../../organisms/KeyTakeawaysCard';

import styles from './OverviewPage.module.scss';
import useOverviewPagePresenter from './OverviewPage.presenter';
import { OverviewPageCombinedProps } from './OverviewPage.types';

// Ratios are based single line vs double line row fit
const MAX_FIRST_PAGE_ROWS = 47;
const MAX_FIRST_PAGE_ROWS_RATIO = 36 / MAX_FIRST_PAGE_ROWS;
const MAX_PAGE_ROWS = 51;
const MAX_PAGE_ROWS_RATIO = 40 / MAX_PAGE_ROWS;

const findMaxRows = (maxRows: number, lines: number, ratio: number) => {
  let results = maxRows;
  for (let index = 1; index < lines; index++) {
    results = Math.ceil(results * ratio);
  }
  return results;
};

const OverviewPrintPage: React.FC<OverviewPageCombinedProps> = () => {
  const { t } = useTranslation();

  const {
    view,
    appliedFilters,
    totalEIAmount,
    avgTotalEIIntensityAmount,
    keyTakeawaysCard,
    eITableProps,
    totalAdjustmentIntensityChart,
    totalEIIntesityChart,
    eIYoYChart,
    allAdjustmentsYoYChart,
  } = useOverviewPagePresenter();

  const {
    legendDatasets: eITableLegendDataset,
    rows,
    thresholds,
  } = eITableProps;

  const eIIntensity = (
    <ChartCard
      width='offset'
      printMode
    >
      <ChartCard.Header
        title={t('OverviewPage.eIGraphCard.title')}
        subtitle={t('filtersApplied', { filters: appliedFilters })}
        isExpandable={false}
        printMode
      />
      <IntensityChart
        datasetConfig={totalEIIntesityChart.datasetConfig}
        alignment='left'
        data={totalEIIntesityChart.data}
        height={545}
        width={484}
        printMode
      />
      <ChartCard.Legend datasets={totalEIIntesityChart.datasetConfig} printMode />
    </ChartCard>
  );

  const totalEIAdjIntensity = (
    <ChartCard
      width='fill'
      printMode
    >
      <ChartCard.Header
        title={t('OverviewPage.totalEIAdjustmentIntensityGraphCard.title')}
        subtitle={t('filtersApplied', { filters: appliedFilters })}
        isExpandable={false}
        printMode
      />
      <IntensityChart
        datasetConfig={totalAdjustmentIntensityChart.adjustmentKeys}
        alignment='right'
        data={totalAdjustmentIntensityChart.data}
        height={545}
        width={631}
        printMode
      />
      <ChartCard.Legend datasets={totalAdjustmentIntensityChart.adjustmentKeys} printMode />
    </ChartCard>
  );

  const eIYoY = (
    <ChartCard
      width={view === 'Region' ? 'offset' : 'half'}
      printMode>
      <ChartCard.Header
        title={view === 'Region'
          ? t('OverviewPage.eIIntensityYoYGraphCard.title')
          : t('OverviewPage.eIAggregateYoYGraphCard.title')}
        subtitle={t('filtersApplied', { filters: appliedFilters })}
        isExpandable={false}
        printMode
      />
      <YoYChart
        height={view === 'Region' ? 545 : 275}
        width={view === 'Region' ? 484 : 265}
        data={eIYoYChart.data} 
        stacked={false}
        datasetConfig={eIYoYChart.datasetConfig}
        isIntensity={view === 'Region'}
        printMode
      />
      <ChartCard.Legend datasets={eIYoYChart.datasetConfig} printMode/>
    </ChartCard>
  );

  const adjByDimensionYoY = (
    <ChartCard
      width={view === 'Region' ? 'fill' : 'half'}
      printMode
    >
      <ChartCard.Header
        title={view === 'Region'
          ? t('OverviewPage.dimensionIntensityYoYGraphCard.title')
          : t('OverviewPage.dimensionAggregateYoYGraphCard.title')}
        subtitle={t('filtersApplied', { filters: appliedFilters })}
        isExpandable={false}
        printMode
      />
      <YoYChart
        height={view === 'Region' ? 545 : 275}
        width={view === 'Region' ? 631 : 265}
        data={allAdjustmentsYoYChart.data} 
        stacked={false}
        datasetConfig={allAdjustmentsYoYChart.datasetConfig}
        isIntensity={view === 'Region'}
        printMode
      />
      <ChartCard.Legend datasets={allAdjustmentsYoYChart.datasetConfig} printMode/>
    </ChartCard>
  );


  let eiTablePages: EITableProps[] = [];
  if (rows.length) {
    const allRows = rows;
    let longestSupplierName = '';
    // Iterate through each row to find the longest supplierName
    allRows.forEach(row => {
      // If the current supplierName is longer than the current longestSupplierName, update it
      if (row.supplierName.length > longestSupplierName.length) {
        longestSupplierName = row.supplierName;
      }
    });

    const nameColumnScalar = Math.ceil(longestSupplierName.length / 27);
    const maxFirstPageRowsScaled = findMaxRows(MAX_FIRST_PAGE_ROWS, nameColumnScalar, MAX_FIRST_PAGE_ROWS_RATIO);
    const maxPageRowsScales = findMaxRows(MAX_PAGE_ROWS, nameColumnScalar, MAX_PAGE_ROWS_RATIO);

    if (allRows.length > maxFirstPageRowsScaled) {
      const firstPageRows = allRows.slice(0, maxFirstPageRowsScaled);
      eiTablePages = [{
        rows: firstPageRows,
        thresholds: thresholds,
      }];

      const remainingRows = allRows.slice(maxFirstPageRowsScaled);
      const remainingPageRows: EITableDataRow[][] = [];
      while (remainingRows.length > 0) {
        remainingPageRows.push(remainingRows.splice(0, maxPageRowsScales));
      }
      remainingPageRows.forEach((pageRows) => {
        eiTablePages.push({
          rows: pageRows,
          thresholds: thresholds,
        });
      });
    } else {
      eiTablePages = [{ rows, thresholds }];
    }
  }
  

  return (
    <>
      <PrintPage>
        <div className={styles.eICardContainer}>
          <Card className={styles.totalEICard} printMode>
            <TotalEICard type='total' value={totalEIAmount} printMode />
          </Card>

          <Card className={styles.totalEICard} printMode>
            <TotalEICard type='average' value={avgTotalEIIntensityAmount} printMode />
          </Card>
        </div>
        {eiTablePages.length > 0 ? <ChartCard printMode>
          <ChartCard.Header
            title={t('OverviewPage.employmentImpactTable.title')}
            subtitle={t('filtersApplied', { filters: appliedFilters })}
            isExpandable={false}
            printMode
          />
          <EITable rows={eiTablePages[0].rows} thresholds={eiTablePages[0].thresholds} className={styles.printTableContainer} printMode />
          <ChartCard.Legend datasets={eITableLegendDataset || []} printMode />
        </ChartCard> : null}
      </PrintPage>
      {eiTablePages.length > 1 ? eiTablePages.slice(1).map((tablePage, index) => (
        <PrintPage key={index}>
          <ChartCard printMode>
            <ChartCard.Header
              title={t('OverviewPage.employmentImpactTable.title')}
              subtitle={t('filtersApplied', { filters: appliedFilters })}
              isExpandable={false}
              printMode
            />
            <EITable rows={tablePage.rows} thresholds={tablePage.thresholds} className={styles.printTableContainer} printMode />
            <ChartCard.Legend datasets={eITableLegendDataset || []} printMode />
          </ChartCard>
        </PrintPage>
      )) : null}
      { view === 'Region' ? (
        <PrintPage>
          <div style={{
            display: 'flex', flexDirection: 'column', gap: '1.5rem', width: '132px', flexShrink: 0,
          }}>
            <KeyTakeawaysCard
              page='overview'
              view={view}
              state={keyTakeawaysCard.state}
              keyTakeawaysValues={keyTakeawaysCard.values}
              printMode />
          </div>
          {eIYoY}
      </PrintPage>
      ) : (
        <>
          <PrintPage>
            <div style={{
              display: 'flex', flexDirection: 'column', gap: '1.5rem', width: '132px', flexShrink: 0,
            }}>
              <KeyTakeawaysCard
                page='overview'
                view={view}
                state={keyTakeawaysCard.state}
                keyTakeawaysValues={keyTakeawaysCard.values}
                printMode />
            </div>
            {eIIntensity}
          </PrintPage>
          <PrintPage>
            {totalEIAdjIntensity}
          </PrintPage>
        </>
      )}
      <PrintPage>
        {view !== 'Region' ? eIYoY : null}
        {adjByDimensionYoY}
      </PrintPage>
    </>
  );
};

export default OverviewPrintPage;
