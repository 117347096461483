import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { forgotPassword } from '../../../lib/api';
import { EmailState } from '../../../lib/api/resources/account';

const useResendPasswordLinkPagePresenter = () => {
  const [email, setEmail] = useState<string>('');
  const { state } = useLocation<EmailState>();

  useEffect(() => {
    if (state && state.email) {
      setEmail(state.email);
    }
  }, [state]);

  const handleSendResetEmailClick = async () => {
    if (email) {
      try {
        await forgotPassword(email);
      } catch (error) {
        // no-op
      }
    }
  };

  return {
    handleSendResetEmailClick,
  };
};

export default useResendPasswordLinkPagePresenter;