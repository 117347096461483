import React from 'react';
import cx from 'classnames';

import Card from '../../atoms/Card';
import Text from '../../atoms/Text';
import EmptyStateContent from '../../molecules/EmptyStateContent';
import KeyTakeaway from './KeyTakeaway/KeyTakeaway';

import styles from './KeyTakeawaysCard.module.scss';
import useKeyTakeawaysCardPresenter from './KeyTakeawaysCard.presenter';

export type KeyTakeawaysCardStateEnum = 'Default' | 'Loading' | 'Empty';

export type KeyTakeawaysCardProps = {
  page: string;
  view: string;
  state?: KeyTakeawaysCardStateEnum;
  keyTakeawaysValues?: Record<string, Record<string, string | number | undefined>>;
  printMode?: boolean;
};
const KeyTakeawaysCard: React.FC<KeyTakeawaysCardProps> = (props) => {
  const { state, printMode } = props;
  const {
    title,
    keyTakeaways,
  } = useKeyTakeawaysCardPresenter(props);
  const content = state === 'Empty' ? <EmptyStateContent style='Vertical' /> : (
    keyTakeaways?.map((keyTakeaway, index) => (
      <KeyTakeaway
        state={state}
        keyTakeawayIndex={index + 1}
        key={index}
        printMode={printMode}
        {...keyTakeaway}
      />
    ))
  );
  return (
    <Card className={cx(styles.KeyTakeawaysCard, (printMode ? styles.PrintKeyTakeawaysCard : undefined))} printMode={printMode}>
      <div className={cx(styles.KeyTakeawaysCard, (printMode ? styles.PrintKeyTakeawaysCard : undefined))}>
        <Text
          color='grey-2'
          size='md'
          type='p'
          weight='bold'
          printMode={printMode}
        >
          {title}
        </Text>
        <div className={cx(styles.KeyTakeawaysContainer, (printMode ? styles.PrintKeyTakeawaysContainer : undefined))}>
          {content}
        </div>
      </div>
    </Card>
  );

};

export default KeyTakeawaysCard;