import axiosInstance from '../axios';
import { BFF_URL } from '../../config';
import { DataEntry } from '../types';
import { Industry } from './industries';
import { Sectors } from './sectors';
import { Location } from './locations';


export type Supplier = {
  name: string;
  revelio_name: string | undefined;
  sector: DataEntry<Sectors>;
  industry: DataEntry<Industry>;
  locations2: string[];
};

export const getSuppliers = async (): Promise<DataEntry<Supplier>[]> => {
  const { data } = await axiosInstance.get<DataEntry<Supplier>[]>(
    `${BFF_URL}/suppliers`,
  );
  return data;
};
export const getSupplier = async (supplierUuid: string): Promise<DataEntry<Supplier>> => {
  const { data } = await axiosInstance.get<DataEntry<Supplier>>(
    `${BFF_URL}/suppliers/${supplierUuid}`,
  );
  return data;
};