import { RoleSummary, Summary, SummaryKeys } from '../../../lib/api/resources/reports';
import { formatAmount, getMinMaxForKeyTakeaway, getMinMaxOnCalculatedField, getYearSummaryTotalAdjustmentValue, getYoYDiffForProperty } from '../../../lib/utils';
import { DataFrame } from '../../../modules/data_frame/DataFrame';
import { View } from '../../organisms/FilterModal/FilterModal.types';
import { EMPLOYEE_LEVELS, EMPLOYEE_LEVELS_ROWS, ETHNICITY_NAME_MAPPING, GENDER_MAPPING } from '../../organisms/GenderTable/GenderTable.constants';

const buildDataForPortfolioView = (summaryDF: DataFrame<Record<string, string | number>>, intensityKeys: SummaryKeys[]) => {
  const { maxRecord, minRecord } = getMinMaxForKeyTakeaway(
    summaryDF,
    intensityKeys,
    ['supplier'],
    'opportunityAdjustment',
    'opportunityAdjustment',
  );

  const keyTakeawayValues = {
    'opportunityAdjustmentGrowthPotential': {
      supplier: minRecord?.supplier,
    },
    'opportunityAdjustmentPerformance': {
      supplier: maxRecord?.supplier,
    },
  };

  return {
    keyTakeawayValues,
  };
};

const buildDataForCompanyView = (summaryDF: DataFrame<Record<string, string | number>>, intensityKeys: SummaryKeys[]) => {
  const { maxRecord, minRecord } = getMinMaxForKeyTakeaway(
    summaryDF,
    intensityKeys,
    ['supplier', 'location'],
    'opportunityAdjustment',
    'opportunityAdjustment',
  );

  const keyTakeawayValues = {
    'opportunityAdjustmentGrowthPotential': {
      supplier: minRecord?.supplier,
      location: minRecord?.location,
    },
    'opportunityAdjustmentPerformance': {
      supplier: maxRecord?.supplier,
      location: maxRecord?.location,
    },
  };

  return {
    keyTakeawayValues,
  };
};

const buildDataForRegionView = (summary: Summary[], roleSummary: RoleSummary[], year: number) => {
  const queriedDF = new DataFrame(summary).query({
    select: [
      'supplier', 'location', 'year', 'opportunityAdjustment',
    ],
    orderBy: ['year'],
  });

  const { diffInPercentage, diffText, currentYearRecord } = getYoYDiffForProperty(queriedDF.getDataset(), 'opportunityAdjustment', year);

  const employeeSummaryAggregatedData = new DataFrame(roleSummary).query({
    groupBy: ['gender', 'ethnicity', 'categoryName', 'categoryType'],
    select: [
      'supplier', 'location', 'categoryName', 'gender', 'ethnicity', 'categoryType', 'adjustment',
    ],
    where: [{
      comparator: (props) => {
        return props.row.year === year;
      },
    }],
  });

  const seniorityAdjDF = employeeSummaryAggregatedData.query({
    where: [{
      comparator: (props) => {
        return props.row.categoryType === 'seniority';
      },
    }],
  });
  const minSeniorityAdj = getMinMaxOnCalculatedField(seniorityAdjDF, 'adjustment', 'min');

  const jobCategoryAdjDF = employeeSummaryAggregatedData.query({
    where: [{
      comparator: (props) => {
        return props.row.categoryType === 'job_category';
      },
    }],
  });

  const minJobCategoryAdj = getMinMaxOnCalculatedField(jobCategoryAdjDF, 'adjustment', 'min');

  const employeeLevel = EMPLOYEE_LEVELS_ROWS[minSeniorityAdj?.categoryName || ''];
  const keyTakeawayValues = {
    'opportunityAcrossSenoritiesAdjustment': {
      demographic: ETHNICITY_NAME_MAPPING[minSeniorityAdj?.ethnicity || ''],
      gender: GENDER_MAPPING[minSeniorityAdj?.gender || ''],
      seniorityLevel: `${employeeLevel} ${EMPLOYEE_LEVELS[employeeLevel || '']}`,
      supplier: minSeniorityAdj?.supplier,
      location: minSeniorityAdj?.location,
    },
    'opportunityAcrossJobCategoriesAdjustment': {
      demographic: ETHNICITY_NAME_MAPPING[minJobCategoryAdj?.ethnicity || ''],
      gender: GENDER_MAPPING[minJobCategoryAdj?.gender || ''],
      jobCategory: minJobCategoryAdj?.categoryName,
      supplier: minJobCategoryAdj?.supplier,
      location: minJobCategoryAdj?.location,
    },
    'opportunityAdjustmentYoY': {
      supplier: currentYearRecord?.supplier,
      location: currentYearRecord?.location,
      diffText: diffText,
      diffInPercentage: diffInPercentage,
      currentYear: year,
      previousYear: (year - 1),
    },
  };

  return {
    keyTakeawayValues,
  };
};

export const buildDataForPage = (rawSummary: Summary[], pageView: View, year: number, roleSummary: RoleSummary[]) => {
  const intensityKeys: SummaryKeys[] = ['opportunityAdjustment'];
  const {
    summaryDF,
    totalAdjustmentValue,
  } = getYearSummaryTotalAdjustmentValue(rawSummary, 'opportunityAdjustment', year);

  if (pageView === 'Portfolio') {
    return {
      ...buildDataForPortfolioView(summaryDF, intensityKeys),
      totalOpportunityAdjustment: formatAmount(totalAdjustmentValue),
    };
  }

  if (pageView === 'Company') {
    return {
      ...buildDataForCompanyView(summaryDF, intensityKeys),
      totalOpportunityAdjustment: formatAmount(totalAdjustmentValue),
    };
  }

  return {
    ...buildDataForRegionView(rawSummary, roleSummary, year),
    totalOpportunityAdjustment: formatAmount(totalAdjustmentValue),
  };
};
