import { useContext, useMemo } from 'react';
import { useHistory } from 'react-router';

import { KNOWLEDGE_BASE_LINK, PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK, YEAR_FILTER_ITEMS } from '../../../lib/constants';

import { UserContext } from '../../../modules/user/UserContext';

import { DropdownIconTextItem } from './Header.types';
import { useFilterModal } from '../FilterModal/FilterModal.context';
import { getInitials } from './Header.utils';

const removeFocusAfterClick = (onClick: () => void) => {
  return (e?: React.MouseEvent<HTMLElement>) => {
    onClick();
    e?.currentTarget.blur();
  };
}; 

const useHeaderPresenter = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const { filterState, updateFilterState, noOfSuppliers, openModal } = useFilterModal();

  const handleYearToggleClick = (year: number) => {
    const updatedFilterState = { ...filterState, year };
    updateFilterState(updatedFilterState);
  };

  const yearFilterItems = YEAR_FILTER_ITEMS.map((year) => ({
    text: year,
    onClick: removeFocusAfterClick(() => handleYearToggleClick(year)),
  }));

  const menuItems: DropdownIconTextItem[] = useMemo(() => {
    const newMenuItems: DropdownIconTextItem[] = [
      {
        asset: 'Profile',
        text: 'My Profile',
        onClick: removeFocusAfterClick(() => {
          history.push('/profile');
        }),
      },
      {
        asset: 'Policy',
        text: 'Privacy Policy',
        onClick: removeFocusAfterClick(() => {
          window.open(PRIVACY_POLICY_LINK, '_blank');
        }),
      },
      {
        asset: 'Terms',
        text: 'Terms of Use',
        onClick: removeFocusAfterClick(() => {
          window.open(TERMS_OF_USE_LINK, '_blank');
        }),
      },
      {
        asset: 'Knowledge',
        colour: 'NeutralDefault',
        state: 'Selected',
        text: 'Knowledge Base',
        onClick: removeFocusAfterClick(() => {
          window.open(KNOWLEDGE_BASE_LINK, '_blank');
        }),
      },
      {
        asset: 'Logout',
        text: 'Logout',
        onClick: removeFocusAfterClick(() => {
          history.push('/logout');
        }),
      },
    ];
    return newMenuItems;
  }, [history]);

  return {
    isUserAuthenticated: !!user,
    userInitials: getInitials(user),
    userName: `${user?.content?.firstName} ${user?.content?.lastName}`,
    selectedYear: filterState.year,
    filterButton: {
      onClick: openModal,
      noOfSuppliers: noOfSuppliers,
    },
    accountMeuItems: menuItems,
    yearFilterItems,
  };
};

export default useHeaderPresenter;