import React from 'react';
import cx from 'classnames';

import styles from './Page.module.scss';
import { PageProps } from './Page.types';

const Page: React.FC<PageProps> = (props) => {
  const {
    children,
    className,
  } = props;
  return (
    <div className = {cx(styles.Page, className)}>
      {children}
    </div>
  );

};
 
export default Page;