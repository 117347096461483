import React from 'react';
import { ProportionalChartProps } from './ProportionalChart.types';
import Chart from '../Chart';
import {  DataSetRecord } from '../../data_frame/DataFrame';
import useProportionalChartPresenter from './ProportionalChart.presenter';

const ProportionalChart: React.FC<ProportionalChartProps<any>> = <T extends DataSetRecord>(props: ProportionalChartProps<T>) => {
  
  const {
    className,
    height,
    width,
    printMode,
    aggregateCategory,
  } = props;

  const {
    annotationFormatter,
    labels,
    datasets,
  } = useProportionalChartPresenter(props);

  return (
    <Chart
      height={height}
      width={width}
      datasets={datasets}
      alignment={'left'}
      orientation={'horizontal'}
      isAnnotated={true}
      displayXAxis={false}
      displayXAxisGrid={false}
      maxX={100}
      minX={0}
      annotationFormatter={annotationFormatter}
      type={'bar'}
      tooltipLayout='proportionalBar'
      labels={labels}
      printMode={printMode}
      aggregateCategory = {aggregateCategory}
    />
  );

};

export default ProportionalChart;