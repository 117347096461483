
import { Supplier } from '../../../lib/api/resources/suppliers';
import { DataEntry } from '../../../lib/api/types';
import { getUniqueLocations } from '../../../lib/utils';
import { FilterItem, View } from '../FilterModal/FilterModal.types';

export type GlossaryHeaderData = {
  page: string;
  pageView?: View;
  title?: string;
  subTitle?: string;
  description?: string;
};
export const generateOverViewPageGlossaryHeader = (
  pageView: View,
  selectedSuppliers: FilterItem[],
  rawSuppliersData: DataEntry<Supplier>[],
  pageName: string,
): GlossaryHeaderData => {
  const glossaryHeaderObj: GlossaryHeaderData = {
    page: pageName,
  };
  if (pageView === 'Portfolio') {
    return glossaryHeaderObj;
  }

  const companyId = selectedSuppliers[0].id;
  const currentSupplier = rawSuppliersData.find((supplier) => supplier.uuid === companyId);
  if (currentSupplier) {
    const locationNames = getUniqueLocations(selectedSuppliers);
    const locationNameString = locationNames.join(', ');
    glossaryHeaderObj.subTitle = `${currentSupplier?.content?.industry?.content?.name} (${currentSupplier?.content?.industry?.content?.code})`;
    if (pageView === 'Company') {
      glossaryHeaderObj.title = currentSupplier.content.name;
      glossaryHeaderObj.description = `Selected Regions: ${locationNameString}`;
    } else if (pageView === 'Region') {
      glossaryHeaderObj.title = `${locationNames[0]} | ${currentSupplier.content.name}`;
    }
  }
  return glossaryHeaderObj;
};