import React from 'react';
import { useTranslation } from 'react-i18next';

import GenderEthnicityChart from '../../../modules/Chart/GenderEthnicityChart';
import { GENDER_ETHNICITY_CHART_LEGEND_KEYS } from '../../../modules/Chart/GenderEthnicityChart/GenderEthnicityChart.constants';
import IntensityChart from '../../../modules/Chart/IntensityChart';
import ProportionalChart from '../../../modules/Chart/ProportionalChart';
import YoYChart from '../../../modules/Chart/YoYChart';
import Card from '../../atoms/Card';
import PrintPage from '../../atoms/Page/PrintPage';
import Text from '../../atoms/Text';
import ChartCard from '../../molecules/ChartCard';
import TotalAdjustmentCard from '../../molecules/TotalAdjustmentCard';
import KeyTakeawaysCard from '../../organisms/KeyTakeawaysCard';

import styles from './WageQualityPage.module.scss';
import useWageQualityPagePresenter from './WageQualityPage.presenter';
import { WageQualityPageProps } from './WageQualityPage.types';

const WageQualityPrintPage: React.FC<WageQualityPageProps> = () => {
  const { t } = useTranslation();

  const {
    view,
    appliedFilters,
    totalWageQualityAdjustmentAmount,
    genderEthnicityChartData,
    keyTakeawaysCard,
    femaleEmpowermentChart,
    maleEmpowermentChart,
    wageQualityIntensityChart,
    wageQualityYoYChart,
  } = useWageQualityPagePresenter();

  return (
    <>
      <PrintPage>
        <div style={{
          display: 'flex', flexDirection: 'column', gap: '10px', width: '132px', flexShrink: 0,
        }}>
          <Card printMode>
            <TotalAdjustmentCard
              page='wageQuality'
              value={totalWageQualityAdjustmentAmount}
              printMode
            />
          </Card>
          <KeyTakeawaysCard
            page='wageQuality'
            view={view}
            state={keyTakeawaysCard.state}
            keyTakeawaysValues={keyTakeawaysCard.values}
            printMode
          />
        </div>

        <ChartCard
          width='offset'
          printMode
        >
          <ChartCard.Header
            title={view === 'Region'
              ? t('WageQualityPage.wageQualityAdjustmentGraphTitle.intensity')
              : t('WageQualityPage.wageQualityAdjustmentGraphTitle.forEachSupplier')}
            subtitle={t('filtersApplied', { filters: appliedFilters })}
            isExpandable={false}
            printMode
          />
          {view === 'Region' ? (
            <YoYChart
              height={545}
              width={484}
              stacked={true}
              datasetConfig={wageQualityYoYChart.datasetConfig}
              data={wageQualityYoYChart.data}
              isIntensity
              printMode
            />
          ) : (
            <IntensityChart
              datasetConfig={wageQualityIntensityChart.datasetConfig}
              alignment='right'
              data={wageQualityIntensityChart.data}
              height={545}
              width={464}
              printMode
            />
          )}
          <ChartCard.Legend
            datasets={view === 'Region'
              ? wageQualityYoYChart.datasetConfig
              : wageQualityIntensityChart.datasetConfig}
            printMode />
        </ChartCard>
      </PrintPage>
      <PrintPage>
        <ChartCard
          width='half'
          printMode
        >
          <ChartCard.Header
            title={t('WageQualityPage.genderEthnicityGraph.title')}
            subtitle={t('filtersApplied', { filters: appliedFilters })}
            isExpandable={false}
            printMode
          />
          <GenderEthnicityChart
            height={275}
            width={265}
            data={genderEthnicityChartData}
            datasetConfig={'wageQualityAdjustment'}
            printMode
          />
          <ChartCard.Legend datasets={GENDER_ETHNICITY_CHART_LEGEND_KEYS} shouldShowDefinition={false} printMode />
        </ChartCard>
        <ChartCard
          width='half'
          printMode
        >
          <ChartCard.Header
            title={t('WageQualityPage.genderEthnicityEmpowermentBreakdown.title')}
            subtitle={t('filtersApplied', { filters: appliedFilters })}
            isExpandable={false}
            printMode
          />
          <div className={styles.genderGrouping}>
            <Text
              type="p"
              size='lg'
              color='grey-2'
              weight='bold'
              align='center'
              printMode
            >Male</Text>
            <ProportionalChart
              height={118.9}
              width={290}
              {...maleEmpowermentChart}
              printMode
            />
          </div>
          <div className={styles.genderGrouping}>
            <Text
              type="p"
              size='lg'
              color='grey-2'
              weight='bold'
              align='center'
              printMode
            >Female</Text>
            <ProportionalChart
              height={118.9}
              width={290}
              {...femaleEmpowermentChart}
              printMode
            />
          </div>
          <ChartCard.Legend datasets={femaleEmpowermentChart.datasetConfig} printMode />
        </ChartCard>
      </PrintPage>
    </>
  );
};
export default WageQualityPrintPage;