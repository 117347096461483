import { User } from '../../../lib/api/resources/user';

/**
 * To get initials of user's name
 * @param {User} user user object 
 * @returns {string} initials of user's name in uppercase
 */
export const getInitials = (user?: User): string => {
  const firstName = user?.content?.firstName || '';
  const lastName = user?.content?.lastName || '';

  return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
};