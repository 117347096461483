import { TFunction } from 'react-i18next';
import { EmpowermentSummary } from '../../lib/api/resources/reports';
import { ChartData } from './PageContext';

/**
 * Translates the fields that have alternative mappings of each `report` object using 
 * the provided translation function.
 * 
 * @param {ChartData} chartData 
 * @param {TFunction} t - A translation function from the `react-i18next` library. 
 * @returns {ChartData} 
 * 
 */

//TODO: Generalize function to work with all report summaries
export const translateDatasets = ( 
  chartData: ChartData,
  t:TFunction, 
):ChartData => {
  const translatedEmpowermentSummary:EmpowermentSummary[] =  chartData.empowermentSummary.map((row) => {
    return {
      ...row,
      ethnicity: t(`ethnicities.${row.ethnicity}`),
    };
  });

  return {
    ...chartData,
    empowermentSummary: translatedEmpowermentSummary,
  };
};