import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Summary } from '../../../lib/api/resources/reports';
import { DatasetConfig } from '../../../modules/Chart/Chart.types';
import { PageContext } from '../../../modules/page/PageContext';
import { buildDataForTable } from '../../organisms/EITable/EITable.utils';
import { useFilterModal } from '../../organisms/FilterModal/FilterModal.context';
import { KeyTakeawaysCardStateEnum } from '../../organisms/KeyTakeawaysCard/KeyTakeawaysCard';

import { buildDataForPage } from './OverviewPage.utils';

const useOverviewPagePresenter = () => {
  const { t } = useTranslation();
  const { filterState: { year }, pageView, appliedFilters } = useFilterModal();
  const { chartsData: { rawSummary }, isLoading } = useContext(PageContext);
  const overViewPageData = useMemo(() => buildDataForPage(rawSummary, year, pageView), [rawSummary, pageView]);

  // EI Table
  const eITableLegendDatasetKey: DatasetConfig<Summary> = {
    datasetLabel: 'Largest Opportunity for Improvement',
    datasetProperty: 'totalEmploymentImpact',
    color: 'BLUE',
  };
  const eITableData = useMemo(() => buildDataForTable(year, pageView, rawSummary), [year, pageView, rawSummary]);
  const eITableProps = {
    legendDatasets: [eITableLegendDatasetKey],
    rows: eITableData.rows,
    thresholds: eITableData.thresholds,
  };

  // Key Takeaways Card
  const keyTakeawaysCardState: KeyTakeawaysCardStateEnum = isLoading ? 'Loading' : 'Default';
  const keyTakeawaysCard = {
    values: overViewPageData.keyTakeawayValues,
    state: keyTakeawaysCardState,
  };

  // Total EI Intensity Chart
  const totalEIIntensityChartDatasetConfig: DatasetConfig<Summary>[] = [{
    datasetLabel: t('chartDatasetKeys.totalEmploymentImpact'),
    datasetProperty: 'totalEmploymentImpact',
    color: 'BLUE',
  }];
  const totalEIIntesityChart = {
    data: rawSummary,
    datasetConfig: totalEIIntensityChartDatasetConfig,
  };

  // Total Adjustment Intesity Chart
  const totalAdjustmentIntensityChartDatasetConfig: DatasetConfig<Summary>[] = [{
    datasetLabel: t('chartDatasetKeys.totalAdjustment'),
    datasetProperty: 'totalAdjustment',
    color: 'BLUE',
  }];
  const totalAdjustmentIntensityChart = {
    data: rawSummary,
    adjustmentKeys: totalAdjustmentIntensityChartDatasetConfig,
  };

  // Employment Impact YoY Chart
  const eIYoYChartAdjustmentKeys: DatasetConfig<Summary>[] = [{
    color: 'BLUE',
    datasetLabel: t('chartDatasetKeys.totalEmploymentImpact'),
    datasetProperty: 'totalEmploymentImpact',
  }];
  const eIYoYChart = {
    data: rawSummary,
    datasetConfig: eIYoYChartAdjustmentKeys,
  };

  // All Adjustments YoY Chart
  const allAdjustmentsYoYChartDatasetConfig: DatasetConfig<Summary>[] = [
    {
      color: 'BLUE',
      datasetLabel: t('chartDatasetKeys.wageQualityAdjustment'),
      datasetProperty: 'wageQualityAdjustment',
    },
    {
      color: 'GREEN',
      datasetLabel: t('chartDatasetKeys.diversityAdjustment'),
      datasetProperty: 'diversityAdjustment',
    },
    {
      color: 'PURPLE',
      datasetLabel: t('chartDatasetKeys.opportunityAdjustment'),
      datasetProperty: 'opportunityAdjustment',
    },
    {
      color: 'TEAL',
      datasetLabel: t('chartDatasetKeys.jobCreationAdjustment'),
      datasetProperty: 'jobCreationAdjustment',
    },
  ];
  const allAdjustmentsYoYChart = {
    data: rawSummary,
    datasetConfig: allAdjustmentsYoYChartDatasetConfig,
  };


  return {
    view: pageView,
    // Applied Filters for each Chart Card
    appliedFilters,
    // Total Adjutsment Amount Card
    totalEIAmount: overViewPageData.totalEIAmount,
    // Average EI Amount Card
    avgTotalEIIntensityAmount: overViewPageData.avgTotalEIIntensityAmount,
    // Key Takeaways Card Props
    keyTakeawaysCard,
    // Intensity Charts Props
    totalEIIntesityChart,
    totalAdjustmentIntensityChart,
    // YoY Charts Props
    eIYoYChart,
    allAdjustmentsYoYChart,
    // Table props
    eITableProps,
  };
};

export default useOverviewPagePresenter;
