import React from 'react';
import cx from 'classnames';

import styles from './DimensionHeader.module.scss';
import Card from '../../atoms/Card';
import Glossary from '../../organisms/Glossary';
import GlossaryHeader from '../../organisms/GlossaryHeader';
import { DimensionHeaderProps } from './DimensionHeader.types';

const DimensionHeader: React.FC<DimensionHeaderProps> = (props) => {
  const { page } = props;

  return (
    <Card className={cx(styles.DimensionHeader)}>
      {page !== 'overview' ? <GlossaryHeader page={page} /> : null}
      <Glossary page={page} />
    </Card>
  );

};

export default DimensionHeader;