import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { AUTH_ROUTES } from '../../../lib/constants';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import ErrorBanner from '../../molecules/ErrorBanner';
import TextField from '../../molecules/TextField';

import styles from './SignInPage.module.scss';
import { SignInPageProps } from './SignInPage.types';
import useSignInPagePresenter from './SignInPage.presenter';

const SignInPage: React.FC<SignInPageProps> = () => {
  const { t } = useTranslation();
  const {
    emailInputField,
    passwordInputField,
    signInButton,
    isError,
  } = useSignInPagePresenter();

  const errorView = isError && (
    <ErrorBanner error={t('SignInPage.errors.invalidCredentials')} />
  );

  return (
    <div className={cx(styles.SignInPage)}>
      <div className={styles.instructionContent}>
        <Text
          size='xl'
          weight='bold'
          color='grey-2'
          type='p'
        >
          {t('SignInPage.title')}
        </Text>
      </div>
      <div className={styles.formContent}>
        {errorView}
        <TextField
          className={styles.textField}
          labelText={t('SignInPage.labels.email')}
          inputField={emailInputField} />
        <TextField
          className={styles.textField}
          labelText={t('SignInPage.labels.password')}
          inputField={passwordInputField} />
        <Button
          className={styles.signInButton}
          onClick={signInButton.onClick}
          style={'primary'}
          disabled={signInButton.disabled}
        >
          <Text
            size='sm'
            type='p'
            weight='semi-bold'
            color='grey-8'
          >
            {t('SignInPage.buttons.signIn')}
          </Text>
        </Button>
        <Button
          className={styles.forgotPasswordButton}
          href={AUTH_ROUTES.forgotPassword}
          style={'default'}
        >
          <Icon className={styles.icon} asset='Question' colour='Primary' state='Default' />
          <Text
            size='sm'
            type='p'
            weight='semi-bold'
            color='primary'
          >
            {t('SignInPage.buttons.forgotPassword')}
          </Text>
        </Button>
      </div>
    </div>
  );

};

export default SignInPage;