import React from 'react';
import cx from 'classnames';

import ScreenSizeCard from '../../molecules/ScreenSizeCard';

import styles from './ScreenSizePage.module.scss';
import { ScreenSizePageProps } from './ScreenSizePage.types';

const ScreenSizePage: React.FC<ScreenSizePageProps> = (props) => {
  const {
    className,
  } = props;
  return (
    <div className = {cx(styles.ScreenSizePage, className)}>
      <ScreenSizeCard className={styles.screenSizeCard} />
    </div>
  );

};
 
export default ScreenSizePage;