import React from 'react';
import cx from 'classnames';

import Text from '../../../atoms/Text';

import styles from './JobMapGraphCardLegends.module.scss';
import { JobMapGraphCardLegendsProps } from './JobMapGraphCardLegends.types';

const JobMapGraphCardLegends: React.FC<JobMapGraphCardLegendsProps> = (props) => {
  const {
    lowestImpact,
    highestImpact,
    printMode,
  } = props;
  return (
    <div className={cx(styles.JobMapGraphCardLegends, (printMode ? styles.PrintJobMapGraphCardLegends : undefined))}>
      <Text
        color='grey-2'
        size='xs'
        type='p'
        weight='regular'
        align='right'
        printMode={printMode}
      > {lowestImpact} </Text>
      <table className={styles.table}>
        <tbody>
          <tr className={styles.row}>
            <td className={cx(styles.column, styles.columnOne)}></td>
            <td className={cx(styles.column, styles.columnTwo)}></td>
            <td className={cx(styles.column, styles.columnThree)}></td>
            <td className={cx(styles.column, styles.columnFour)}></td>
            <td className={cx(styles.column, styles.columnFive)}></td>
            <td className={cx(styles.column, styles.columnSix)}></td>
          </tr>
        </tbody>
      </table>
      <Text
        color='grey-2'
        size='xs'
        type='p'
        weight='regular'
        align='left'
        printMode={printMode}
      > {highestImpact} </Text>
    </div>
  );

};
 
export default JobMapGraphCardLegends;