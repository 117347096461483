import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import SkeletonRectangle from '../../atoms/SkeletonRectangle';
import Text from '../../atoms/Text';
import TooltipIcon from '../TooltipIcon';

import styles from './TotalAdjustmentCard.module.scss';
import { TooltipDefs } from '../../organisms/Tooltip/Tooltip.constant';
import { TotalAdjustmentCardProps } from './TotalAdjustmentCard.types';

const TotalAdjustmentCard: React.FC<TotalAdjustmentCardProps> = (props) => {
  const { t } = useTranslation();
  const { state = 'Default', page, printMode, value } = props;
  const titleText = t(`totalAdjustmentCard.${page}`);

  const TooltipButton = !printMode ? ( 
    <TooltipIcon
      style={{ marginLeft: '6px' }}
      title={titleText}
      desc={TooltipDefs[titleText]} />
  ) : null;

  const valueContent = state === 'Loading' ? (
    <SkeletonRectangle />
  ) : (
    <Text
      type='h2'
      size={'md'}
      weight={'semi-bold'}
      color={'grey-2'}
      printMode={printMode}
    >
      {value}
    </Text>
  );

  return (
    <div className = {cx(styles.TotalAdjustmentCard, (printMode ? styles.PrintTotalAdjustmentCard : undefined))}>
      <div className={styles.labelContent}>
        <Text
          type='p'
          size={'md'}
          weight={'regular'}
          color={'grey-4'}
          printMode={printMode}
        >
          {titleText}
          {TooltipButton}
        </Text>
      </div>
      {valueContent}
    </div>
  );

};
 
export default TotalAdjustmentCard;