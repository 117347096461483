export const keyTakeawaysDefinitions = {
  'overview': {
    'Portfolio': ['employmentImpactGrowthPotential', 'employmentImpactPerformance'],
    'Company': ['employmentImpactGrowthPotential', 'employmentImpactPerformance', 'employmentImpact'],
    'Region': ['employmentImpact', 'employmentImpactYoY'],
  },
  'wageQuality': {
    'Portfolio': ['wageQualityBreakdown', 'wageQualityPerformance', 'wageEquityAdjustment', 'incomeSatiationAdjustment'],
    'Company': ['wageQualityAdjustmentPerLocation', 'wageQualityPerformance', 'wageEquityAdjustment', 'incomeSatiationAdjustment'],
    'Region': ['wageEquity', 'wageQualityYoY'],
  },
  'diversity': {
    'Portfolio': ['diversityAdjustmentGrowthPotential', 'diversityAdjustmentPerformance'],
    'Company': ['diversityAdjustmentGrowthPotential', 'diversityAdjustmentPerformance'],
    'Region': ['diversityAdjustmentGrowthPotential', 'employeeRepresentation', 'diversityAdjustmentYoY'],
  },
  'opportunity': {
    'Portfolio': ['opportunityAdjustmentGrowthPotential', 'opportunityAdjustmentPerformance'],
    'Company': ['opportunityAdjustmentGrowthPotential', 'opportunityAdjustmentPerformance'],
    'Region': ['opportunityAcrossSenoritiesAdjustment', 'opportunityAcrossJobCategoriesAdjustment', 'opportunityAdjustmentYoY'],
  },
  'jobCreation': {
    'Portfolio': ['jobCreationAdjustment', 'jobCreationPerformance'],
    'Company': ['jobCreationPerformance', 'jobCreationAdjustment', 'jobCreationGrowthPotential'],
    'Region': ['jobCreationAdjustmentYoY'],
  },
} as const;