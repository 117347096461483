import { getYoYChartDatasetAndLabels } from './YoYChart.utils';
import { YoYChartProps } from './YoYChart.types';

const useYoYChartPresenter = ( props: YoYChartProps ) => {
  const {
    data,
    isIntensity,
    datasetConfig,
  } = props;

  return getYoYChartDatasetAndLabels(data, datasetConfig, isIntensity);
};

export default useYoYChartPresenter;