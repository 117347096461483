import axiosInstance from '../axios';
import { BFF_URL } from '../../config';
import { DataEntry } from '../types';
import { Industry } from './industries';

export type Sectors = {
  name: string;
  industries: DataEntry<Industry>[];
};

export const getSectors = async (): Promise<DataEntry<Sectors>[]> => {
  const { data } = await axiosInstance.get<DataEntry<Sectors>[]>(
    `${BFF_URL}/sectors`,
  );
  return data;
};