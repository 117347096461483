import { useContext, useState, useEffect } from 'react';
import { UserInterface, updateUser } from '../../../lib/api/resources/user';
import { UserContext } from '../../../modules/user/UserContext';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from '../../../lib/utils';
import { InputFieldProps } from '../../atoms/InputField/InputField.types';

const useProfilePagePresenter = () => {
  const { t } = useTranslation();
  const { user, refetchUser } = useContext(UserContext);
  const [currentAccountData, setCurrentAccountData] = useState<UserInterface | undefined>(undefined);
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);

  useEffect(() => {
    if (user?.content) {
      setCurrentAccountData(user.content);
    }
  }, [user]);

  const handleTextChange = (field: keyof UserInterface) => {
    return (value: string) => {
      if (user?.content && currentAccountData) {
        const newValue = {
          ...currentAccountData,
          [field]: value,
        };
        const isDataUpdated = JSON.stringify(newValue) !== JSON.stringify(user.content);
        setCurrentAccountData(newValue);
        setIsFormChanged(isDataUpdated);
      }
    };
  };

  const onSaveButtonClicked = async () => {
    if (currentAccountData) {
      try {
        await updateUser({
          lastName: currentAccountData?.lastName,
          companyName: currentAccountData?.companyName,
          firstName: currentAccountData?.firstName,
          jobTitle: currentAccountData?.jobTitle,
          phoneNumber: currentAccountData?.phoneNumber,
        }, user?.uuid || '');
        await refetchUser();
        setIsFormChanged(false);
      } catch {
        // no-op
      }
    }
  };

  const phoneNumberInputField: InputFieldProps = {
    type: 'phone',
    maxLength: 12,
    textPlaceholder: t('CompleteProfile.phoneNumberPlaceholder'),
    textValue: currentAccountData?.phoneNumber,
    onTextChanged: handleTextChange('phoneNumber'),
    preProcessTextInput: formatPhoneNumber,
  };

  return {
    emailInputField: {
      textValue: currentAccountData?.email,
      disabled: true,
    },
    firstNameInputField: {
      textValue: currentAccountData?.firstName,
      onTextChanged: handleTextChange('firstName'),
    },
    lastNameInputField: {
      textValue: currentAccountData?.lastName,
      onTextChanged: handleTextChange('lastName'),
    },
    companyNameInputField: {
      textValue: currentAccountData?.companyName,
      onTextChanged: handleTextChange('companyName'),
    },
    jobTitleInputField: {
      textValue: currentAccountData?.jobTitle,
      onTextChanged: handleTextChange('jobTitle'),
    },
    phoneNumberInputField,
    submitButton: {
      disabled: !isFormChanged,
      onClick: onSaveButtonClicked,
    },
  };
};

export default useProfilePagePresenter;