import React from 'react';

import ReactECharts from 'echarts-for-react';

import useGenderChartPresenter from './GenderChart.presenter';
import { GenderChartProps } from './GendersChart.types';

const GenderChart: React.FC<GenderChartProps> = (props) => {
  const { printMode } = props;
  const { options } = useGenderChartPresenter(props);
  return (
    <ReactECharts
      option={options}
      style={{ height: printMode ? '50px' : '100px', width: printMode ? '50px' : '100px' }} />
  );
};

export default GenderChart;
