import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
//TODO: Fix this depreaction warning
import { BrowserTracing } from '@sentry/tracing';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';

import './index.css';
import App from './App';
import AppProvider from './AppProvider';
import * as serviceWorker from './serviceWorker';
import {
  SENTRY_ENV_FRONTEND,
  SENTRY_DSN,
  SENTRY_TRACES_SAMPLE_RATE,
} from './lib/config';
import { initializeTracking } from './lib/trackingUtils';

//Initialize and gloabally set fonts for ChartJS
Chart.register(annotationPlugin);

Chart.defaults.font.family = 'Lato';
Chart.defaults.font.size = 12;
Chart.defaults.font.style = 'normal';
Chart.defaults.font.weight = 'normal';
Chart.defaults.font.lineHeight = 'normal';
Chart.defaults.color = '#333';


initializeTracking();

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV_FRONTEND,
  // TODO: Fix deprecated BrowserTracing
  integrations: [new BrowserTracing()],
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,

  // Manually ignored errors and URLs
  ignoreErrors: [],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
