import React from 'react';
import cx from 'classnames';

import styles from './Image.module.scss';
import { ImageProps } from './Image.types';

const Image: React.FC<ImageProps> = (props) => {
  const { className, imageSrc, imageFallback } = props;

  const currentStyle = styles.image;

  return (
    <div
      className={cx(currentStyle, className)}
      style={{ backgroundImage: `url(${imageSrc}), url(${imageFallback})` }}
    />
  );
};

export default Image;
